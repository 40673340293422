<template>
    <div class="container">
        <header class="jumbotron">
            <vue-editor
                    :style="{width: '100%', height: '100%', padding: '0px 128px'}"
                    v-model="html" spellcheck="true" disabled="true" />

<!--            <div id="preview" :style="{width: '100%', height: (height - 48) + 'px', border: '1px solid #b3b3b3'}">-->
<!--            </div>-->
<!--            <div>{{aims}}</div>-->
        </header>
    </div>
</template>

<script>
    import CaseController from "../../controllers/case.controller";
    import CaseSessionController from "../../controllers/caseSession.controller";

    export default {
        name: 'User',
        data() {
            return {
                html: '',
            };
        },
        mounted() {
            this.caseController = new CaseController()
            this.caseSessionController = new CaseSessionController()

            const session = this.caseSessionController.getCurrentSessionStore()
            if (!session.adminSession) {
                const caseId = session.caseId
                this.caseController.caseById(caseId).then( (response) => {
                    this.html = response.data.html
                })
            }
        }
    };
</script>

<style scoped>
    .instruction {
        background: rgba(255, 255, 255, 0.95);
        padding-top: 10px;
    }
    p {
        text-align: left;
    }

    >>> .ql-toolbar {
        display: none
    }
    >>>.jumbotron{
        padding: 0px;
    }
    >>>.ql-editor{

    }


</style>