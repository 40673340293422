<template>
  <div>
      <svg :style="{ left:0, right: 0}" :height="height + 'px'" :width="width + 'px'" xmlns="http://www.w3.org/2000/svg">
          <g id="gElement">

          </g>
      </svg>


  </div>
</template>

<script>
  import D3Controller from "../../controllers/d3.edges.window.controller";
  import OntologyController from "../../controllers/ontology.controller";

  export default {
  name: 'EdgesWindowElement',
  data() {
    return {
      height: 60,
      width: 220,
      graph: {
        nodes: [],
        links: []
      },
      d3Controller: null,
        showElementInfo: false,
        showElementInfoTimeout: null,
        ontologyController: null

    }
  },
    props: {
      item: {
        type: Object,
        default: function () {
          return {}
        }
      },
    },
  computed: {
  },
  mounted() {
    this.d3Controller = new D3Controller(this.$el.querySelector('svg'), this.width, this.height)
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    if (Object.keys(this.item).length === 0) {
      console.log('Object.keys(this.item).length === 0')
    } else {
        this.createGraphic()
    }

  },
  methods: {
      goToSubgraphs () {
        this.$emit('subgraphs', {
            type: this.type,
            anotherID: this.id
        })
      },
    createGraphic () {

      let x1 = 32
      let x2 = 170
      let y1 = 30
      let y2 = 30

      this.d3Controller.removeElements('.node')
      this.d3Controller.removeElements('.marker')
      this.d3Controller.removeElements('text')
      this.d3Controller.removeElements('.link')

        this.graph.nodes.push({
            element: 'ontology',
            name: this.item.selectedName,
            id: this.item.selected,
            x: x1,
            y: y1,
            index: 0,
            icon: '',
        })
        this.graph.nodes.push({
            element: 'ontology',
            name: this.item.nodeName,
            id: this.item.node,
            x: x2,
            y: y2,
            index: 1,
            icon: '',
        })
        if (this.item.type === 'out') {
            this.graph.links.push({
                source: this.graph.nodes[0],
                target: this.graph.nodes[1],
                sameLink: {all: 0, current: 0},
                name: this.item.linkType,
                id: this.item.link
            })
        } else if (this.item.type === 'in') {
            this.graph.links.push({
                target: this.graph.nodes[0],
                source: this.graph.nodes[1],
                sameLink: {all: 0, current: 0},
                name: this.item.linkType,
                id: this.item.link
            })
        }

        const vm = this

        function ontologyMouseEnter(d) {
            vm.d3Controller.event().preventDefault();
            d.showElementInfo = true
            vm.showElementInfoTimeout = setTimeout(function () {
                if (d.showElementInfo) {
                    vm.$emit('show-info', d)
                }
            }, 600)

        }
        function ontologyMouseLeave(d) {
            vm.d3Controller.event().preventDefault();
            clearTimeout(vm.showElementInfoTimeout);
            d.showElementInfo = false
            vm.$emit('close-info', d)
        }


        this.d3Controller.updateGraph(this.graph)
      this.d3Controller.createLinkLabel(this.ontology.links[this.item.linkType].label, x1, x2, y1)
      this.d3Controller.createLinks()
      this.d3Controller.createNodes(ontologyMouseEnter, ontologyMouseLeave)
      this.d3Controller.moveMarkers()

    }
  },
};
</script>

<style scoped>

  >>> .el-card__body {
    padding: 8px;
  }
  .vl {
    margin-left: 16px;
    margin-right: 16px;
    border-left: 1px solid #e2e2e2;
    height: 100px;
    display: inline-block;
  }

  >>> .spark {
    fill: none;
    stroke: steelblue;
    stroke-width: 1.5px;
  }

  >>> .point {
    fill: steelblue;
    stroke: #fff;
    stroke-width: 3px;
  }

  >>> .label,
  >>> .change,
  >>> .axis,
  >>> .main {
    text-anchor: middle;
    alignment-baseline: middle;
    fill: #aaa;
  }

  >>> .change,
  >>> .main {
    text-anchor: middle;
    alignment-baseline: middle;
    fill: #333;
  }

  >>> line.axis {
    stroke: #aaa;
    stroke-width: 1.5px;
  }



</style>
