<template>
  <div id="table">

    <el-row>
      <el-col :span="13" style="padding: 16px">
        <scheme-window class="mt-2" @select-element="selectElement"
                       v-loading="isLoading"
                       :ref="'subgraph_window'"
                       :data="$data"
        @add-to-cart="addToCart"></scheme-window>
      </el-col>
      <el-col :span="11">
        <el-card style="margin-left: 16px">
          <template #header>
            <div class="clearfix">
              <span>{{elementCard.name}}</span>
            </div>
          </template>

          <div v-for="attr in elementCard.attributes" :key="attr.name"  >
            <div class="break-long-words">
              <b>{{attr.name }}</b>  {{': ' + attr.value }}
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import axios from 'axios'
import SchemeWindow from './SchemeWindow'
import SchemeController from "../../controllers/scheme.controller";
import config from "../../../config";
import Utils from "../../services/utils";
import OntologyController from "../../controllers/ontology.controller";
const ScienceAPI = config.API

export default {
  props: {
    form: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  name: 'subgraph-row',
  components: {
    SchemeWindow
  },
  data () {
    return {
      tableColumns: [],
      tableData: [],
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ) * 3 / 4 + 16,
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 56,
      tableH: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 56,
      isLoading: false,
      elementCard: {
        name: '',
        id: 0,
        attributes: []
      },
      subgraphElements: {nodes: {}, links: {}},
      perPage: 50,
      totalItems: 0,
      columnSort: '',
      orderSort: 'ASC',
      currentSortType: '',
      schemeGraphType: '1',
      selectedItem: null,
      schemeController: null,
      ontology: null,
      ontologyController: null
    }
  },
  mounted () {
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
  },
  methods: {
    init(row, currentForm) {
      this.schemeController = new SchemeController('vis')
      const vm = this
      this.isLoading = true
      axios
              .post(`${ScienceAPI}/Neo4j/getSubgraphs/elements`, {
                        sNodes: row.sNodes,
                        sLinks: row.sLinks,
                        scheme: currentForm.scheme,
                        item: currentForm.item
                      }
              )
              .then(function (response) {
                const data = response.data
                vm.subgraphElements.nodes = data.nodes
                vm.subgraphElements.links = data.links

                const partQuery = (function () {
                  for (const part of currentForm.scheme.subgraphQuery) {
                    if (part.part.includes(currentForm.item.index)) {
                      return part;
                    }
                  }
                })();

                let schemeNodes = currentForm.scheme.nodes
                let schemeLinks = []
                for (let link of currentForm.scheme.links) {
                  if (link.source.element === 'ontology' && link.target.element === 'ontology') schemeLinks.push(link)
                }

                let newLinks = vm.schemeController.loadLinks(schemeLinks, schemeNodes, [], [],
                    [], [], [])
                for (let i = 0; i < newLinks.length; i++) {
                  for (let j = 0; j < partQuery.sequenceLink.length; j++) {
                    if (partQuery.sequenceLink[j] === newLinks[i].short) {
                      newLinks[i].name = row.sLinksType[j]
                    }
                  }
                }
                vm.$refs['subgraph_window'].graph.nodes = schemeNodes
                vm.$refs['subgraph_window'].graph.links = newLinks
                vm.$refs['subgraph_window'].graph.attributes = []
                vm.$refs['subgraph_window'].graph.values = []
                vm.$refs['subgraph_window'].graph.operators = []
                vm.$refs['subgraph_window'].graph.logics = []

                vm.$refs['subgraph_window'].init()
                vm.$refs['subgraph_window'].restart('')
                vm.isLoading = false
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(function () {})
    },
    selectElement (obj) {
      this.selectedItem = obj.element
      this.elementCard.attributes = []
      let element = null
      if (obj.type === 'node') {
        element = this.subgraphElements.nodes[obj.element.short]
        this.elementCard.name = this.ontology.nodes[obj.element.name].label + ' : ' + element._id
      }
      else {
        element = this.subgraphElements.links[obj.element.short]
        this.elementCard.name = this.ontology.links[obj.element.name].label + ' : ' + element._id
      }

      this.elementCard.id = element._id
      for (let key of Object.keys(element)) {
        if (key !== '_id') {
          let numTest = Utils.testNumber(element[key])
          if (numTest !== null) {
            element[key] = element[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          }
          this.elementCard.attributes.push({
            name: key,
            value: element[key]
          })
        }
      }
    },
    addToCart() {
      this.$emit('add-to-cart', {element: this.elementCard, node: this.selectedItem})
    }
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<style>

  .break-long-words {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    display: inline-block;
  }

th {
  white-space: pre;
}

.vue-ads-text-xs {
  font-size: 18px
}

.vue-ads-w-6 {
  padding-left: 8px;
  padding-right: 8px;
  width: initial;
}

</style>
