<template>
  <div>
    <el-card
            :style="{width: '100%'}"
            v-loading="edgesWindow.loading">
      <template #header>
        <h5 class="mb-0 ml-0">{{data.selected.label}} : {{edgesWindow.totalEdges}} {{ $t('связей') }}</h5>
      </template>
      <el-row style="text-align: start">
        <el-col :span="6">
          <el-select v-model="edgesWindow.filter.nodes"
                     @change="init"
                     :placeholder="$t('Все узлы')"
                     multiple>
            <el-option
                    v-for="item in edgesWindow.avaliableNodes"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select v-model="edgesWindow.filter.edges"
                     style="margin-left: 8px"
                     @change="init"
                     :placeholder="$t('Все связи')"
                     multiple>
            <el-option
                    v-for="item in edgesWindow.avaliableEdges"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">

          <el-select
                  style="margin-left: 8px"
                  @change="init"
                  v-model="edgesWindow.filter.type">
            <el-option
                    :label="$t('Все')"
                    value="all">
            </el-option>
            <el-option
                    :label="$t('Входящие')"
                    value="in">
            </el-option>
            <el-option
                    :label="$t('Исходящие')"
                    value="out">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6">
          <el-select v-model="edgesWindow.filter.attributes"
                     style="margin-left: 8px"
                     :placeholder="$t('Все атрибуты')"
                     multiple>
            <el-option
                    v-for="item in attributes"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
          </el-select>
        </el-col>
      </el-row>


      <div :style="{'margin-top': '16px', overflow: 'auto', height: (height - 262 - favoriteHeight) + 'px'}" v-if="!edgesWindow.loading">
        <el-table
                border
                fit
                @sort-change="sortChange"
                :data="paginateData"
                :style="{width: '100%'}">
          <el-table-column
                  prop=""
                  width="220"
                  :label="$t('Схема')">
            <template #default="props">
              <EdgesSimpleWindowElement
                  :ref="'id' + props.row.link"
                  v-if="rerenderSVG"
                      @close-info="closeInfo"
                      @show-info="showInfo"
                                         :item="props.row"></EdgesSimpleWindowElement>
            </template>
          </el-table-column>

          <el-table-column
                  v-for="attr in attributesFilter"
                  :key="attr"
                  :prop="attr"
                  width="200"
                  sortable
                  :label="attr">
              <template #default="props">
              {{props.row.attributes[attr]}}
            </template>
          </el-table-column>
        </el-table>



        <el-pagination
                background
                class="mt-3"
                type="primary"
                layout="prev, pager, next"
                :total="edgesWindow.totalEdges"
                :current-page = "page"
                :page-size= "perPage"
                @current-change = "pageChange">

        </el-pagination>
      </div>
      <div style="margin-top: 16px">
        <el-button @click="close">
          {{ $t('Закрыть') }}
        </el-button>
      </div>
    </el-card>

  </div>
</template>

<script>
  import VisController from '../../controllers/vis.controller'
  import EdgesSimpleWindowElement from './EdgesSimpleWindowElement'
  import Utils from '../../services/utils'
  import OntologyController from "../../controllers/ontology.controller";
  import CashController from "../../controllers/cash.controller";

  export default {
  name: 'EdgesTable',
    components: {
      EdgesSimpleWindowElement
    },
  data() {
    return {
      width: Math.max(
              document.documentElement.clientWidth,
              window.innerWidth || 0
      ) * 3 / 4 + 16,
      height: Math.max(
              document.documentElement.clientHeight,
              window.innerHeight || 0
      ) - 56,
      ontology: {
        nodes: {},
        links: {}
      },
      edgesWindow: {
        loading: true,
        avaliableNodes: [],
        data: [],
        totalEdges: 0,
        filter: {
          type: 'all',
          attributes: [],
          edges: [],
          nodes: []
        }
      },
      attributes: [],
      visController: null,
      ontologyController: null,
      cashController: null,
      page: 1,
      start: 0,
      end: 0,
      sort: {
        prop: null,
        order: null
      },
      rerenderSVG: true,
      perPage: 20,
      totalItems: 0,
      favoriteHeight: null,
      observerFavour: null,
    }
  },
    props: {
      data: {
        type: Object,
        default: function () {
          return {
            selected: null,
            edges: [],
            avaliableNodes: []
            }
          }
        }

  },
  computed: {
    paginateData () {
      let data = this.edgesWindow.data
      const vm = this
      // console.log(this.sort)
      let end = 0
      if (this.page*this.perPage < this.edgesWindow.totalEdges) end = this.page*this.perPage
      else end = data.length
      if (this.sort.prop !== null) {
        return data.sort(function (row1, row2) {
          let a = row1.attributes[vm.sort.prop]
          let b = row2.attributes[vm.sort.prop]
          let order = 1

          let testNumber1 = Utils.testNumber(a)
          let testNumber2 = Utils.testNumber(b)
          if (testNumber1 !== null && testNumber2 !== null) {
            if (testNumber1 > testNumber2) return 1 * order
            else if (testNumber1 < testNumber2) return -1 * order
            return 0
          } else {
            return a.localeCompare(b) * order
          }
        }).slice((this.page - 1) * this.perPage, end)
      } else {
        return data.slice((this.page - 1) * this.perPage, end)
      }

    },
    attributesFilter() {
      if (this.edgesWindow.filter.attributes.length === 0) return this.attributes
      let attributesToGo = []
      for (let attr of this.attributes) {
        if (this.edgesWindow.filter.attributes.includes(attr)) attributesToGo.push(attr)
      }
      return attributesToGo
    }
  },
    beforeMount() {
      this.ontologyController = new OntologyController()
      this.cashController = new CashController()
      this.ontology = this.ontologyController.getOntology()
    },
    mounted() {
      this.visController = new VisController()
      this.page = 1
      this.init();
      this.detectFavourEl();
    },
    beforeUnmount() {
      this.observerFavour.disconnect()
    },
  methods: {
    sortChange (sort) {
      const vm = this
      this.rerenderSVG = false
      setTimeout(() => {
        vm.rerenderSVG = true
      }, 60)
      this.sort.prop = sort.prop
      this.sort.order = sort.order
    },
    closeInfo(d) {
      this.$emit('close-info', d)
    },
    showInfo(d) {
      this.$emit('show-info', d)
    },
    init() {
      this.edgesWindow.totalEdges = 0
      this.edgesWindow.loading = true
      this.edgesWindow.data = []
      this.edgesWindow.selected = this.data.selected
      this.edgesWindow.avaliableNodes = this.data.avaliableNodes
      this.edgesWindow.avaliableEdges = this.data.avaliableEdges
      for (let edge of this.data.edges) {
        if (this.edgesWindow.filter.edges.includes(edge.name) || this.edgesWindow.filter.edges.length === 0) {
          if (edge.source.id === this.data.selected.id) {
            if ((this.edgesWindow.filter.type === 'all' || this.edgesWindow.filter.type === 'out') &&
                    (this.edgesWindow.filter.nodes.includes(edge.target.name) || this.edgesWindow.filter.nodes.length === 0)) {
              this.edgesWindow.data.push({
                type: 'out',
                selected: parseInt(this.data.selected.id),
                selectedName: this.data.selected.name,
                link: parseInt(edge.id),
                linkType: edge.name,
                node:  parseInt(edge.target.id),
                nodeName: edge.target.name
              })
            }
          } else if (edge.target.id === this.data.selected.id) {
            if ((this.edgesWindow.filter.type === 'all' || this.edgesWindow.filter.type === 'in') &&
                    (this.edgesWindow.filter.nodes.includes(edge.source.name) || this.edgesWindow.filter.nodes.length === 0)) {
              this.edgesWindow.data.push({
                type: 'in',
                selected: parseInt(this.data.selected.id),
                selectedName: this.data.selected.name,
                link: parseInt(edge.id),
                linkType: edge.name,
                node:  parseInt(edge.source.id),
                nodeName: edge.source.name
              })
            }
          }
        }
      }
      this.edgesWindow.totalEdges = this.edgesWindow.data.length
      const vm = this
      this.visController.getSimpleEdges({
        data: this.edgesWindow.data,
        revealed: this.cashController.getCashStore().revealed.elements
      }).then( (response) => {
        let data = response.data
        vm.attributes = data.attributes
        for (let i = 0; i < data.edgesAttrs.length; i++) {
          vm.edgesWindow.data[i].attributes = {}
          for (let attr of vm.attributes) {
            let atr = data.edgesAttrs[i][attr]
            if (typeof atr != 'undefined') {
              let numTest = Utils.testNumber(atr)
              if (numTest !== null) {
                atr = atr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
              }
              if (attr in data.edgesAttrs[i])  vm.edgesWindow.data[i].attributes[attr] = atr
              else  vm.edgesWindow.data[i].attributes[attr] = '-'
            }

          }
        }
        vm.edgesWindow.loading = false
      })
    },
    addToCart (row) {
      this.$emit('add-to-cart', {
        name: row.type  + ' : ' + row.id,
        id:  row.id,
        type: row.type,
        text: '',
        class:'element-cart-card'
      })
    },
    close () {
      this.$emit('close')
    },
    pageChange(page) {
      this.page = page
    },
    detectFavourEl() {
      let vm = this;
      let favoriteEl = document.getElementById("favorites");
      this.observerFavour = new ResizeObserver((entries) => {
        let elHeight = entries[0].contentRect.height;
        if(elHeight && elHeight > 47) {
          vm.favoriteHeight = elHeight - 47;
        } else {
          vm.favoriteHeight = 0;
        }
      });
      this.observerFavour.observe(favoriteEl);
    }
  },
};
</script>

<style scoped>

  >>> .el-transfer-panel{
    width: 300px;
  }


</style>
