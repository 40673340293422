import * as d3 from 'd3'
import arrowJSON from '../store/arrowJSON20'
import {store} from "../store";
import {i18n} from "../i18n/i18n";
//import {forceManyBodyReuse} from 'd3-force-reuse'

export default class D3Controller {
    constructor(svg, width, height) {
        this.ontology = store.getters['ontologyStore/getOntologyStore']
        // this.svg = svgSelection
        // this.graphSelection = graphSelection

        this.svg = d3.select(svg)
            .attr('height', height)
        this.graphSelection = this.svg.select('#gElement')
        //this.graphSelectionScale = this.svg.select('#gElementScale')
        this.height = height
        this.simulation = null
        this.zoom = null
        this.graph = {
            nodes: [],
            links: [],
        }

        this.forceMode = false

        this.keydownDefault = null
        this.keyupDefault = null

        this.nodesCounter = 0
        this.linksCounter = 0

        this.currentScale = {k:1, x:0, y:0}

        this.selected_link = null
        this.selected_node = null
        this.mousedown_node = null
        this.mouseup_node = null

        this.canBeSelected = true
        this.canBeEdited = true
        this.canDrawLine = true
        this.dragEnable = true


        const vm = this
        this.arrowJSON = arrowJSON

        // let ticksPerRender = 1;
        // requestAnimationFrame(function render() {
        //     for (var i = 0; i < ticksPerRender; i++) {
        //         vm.simulation.tick();
        //     }
        //     links
        //         .attr('x1', function(d) { return d.source.x; })
        //         .attr('y1', function(d) { return d.source.y; })
        //         .attr('x2', function(d) { return d.target.x; })
        //         .attr('y2', function(d) { return d.target.y; });
        //     nodes
        //         .attr('cx', function(d) { return d.x; })
        //         .attr('cy', function(d) { return d.y; });
        //
        //     if (vm.simulation..alpha() > 0) {
        //         requestAnimationFrame(render);
        //     }
        // })

        this.tickFunction = function () {
            for (let i = 0; i < 2; i++) {
                vm.simulation.tick();
            }
            const arrowJSON = vm.arrowJSON
            const marker = d => {
                if (!d.hidden && d.sameLink.all + 1 === d.sameLink.realCount) {
                    let angle = Math.PI / 2
                    let diffX = (d.source.x - d.target.x)
                    let diffY = d.source.y - d.target.y
                    if (diffX !== 0) angle = Math.atan(diffY / diffX)
                    if (diffX > 0) angle = Math.PI + angle
                    if (d.source === d.target) {
                        if (d.source.element === 'ontology' && d.target.element === 'ontology') {
                            return 'translate(' + d.source.x + ',' +
                                d.source.y + ') ' +
                                'rotate(' + 90 +
                                ')'
                        }
                    } else {
                        let shift = 10
                        let number = (d.sameLink.all - 2 * d.sameLink.current)
                        if (diffX > 0) number = number * (-1)
                        let shiftQ = number * 22
                        let L = Math.sqrt(diffX * diffX + diffY * diffY)
                        if (L <= 20* 2) return 'translate(' + d.source.x + ',' +
                            d.source.y + ') ' +
                            'rotate(' + 0 +
                            ')'
                        let t1 = (L - 20) / L
                        if (L < 800) t1 = arrowJSON['' + Math.ceil(Math.abs(number))][Math.ceil(L - 40)]
                        let t2 = t1 - 0.07
                        let x1 = d.source.x
                        let y1 = d.source.y
                        let x2 = (d.source.x + d.target.x) / 2 - shiftQ * Math.sin(angle)
                        let y2 = (d.source.y + d.target.y) / 2 + shiftQ * Math.cos(angle)
                        let x3 = d.target.x
                        let y3 = d.target.y
                        let Bx1 = (1 - t1) * (1 - t1) * x1 + 2 * t1 * (1 - t1) * x2 + t1 * t1 * x3
                        let By1 = (1 - t1) * (1 - t1) * y1 + 2 * t1 * (1 - t1) * y2 + t1 * t1 * y3
                        let Bx2 = (1 - t2) * (1 - t2) * x1 + 2 * t2 * (1 - t2) * x2 + t2 * t2 * x3
                        let By2 = (1 - t2) * (1 - t2) * y1 + 2 * t2 * (1 - t2) * y2 + t2 * t2 * y3
                        let dopAngle = Math.PI / 2
                        if (Bx1 - Bx2 !== 0) dopAngle = Math.atan((By1 - By2) / (Bx1 - Bx2))
                        if (diffX > 0) dopAngle = Math.PI + dopAngle
                        let dop = (angle - dopAngle)
                        let x = Bx1 - shift * Math.cos(angle - dop)
                        let y = By1 - shift * Math.sin(angle - dop)
                        return 'translate(' + x + ',' +
                            y + ') ' +
                            'rotate(' + 180 / Math.PI * (angle - dop) +
                            ')'
                    }
                } else {
                    return 'translate(' + d.source.x + ',' +
                        d.source.y + ') ' +
                        'rotate(' + 0 +
                        ')'
                }

            }

            const transform = d => {
                return 'translate(' + (d.x) + ',' + (d.y) + ')'
            }

            const linkText = d => {
                let angle = Math.PI / 2
                if (d.source.x - d.target.x !== 0) angle = Math.atan((d.source.y - d.target.y) / (d.source.x - d.target.x))
                let shift = (d.sameLink.all / 2 - d.sameLink.current) * 44
                let x1 = (-shift) * Math.sin(angle) / 2 + (d.source.x + d.target.x) / 2
                let y1 = shift * Math.cos(angle) / 2 + (d.source.y + d.target.y) / 2
                return 'translate(' + ((x1) + 10 * Math.sin(angle)) + ',' +
                    ((y1) - 10 * Math.cos(angle)) + ') ' +
                    'rotate(' + 180 / Math.PI * angle +
                    ')'
            }

            const transformInfo = d => {
                if (d.target.id !== d.source.id) {
                    let angle = Math.PI / 2
                    if (d.source.x - d.target.x !== 0) angle = Math.atan((d.source.y - d.target.y) / (d.source.x - d.target.x))
                    let shift = (d.sameLink.all / 2 - d.sameLink.current) * 44
                    let x1 = (-shift) * Math.sin(angle) / 2 + (d.source.x + d.target.x) / 2
                    let y1 = shift * Math.cos(angle) / 2 + (d.source.y + d.target.y) / 2
                    return 'translate(' + x1 + ',' + y1 + ')'
                } else {
                    let x1 = d.source.x - 50
                    let y1 = d.source.y - 120
                    let x2 = d.source.x + 50
                    let y2 = d.source.y - 120
                    return 'translate(' + (x1 + x2) /2 + ',' + ((y1 + y2)/2 + 20) + ')'
                }
            }

            // const transformText = d => {
            //     if (d.target.id !== d.source.id) {
            //         let angle = Math.PI / 2
            //         if (d.source.x - d.target.x !== 0) angle = Math.atan((d.source.y - d.target.y) / (d.source.x - d.target.x))
            //         return 'translate(' + (d.source.x + d.target.x) / 2 + ',' + ((d.source.y + d.target.y) / 2) + ')'+
            //             ' rotate(' + 180 / Math.PI * (angle) +
            //             ')'
            //     } else {
            //         let x1 = d.source.x - 50
            //         let y1 = d.source.y - 120
            //         let x2 = d.source.x + 50
            //         let y2 = d.source.y - 120
            //         return 'translate(' + (x1 + x2) /2 + ',' + ((y1 + y2)/2 + 20) + ')'
            //     }
            // }

            const link = d => {
                try {
                    if (!d.hidden) {
                        if (d.target.id !== d.source.id) {
                            let angle = Math.PI / 2
                            if (d.source.x - d.target.x !== 0) angle = Math.atan((d.source.y - d.target.y) / (d.source.x - d.target.x))
                            let number = (d.sameLink.all - 2 * d.sameLink.current)
                            let shift = number * 22

                            let x1 = -shift * Math.sin(angle) + (d.source.x + d.target.x) / 2
                            let y1 = shift * Math.cos(angle) + (d.source.y + d.target.y ) / 2

                            return 'M' + (d.source.x) + ',' + (d.source.y ) + ' Q' + x1 + ',' + y1 + ' '
                                + (d.target.x) + ',' + (d.target.y)
                        } else {
                            let x1 = d.source.x - 50
                            let y1 = d.source.y - 120
                            let x2 = d.source.x + 50
                            let y2 = d.source.y - 120
                            return 'M' + d.source.x + ',' + d.source.y + ' C' + x1 + ',' + y1 + ' ' + x2 + ',' + y2 + ' ' + d.source.x + ',' + d.source.y
                        }
                    } else {
                        return 'M' + (d.source.x) + ',' + (d.source.y) + ' L' +
                            + (d.target.x) + ',' + (d.target.y)
                    }
                } catch (e) {
                    //
                }
            }

            const graph = vm.graphSelection

            graph.selectAll('.link').attr('d', link)
            graph.selectAll('.text-count').attr('transform', transformInfo)
            graph.selectAll('.text-link').attr('transform', linkText)
            // graph.selectAll('.text-info').attr('transform', transformInfo)

            graph.selectAll('.node').attr('transform', transform)

            graph.selectAll('.marker').attr('transform', marker)
        }

    }

    setCounters(nodes, links) {
        this.nodesCounter = nodes
        this.linksCounter = links
    }

    pie() {
        return d3.pie()
    }

    arc() {
        return d3.arc()
    }

    select(element) {
        return d3.select(element)
    }

    zoomIdentity() {
        return d3.zoomIdentity
    }

    event() {
        return d3.event
    }

    mouse(element) {
        return d3.mouse(element)
    }

    updateGraph(graph) {
        this.graph.links = graph.edges
        for (let i=0; i<this.graph.links.length; i++) {
            let linklabel = graph.edges[i].name
            if (graph.edgenames.length > this.graph.links[i].id) {
                if (graph.edgenames[this.graph.links[i].id] !== null) {
                    linklabel = graph.edgenames[this.graph.links[i].id]
                }
            }
            let t_linklabel = linklabel
            try {
                t_linklabel = i18n.global.t(linklabel)
            } catch(e) {
                console.log('Ошибка перевода. Строка: ', linklabel, 'Описание: ', e);
                t_linklabel = linklabel
            }
            if (t_linklabel!==null) {
                this.graph.links[i].label = t_linklabel
            }
        }
        this.graph.nodes = graph.nodes
    }

    bindActions(dblclick, mousedown, mouseup, mousemove) {
        this.graphSelection
            .on('dblclick', dblclick)
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)
            .on('mousemove', mousemove)

    }

    bindKeyUp(func) {
        this.keydownDefault = func
        d3.select(window).on('keyup', func)
    }

    bindKeyDown(func) {
        d3.select(window).on('keydown', func)
        this.keyupDefault = func
    }

    bindZoom(scale) {
        const vm = this
        this.zoom = d3.zoom()
            .scaleExtent(scale)
            .on('zoom', function () {
                const transform = d3.event.transform;
                vm.currentScale.k = transform.k
                vm.currentScale.x = transform.x
                vm.currentScale.y = transform.y
                vm.graphSelection.attr('transform', transform)
            })
        this.svg.call(this.zoom)
        this.svg.on("dblclick.zoom", null);
    }

    unbindZoom() {
        this.svg.on('.zoom', null)
    }

    tick() {
        this.tickFunction()
    }

    bindSimulation(forceProperties) {
        this.simulation = d3.forceSimulation()
            .force('link', d3.forceLink())
            .force('charge', d3.forceManyBody())
            // .force('collide', d3.forceCollide())
            .force('center', d3.forceCenter())
            .force('forceX', d3.forceX())
            .force('forceY', d3.forceY())
            .on('tick', this.tickFunction)
        this.restartSimulation(forceProperties)
    }

    restartSimulation(forceProperties) {
        if (this.simulation !== null) {
            const { simulation } = this
            simulation.force("charge")
                .strength(forceProperties.charge.strength * forceProperties.charge.enabled)
                .theta([1])
                .distanceMin(forceProperties.charge.distanceMin)
                .distanceMax(forceProperties.charge.distanceMax);
            // simulation.force("collide")
            //     .strength(forceProperties.collide.strength * forceProperties.collide.enabled)
            //     .radius(forceProperties.collide.radius)
            //     .iterations(forceProperties.collide.iterations);
            simulation.force("link")
                .distance(forceProperties.link.distance)
                .iterations(forceProperties.link.iterations);
            this.simulation.nodes(this.graph.nodes)
            this.simulation.force('link').links(this.graph.links)
            this.simulation.alpha(1).restart()
        }
    }

    disableDrag() {
        this.dragEnable = false
        this.graphSelection.selectAll('.node').on('mousedown.drag', null)
    }

    enableDrag() {
        this.dragEnable = true
        const vm = this
        this.graphSelection.selectAll('.node')
            .call(d3.drag()
                .on('start', function (d) {
                    d3.event.sourceEvent.stopPropagation();
                    if (!d3.event.active) {
                        vm.simulation.alphaTarget(0.3).restart()
                    }
                    d.fx = d.x
                    d.fy = d.y
                })
                .on('drag', function (d) {
                    d.fx = d3.event.x
                    d.fy = d3.event.y
                })
                .on('end', function (d) {
                    if (!d3.event.active) { vm.simulation.alphaTarget(0.0001) }
                    d.fixed = true
                    if (vm.forceMode) {
                        d.fx = d3.event.x
                        d.fy = d3.event.y
                    } else {
                        d.fx = d3.event.x
                        d.fy = d3.event.y
                    }
                })
            )
    }


    removeElements(className) {
        this.graphSelection.selectAll(className).remove()
    }

    getNeighbours(id) {
        let neighbours = []
        for (let link of this.graph.links) {
            if (link.source.id === id) {
                neighbours.push(link.target.id)
            }
            if (link.target.id === id) {
                neighbours.push(link.source.id)
            }
        }
        return neighbours
    }

    selectNodeAndNighbours(id) {
        // const neighbours = this.getNeighbours(id)
        // console.log('selectNodeAndNighbours', id, neighbours)
        this.graphSelection.selectAll('.label').attr('style', d => {
            if (id === d.id) return 'display: block'
            return 'display: none'
        })
        // this.graphSelection.selectAll('.node').attr('style', d => {
        //     if (id === d.id || neighbours.includes(d.id)) return 'pointer-events: fill; opacity: 1'
        //     else return 'pointer-events: none; display: none;'
        // })
        // this.graphSelection.selectAll('.marker').attr('style', d => {
        //     if ((id === d.source.id && neighbours.includes(d.target.id) )|| (id === d.target.id && neighbours.includes(d.source.id))) return 'pointer-events: fill; opacity: 1'
        //     else return 'pointer-events: none; display: none;'
        // })
        // this.graphSelection.selectAll('.text-count').attr('style', d => {
        //     if ((id === d.source.id && neighbours.includes(d.target.id) )|| (id === d.target.id && neighbours.includes(d.source.id))) return 'pointer-events: fill; opacity: 1'
        //     else return 'pointer-events: none; display: none;'
        // })
        // this.graphSelection.selectAll('.text-link').attr('style', d => {
        //     if ((id === d.source.id && neighbours.includes(d.target.id) )|| (id === d.target.id && neighbours.includes(d.source.id))) return 'pointer-events: fill; opacity: 1'
        //     else return 'pointer-events: none; display: none;'
        // })
        // this.graphSelection.selectAll('.link').attr('style', d => {
        //     if ((id === d.source.id && neighbours.includes(d.target.id) )|| (id === d.target.id && neighbours.includes(d.source.id))) return 'stroke:' + d.color + ';stroke-width:' + 8*d.size + 'px;fill: none; opacity: 1'
        //     else return 'stroke:' + d.color + ';stroke-width:' + 8*d.size + 'px;fill: none;display: none;'
        // })
    }


    createLinks(action, beforeElement, linkMousedown, linkMouseup, mouseenter, mouseleave) {
        const link = this.graphSelection.selectAll('.link').data(this.graph.links)
        // const vm = this
        link.enter().insert('g', beforeElement).attr('class', 'marker').append('polygon').attr('points', '13,0 -6,6 -6,-6')
            .attr('style', d => { return 'fill:' + d.color})

        link.enter().insert('path', beforeElement)
            .attr('class', 'link')
            .attr('x1', d => d.source.x)
            .attr('y1', d => d.source.y)
            .attr('x2', d => d.target.x)
            .attr('y2', d => d.target.y)
            .attr('style', d => {
                if (d.hidden) return 'display: none;fill: none;'
                return 'stroke:' + d.color + ';stroke-width:' + 8*d.size + 'px;fill: none;'})
            .on('mousedown', linkMousedown)
            .on('mouseup', linkMouseup)
            .on('mouseenter', mouseenter)
            .on('mouseleave', mouseleave)


        link.enter().insert('g', beforeElement).attr('class', 'text-link').append('text')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '10px')
            .attr('fill', 'black')
            .attr('font-weight', '200')
            .attr('text-anchor', 'middle')
            .text((d) => {
                if (d.hidden) return ''
                return d.label})


        let gCount = link.enter().insert('g', beforeElement).attr('class', 'text-count').attr('id', (d) => 'gl' + d.counter)

        gCount.insert('circle')
            .attr('r', 10)

            .attr('style', d => {
                if (d.sameLink.realCount !== d.sameLink.all + 1 && d.sameLink.current === 0) return 'stroke: #666 ;stroke-width:2px;fill: white;'
                return 'display: none;'
            })
            .attr('cx', 0)
            .attr('cy', 0)

        gCount.insert('text')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', 4)
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '13px')
            .attr('fill', 'black')
            .attr('font-weight', '600')
            .attr('text-anchor', 'middle')
            .text(d => {
                if (d.sameLink.realCount !== d.sameLink.all + 1 && d.sameLink.current === 0) return  d.sameLink.realCount
                return ''
            })

        link.exit().remove()
    }

    createOntology(action, beforeElement, mousedown, mouseup, double, mouseenter, mouseleave) {
        const node = this.graphSelection.selectAll('.ontology').data(this.graph.nodes)
        const vm = this
        const gNode = node.enter().insert('g', beforeElement).attr('class', 'node ontology').attr('id', (d) => 'gg' + d.counter)
            .on('dblclick', double)
            .on('mouseenter', mouseenter)
            .on('mouseleave', mouseleave)

        this.graphSelection.append('text')
            .attr('class', 'test-icon')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr("fill","black")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size',  42 + 'px')
            .text('');

        const gLabel = gNode.insert('g').attr('class', 'label').attr('style', (d) => {
            if (d.freeze) return 'display: block'
            return 'display: none'
        })


        this.graphSelection.insert('text')
            .attr('class', 'text-label-test')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '20px')
            .attr('fill', 'white')
            .attr('font-weight', '500')
            .attr('text-anchor', 'middle')
            .text('')
        gLabel.insert('path')
            .attr('d', d => {
                let r = 16
                if (d.label !== null) {
                    let length = 0
                    let text = vm.graphSelection.select('.text-label-test').text(d.label)
                    d.lengthText = text._groups[0][0].getComputedTextLength()
                    length = d.lengthText + (20 * d.size) + 10
                    return 'M' + 0 + ',' + 0  + ' ' +
                        'm' + '0, ' + (-r) + ' ' +
                        'a' + r + "," + r + " 0 1,0 " + 0  + ","  + r*2 + ' '  +
                        'l' + length + ', ' + 0 + ' ' +
                        'l' + 0 + ', ' + -r*2 + ' ' +
                        'l' + -length + ', ' + 0 + ' '
                } else {
                    return 'M' + 0 + ',' + 0  + ' '
                }
            })
            .attr('style', 'fill: #202020')

        gLabel.insert('text')
            .attr('class', 'text-label')
            .attr('x', d => {
                return (20 * d.size) + d.lengthText/2 + 3
            })
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '20px')
            .attr('fill', 'white')
            .attr('font-weight', '500')
            .attr('text-anchor', 'middle')
            .text(d => {
                return d.label
            })

        this.graphSelection.selectAll('.text-label-test').remove()

        this.graphSelection.append('text')
            .attr('class', 'test-icon')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr("fill","black")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size',  42 + 'px')
            .text('');

        gNode.insert('circle')
            .attr('class', 'stroke')
            .attr('style', d => {
                if (!vm.forceMode){
                    d.fx = d.x
                    d.fy = d.y
                }
                return 'pointer-events: fill; fill:' + d.color + '; stroke:white;stroke-width:1px'
            })
            .attr('r',d => {return 20 * d.size})

        gNode.append('text')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr('x', (d) => {
                let text = vm.graphSelection.select('.test-icon')
                    .attr('font-size', function() {    if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return 0
                else return (19 * d.size) + 'px'} )
                    .text(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let size = text.node().getBBox()
                return -size.width/2
            })
            .attr('y', (d) => {
                let text = vm.graphSelection.select('.test-icon')
                    .attr('font-size', function() {    if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return 0
                    else return (19 * d.size) + 'px'} )
                    .text(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let size = text.node().getBBox()
                return -(size.height)/2 - size.y - 1
            })
            .attr("fill","white")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', function(d) {    if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return 0
            else return (19 * d.size) + 'px'} )
            .text(function(d) {
                if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return ''
                else return String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode))
            });

        this.graphSelection.selectAll('.test-icon').remove()

        gNode.append('foreignObject')
            .attr('width', '36px')
            .attr('height', '36px')
            .attr('id', (d) => 'middleButton' + d.counter)
            .attr(
                "transform",
                `translate(${-18}, ${-18})`
            )
            .on('click', mousedown)
            .on('mouseup', mouseup)

        node.exit()
            .remove()

        return gNode
    }

    resetMouseVars() {
        this.graphSelection.selectAll('.stroke').attr('style', d => {
            return 'pointer-events: fill; fill:' + d.color + '; stroke:white;stroke-width:1px'
        })
        this.graphSelection.selectAll('.link').attr('style', d => { return 'stroke:' + d.color + ';stroke-width:' + 8*d.size + 'px;fill: none; opacity: 1'})
        this.graphSelection.selectAll('.marker').attr('style', 'pointer-events: fill; opacity: 1')
        this.graphSelection.selectAll('.text-count').attr('style', 'pointer-events: fill; opacity: 1')
        this.graphSelection.selectAll('.text-link').attr('style', 'pointer-events: fill; opacity: 1')
        this.graphSelection.selectAll('.node').attr('style', 'pointer-events: fill; opacity: 1')
    }
}