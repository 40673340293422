<template>
  <div
    class="graph"
    oncontextmenu="return false;"
    id="graph"
    :style="{  position: 'absolute', left:0, right: 0, width: (width-2) + 'px', height: (height-2) + 'px', border: '1px solid black' }"
  >
    <!--<svg id="mapSVG" :width="(width-2) + 'px'" :height="(height-2) + 'px'" xmlns="http://www.w3.org/2000/svg" :style="{backgroundImage: 'url('+srcMap+')'}"></svg>-->
    <svg :style="{  position: 'absolute', left:0, right: 0, width: (width-2) + 'px', height: (height-2) + 'px'}" height="100%" width="100%"
         xmlns="http://www.w3.org/2000/svg">
      <g height="100%" width="100%" id="gElement">
        <foreignObject  :style="menu" id="addToCart" >
          <div :style="menu" id="addToCart">
            <div @click="addToCart">
              <el-dropdown-item command="a">{{ $t('В избранное') }}</el-dropdown-item>
            </div>
            <div @click="showEdges">
              <el-dropdown-item command="a">{{ $t('Связи') }}</el-dropdown-item>
            </div>
          </div>
        </foreignObject>
      </g>
    </svg>

    <div
      :style="{position: 'absolute', left:8+'px', right: 0, top:8+'px', pointerEvents: 'none', 'z-index': 1000}"
      id="graph-labels"
    >
      <el-row type="flex" :style="{position: 'relative', left:16+'px', 'color':'white', 'font-size': '16px'}" justify="start">
        <div v-for="node in legend.nodes"
             :key="node.type"
             @click="labelEdit(node.type)"
             style="pointer-events: all"
        >
           <span
               class="mr-2 badge badge-pill"
               :style="{position:'relative', background: ontology.nodes[node.type].color }"
           >{{node.title +' (' +countNodes[node.type]+')'}}</span>

        </div>
      </el-row>
      <el-row type="flex" class="mt-1" :style="{position: 'relative', left:16+'px', 'color':'white', 'font-size': '16px'}" justify="start">
        <span
          class="mr-2 badge badge-pill"
          type="primary"
          v-for="edge in legend.edges"
          :key="edge.name"
          :style="{position:'relative', background: 'linear-gradient(90deg, hsl(0,0%,0%) 0%, hsl(0,0%,40%) 50%, hsl(0,0%,70%) 100%)' }"
        >{{edge.title +' (' +countEdges[edge.type]+')'}}</span>
      </el-row>
    </div>

    <el-card v-if="edgesWindow.show" v-loading="edgesWindow.loading"
             :style="{width: 400 + 'px', height: (height - 200) + 'px',  position: 'absolute', 'z-index': 2000, 'top': '16px', 'left': '16px'}">
      <template #header>
        <h5 class="mb-0 ml-0">{{edgesWindow.selected.label}} : {{edgesWindow.totalEdges}} {{ $t('связей') }}</h5>
      </template>
      <el-row style="text-align: start">
        <el-select v-model="edgesWindow.filter">
          <el-option
                  :label="$t('Все')"
                  value="all">
          </el-option>
          <el-option
                  v-if="!scheme.symmetry"
                  :label="$t('Как первый')"
                  value="first">
          </el-option>
          <el-option
                  v-if="!scheme.symmetry"
                  :label="$t('Как второй')"
                  value="second">
          </el-option>
        </el-select>
      </el-row>


      <div :style="{'margin-top': '16px', overflow: 'auto', height: (height - 300) + 'px'}" v-if="!edgesWindow.loading">

          <div v-for="id in Object.keys(edgesWindow.data)"
              :key="id">
            <EdgesWindowElement
                    @close-info="closeInfo"  @show-info="showInfo"
                    @subgraphs="showSubgraphEdges" style="margin-bottom: 8px"
                                                v-if="edgesWindow.data[id].first.length > 0 && (edgesWindow.filter === 'all' || edgesWindow.filter === 'first')"
                    :selected="edgesWindow.selectedID" :id="id" :path="scheme.path" :items="edgesWindow.data[id]" :type="'first'"></EdgesWindowElement>
            <EdgesWindowElement
                    @close-info="$emit('close-info')"  @show-info="$emit('show-info')"
                    @subgraphs="showSubgraphEdges" style="margin-bottom: 8px"
                                 v-if="edgesWindow.data[id].second.length > 0 && (edgesWindow.filter === 'all' || edgesWindow.filter === 'second')"
                    :selected="edgesWindow.selectedID" :id="id" :path="scheme.path" :items="edgesWindow.data[id]"  :type="'second'"></EdgesWindowElement></div>
      </div>
      <div style="margin-top: 16px">
        <el-button @click="edgesWindow.show = false">
          {{ $t('Закрыть') }}
        </el-button>
      </div>
    </el-card>

    <el-card v-if="subgraphEdgesWindow.show"
             :style="{width: 800 + 'px', height: (height - 200) + 'px',  position: 'absolute', 'z-index': 2000, 'top': '16px', 'left': '16px'}">
<!--      <template #header>-->
<!--        <h5 class="mb-0 ml-0">{{edgesWindow.selected.label}} : {{edgesWindow.totalEdges}} связей</h5>-->
<!--      </template>-->
      <el-row style="text-align: start">
        <el-button @click="backToEdgesWindow">
          {{ $t('Назад') }}
        </el-button>
      </el-row>
      <NeoSearchSubgraph  @add-to-cart="addToCartSubgraph" :data="subgraphEdgesWindow.data"> </NeoSearchSubgraph>
      <div style="margin-top: 16px">
        <el-button @click="edgesWindow.show = false">
          {{ $t('Закрыть') }}
        </el-button>
      </div>
    </el-card>


    <el-card :id="showFilter ? 'control-pane' : 'control-pane-collapse'">
      <template #header>
        <h5 class="mb-0 ml-0">{{ $t('Фильтр') }}</h5>
          <div :style="{float: 'right', position: 'absolute', padding: '3px 0', top: '10px', right: '16px'}">
            <div v-if="showFilter" @click="showFilter = false">
              <i  class="fas fa-minus fa-lg icon-cog"></i>
            </div>
            <div v-else @click="showFilter = true">
              <i  class="fas fa-plus fa-lg icon-cog"></i>
            </div>
        </div>

      </template>
      <div v-if="showFilter">
        <div>
          <h6>{{ $t('Степень') }}: {{levelValue[0]}} - {{levelValue[1]}}</h6>
          <el-slider id="min-level" range :marks="marksLevel" :max="maxLevel" v-model="levelValue" />
          <br />
        </div>
        <div>
          <h6>{{ $t('Величина ребра') }}: {{countValue[0]}} - {{countValue[1]}}</h6>
          <el-slider id="min-count" range :marks="marksCount" :max="maxCount" v-model="countValue" />
          <br />
        </div>
      </div>
      <div v-if="showFilter" >
        <el-row type="flex" justify="space-around">
          <el-col :span="12">
            <el-button class="filter" type="primary" id="submit-btn" @click="acceptFilter">{{ $t('Применить') }}</el-button>
          </el-col>
          <el-col :span="12">
            <el-button class="filter" type="danger" id="reset-btn" @click="resetFilter">{{ $t('Сброс') }}</el-button>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <div id="control-force">
      <el-button type="primary" @click="changeForceMode">
        <div v-if="!forceStarted">
          <span style="font-size: 16px">Force</span>
          <i class="ml-2 fas fa-play fa-lg icon-cog"></i>
        </div>
        <div v-else>
          <span style="font-size: 16px">Force</span>
          <i style="color: #AC3B61;" class="ml-2 fas fa-stop fa-lg icon-cog"></i>
        </div>
      </el-button>
    </div>

    <div id="control-search" style="width: 380px">
      <el-row type="flex" justify="end" :style="{textAlign: 'center'}">
        <el-select v-model="nodeSearch"
                                @change="searchNodeStart"
                   value-key="value"
                   :no-match-text="$t('Ничего не найдено')"
                                style="width: 300px;display: inline-block; margin-right: 4px;"
                                v-if="searchExpand"
                                filterable
                                :placeholder="$t('Поиск')">
        <el-option
                v-for="item in nodeNames"
                :key="item.value"
                :label="item.name"
                :value="item">
        </el-option>
      </el-select>
          <el-button style="display: inline-block; height: fit-content" type="primary" @click="searchExpand = !searchExpand">
            <i class="fas fa-search fa-lg icon-cog"></i>
          </el-button>
      </el-row>
    </div>

    <div id="control-map">
      <el-button type="primary" @click="openMapGraph">
        <span style="font-size: 16px">{{ $t('Карта') }}</span>
      </el-button>
    </div>
    <div id="control-show-labels" style="width: 380px">
      <el-row type="flex" justify="end" :style="{textAlign: 'center'}">
        <el-checkbox @change="showAllLabelsChange" v-model="showAllLabels">{{ $t('Показать все надписи') }}</el-checkbox>
      </el-row>
    </div>
    <div
      class="text-center"
      v-if="isLoading"
      :style="{  position: 'absolute', left:0, right: 0, width: (width-2) + 'px', height: (height-2) + 'px', opacity: 0.5, backgroundColor: '#efefef'}"
    >
      <div  :style="{ position: 'relative',  left: ((width/2) - 100) + 'px', top:  height/2+'px' , width:200 + 'px', height: 200 + 'px'}">
        <b-spinner
          :style="{ position: 'relative', width:64 + 'px', height: 64 + 'px'}"
          label="Loading..."
        ></b-spinner>
        <p class="mt-3" style="font-size:16px">
          {{socketProgress}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import Utils from "../../services/utils";
  import VisController from "../../controllers/vis.controller";
  import EdgesWindowElement from "./EdgesWindowElement"
  import NeoSearchSubgraph from "./NeoSearchSubgraph"
  import D3Controller from "../../controllers/d3.graph.controller";
  import OntologyController from "../../controllers/ontology.controller";
  import CashController from "../../controllers/cash.controller";



export default {
  name: 'v-graph',
  props: {
    data: {},
  },
  components: {
    NeoSearchSubgraph,
    EdgesWindowElement
  },
  data () {
    return {
      graph: {
        nodes: [],
        edges: []
      },
      edgesWindow: {
        show: false,
        loading: false,
        data: [],
        selectedID: 0,
        totalEdges: 0,
        filter: 'all'
      },
      showAllLabels: false,
      subgraphEdgesWindow: {
        show: false,
        loading: false,
        data: {}
      },
      scheme: {},
      isOrgs: false,
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ) * 3 / 4 + 16,
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 56,
      padding: 150,
      forceProperties: {
        center: {
          x: 0.5,
          y: 0.5
        },
        charge: {
          enabled: true,
          strength: -800,
          distanceMin: 1,
          distanceMax: 2000
        },
        collide: {
          enabled: true,
          strength: .7,
          iterations: 1,
          radius: 35
        },
        forceX: {
          enabled: false,
          strength: 0.01,
          x: 0.5
        },
        forceY: {
          enabled: false,
          strength: 0.01,
          y: 0.5
        },
        link: {
          enabled: true,
          distance: 70,
          iterations: 1
        }
      },
      srcMap: require('@/assets/mapRussia.svg'),

      menu: {backgroundColor: 'white',display: 'none', height: 80 + 'px', width: (180)  + 'px','z-index':1000000},
      countNodes: {},
      countEdges: {'0': 0},
      edgeTitle: {
        id: '',
        index: -1
      },
      legend: {
        nodes: [],
        edges: []
      },
      s: null,
      isLoading: true,
      filter: null,
      maxCount: 0,
      maxLevel: 0,
      maxCountAll: [],
      maxLevelAll: [],
      levelValue: [0, 1],
      countValue: [0, 1],
      marksLevel: {
        0: '0'
      },
      ontology: {
        nodes: {},
        links: {},
        shortList: {}
      },
      marksCount: {
        0: '0'
      },
      type_edge_show: 'weak',
      mapRussia: null,
      socketProgress: this.$t('Загрузка'),
      showFilter: true,
      forceStarted: false,
      searchExpand: false,
      nodeSearch: null,
      nodeNames: [],
      selectedNode: null,
      visController: null,
      showElementInfo: true,
      showElementInfoTimeout: null,
      controller: null,
      ontologyController: null,
      cashController: null
    }
  },
  mounted: function () {
    this.ontologyController = new OntologyController()
    this.cashController = new CashController()
    this.ontology = this.ontologyController.getOntology()
    this.visController = new VisController()
    this.controller = new D3Controller(this.$el.querySelector('svg'), this.width, this.height)
    this.controller.bindZoom([1 / 4, 4])
    //this.bindMouseActions()
    this.init(this.data)
  },

  methods: {
    showAllLabelsChange() {
      for (let i = 0; i < this.graph.nodes.length; i++) {
        this.graph.nodes[i].freeze = this.showAllLabels
      }
      this.draw()
    },
    openMapGraph() {
      this.$emit('open-map', {
        graph: this.graph,
        countNodes: this.countNodes,
        countEdges: this.countEdges,
        maxLevelAll: this.maxLevel,
        maxCountAll: this.maxCount,
        maxCount: this.maxCount,
        maxLevel: this.maxLevel,
        legend: this.legend
      })
    },
    showInfo(d) {
      this.$emit('show-info', d)
    },
    closeInfo(d) {
      this.$emit('close-info', d)
    },
    showSubgraphEdges (obj) {
      this.edgesWindow.show = false
      let type = obj.type
      let anotherID = obj.anotherID
      let nodeID = this.edgesWindow.selected.id
      let subgraphQuery = [{
        part: [],
        sequence: [],
        sequenceLink: [],
        query: ''
      }]
      for (let node of this.scheme.path.nodes) {
        subgraphQuery[0].part.push(node.index)
        subgraphQuery[0].sequence.push(node.short)
      }
      for (let link of this.scheme.path.ontologyLinks) {
        subgraphQuery[0].sequenceLink.push(link.short)
      }
      let query = 'MATCH ' + this.scheme.query + '\n'
      if (type === 'first') {
        query = query + 'WHERE (ID(first) = ' + nodeID + ') AND ' +
                '(ID(second) = ' + anotherID + ')';
      } else if (type === 'second') {
        query = query + ' WHERE (ID(first) = ' + anotherID + ') AND ' +
                '(ID(second) = ' + nodeID + ')\n';
      }
      let withArray = []
      let queryFirst = query + ' RETURN count(first)'
      for (let node of this.scheme.path.nodes) {
        withArray.push('ID(' + node.short + ') as ' + node.short + '_id')
      }
      for (let link of this.scheme.path.ontologyLinks) {
        withArray.push('ID(' + link.short + ') as ' + link.short + '_id')
      }
      query = query + ' WITH ' + withArray.join(', ')
      query = query + ' SKIP {0} LIMIT {1}\n'
      withArray = []
      for (let node of this.scheme.path.nodes) {
        withArray.push(node.short + '_id')
      }
      for (let link of this.scheme.path.ontologyLinks) {
        withArray.push(link.short + '_id')
      }
      query = query + ' WITH ' + withArray.join(', ')
      subgraphQuery[0].query = query
      this.subgraphEdgesWindow.data = {
        scheme: {
          nodes: this.scheme.path.nodes,
          links: this.scheme.path.ontologyLinks,
          subgraphQuery: subgraphQuery,
          queryFirst: queryFirst
        },
        item: this.scheme.path.nodes[0],
        attributes: []
      }
      const vm = this
      setTimeout(function () {
        vm.subgraphEdgesWindow.show = true
      }, 110)
    },
    backToEdgesWindow () {
      this.subgraphEdgesWindow.show = false
      this.subgraphEdgesWindow.data = {}
      this.showEdges()
    },
    labelEdit (label) {
      this.$emit('show-edit-label', label)
    },
    showEdges () {
      this.subgraphEdgesWindow.show = false
      this.edgesWindow.show = true
      this.edgesWindow.totalEdges = 0
      this.edgesWindow.loading = true
      this.edgesWindow.data = {}
      this.edgesWindow.selected = this.selectedNode
      let neighboursID = []
      for (let edge of this.graph.edges) {
        if (edge.source.id === this.selectedNode.id) {
          neighboursID.push({
            id: parseInt(edge.target.id),
            name: edge.target.label
          })
        } else if (edge.target.id === this.selectedNode.id) {
          neighboursID.push({
            id: parseInt(edge.source.id),
            name: edge.source.label
          })
        }
      }
      this.edgesWindow.selectedID = this.selectedNode.id
      this.visController.getEdgesWindow({
        id: this.selectedNode.id,
        scheme: this.scheme,
        neighbours: neighboursID,
        revealed: this.cashController.getCashStore().revealed.elements
      }).then( (response) => {
        this.edgesWindow.data = response.data.edges
        for (let id in response.data.edges) {
          if (relation.hidden && !revealed.includes(relation.id)) continue
          this.edgesWindow.totalEdges += response.data.edges[id].first.length
          this.edgesWindow.totalEdges += response.data.edges[id].second.length
        }
        this.edgesWindow.loading = false
      })

    },
    addToCart () {
      this.$emit('add-to-cart', {
        name: this.selectedNode.name + ' : ' + this.selectedNode.id,
        id: this.selectedNode.id,
        type: this.selectedNode.type,
        text: this.selectedNode.label,
        class:'element-cart-card'
      })
    },
    addToCartSubgraph(obj) {
      this.$emit('add-to-cart', obj)
    },
    bindMouseActions() {
      const vm = this
      function svgDoubleClick() {
        vm.controller.event().preventDefault();
        vm.resetMouseVars()
      }
      this.controller.bindActions(svgDoubleClick, null, null, null)
    },
    changeForceMode () {
      this.forceStarted = !this.forceStarted
      this.controller.forceMode = !this.controller.forceMode
      if (this.controller.forceMode) {
        for (let i = 0; i < this.graph.nodes.length; i++) {
          this.graph.nodes[i].fx = null
          this.graph.nodes[i].fy = null
        }
      }
      this.draw()
    },
    displayLabelChange(obj){
      for (let i = 0; i < this.graph.nodes.length; i++) {
        if (this.graph.nodes[i].name === obj.type) this.graph.nodes[i].displayAttribute = obj.attribute
      }
      this.loadNodesNames()
    },
    loadNodesNames() {
      this.nodeNames = []
      const vm = this
      let data = []
      for (let node of this.graph.nodes) {
        data.push({
          id: node.id,
          attribute: node.displayAttribute,
          name: node.name
        })
      }
      this.visController.getAttributeNodes(data).then( (response) => {
        let nodes = response.data.nodes
        for (let i = 0; i < vm.graph.nodes.length; i++) {
          vm.graph.nodes[i].label = nodes[i]
          vm.nodeNames.push({name: nodes[i], value: data[i].id})
        }
        vm.nodeNames.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
        vm.draw()
      })
    },
    init (form) {
      this.scheme = {
        path: form.pathScheme,
        symmetry: form.symmetry,
        names: form.names,
        query: form.pathVGraph
      }
      form['revealed'] = this.cashController.getCashStore().revealed.elements
      this.nodeSearch = null
      this.nodeNames = []
      this.graph.nodes = []
      this.graph.edges = []
      this.controller.bindSimulation(this.forceProperties)
      this.controller.enableDrag()
      for (let i = 0; i < this.countNodes.length; i++) {
        this.countNodes[i] = 0
      }
      for (let i = 0; i < this.countEdges.length; i++) {
        this.countEdges[i] = 0
      }
      const vm = this
      vm.isLoading = true

      const socket = this.$io('https://localhost:8083')

      // socket.on('graph-message', (data) => {
      //   if (data.count === -1) {
      //     vm.socketProgress = 'Объём определён'
      //   } else if (data.count === -2) {
      //     vm.socketProgress = 'Нахождение связей...'
      //   } else {
      //     vm.socketProgress = 'Загрузка: ' + (data.count * 100.0 / data.all).toFixed(2) + '%'
      //   }
      // })

      this.visController.getVGraph(form)
        .then(function (response) {
          let cash = this.cashController.getCashStore()
          const minNodeSize = 0.2
          const data = response.data
          // console.log(response.data)
          vm.legend = data.legend
          vm.maxLevelAll = data.maxLevel
          vm.maxCountAll = data.maxCount
          vm.maxCount = data.maxCount
          vm.maxLevel = data.maxLevel
          vm.levelValue[1] = data.maxLevel
          vm.countValue[1] = data.maxCount
          vm.marksLevel = {0: '0'}
          vm.marksCount = {0: '0'}
          vm.marksLevel['' + data.maxLevel] = '' + data.maxLevel
          vm.marksCount['' + data.maxCount] = '' + data.maxCount


          function getEdgeColor (count, max, type) {
            let a = Math.sqrt(max)
            let b = -a * Math.atanh(0.25 * 2 - 1)
            let num = (Math.tanh(-(count - b) / a) + 1) / 2
            let H = 0
            let S = 0
            return Utils.hslToRgb(H, S, Math.floor(num * 100))
          }

          function getParameter (count, max) {
            let a = Math.sqrt(max)
            let b = -a * Math.atanh(minNodeSize * 2 - 1)
            return (Math.tanh((count/(a/2.5) - b) / a) + 1) / 2
          }

          let i = 0
          for (let node of data.nodes) {
            let displayAttribute = ''
            if (Object.keys(cash.displayAttribute).includes(node.name)) {
              displayAttribute = cash.displayAttribute[node.name]
            } else {
              displayAttribute = ''
            }
            if (node.name in vm.countNodes) vm.countNodes[node.name] += 1
            else vm.countNodes[node.name] = 1

            vm.graph.nodes.push({
              id: node.id,
              name: node.name,
              label: node.id,
              x: (function () {
                if (node.main) return 0.5 * 1000
                else return Math.random() * 1000
              })(),
              y: (function () {
                if (node.main) return 0.5 * 1000
                else return Math.random() * 1000
              })(),
              displayAttribute: displayAttribute,
              isFiltered: false,
              size: getParameter(node.level, data.maxLevel),
              sizesAll: getParameter(node.level, data.maxLevel),
              level: node.level,
              fixed: true,
              color: vm.ontology.nodes[node.name].color,
              freeze: false,
            })
            i++
          }
          i = 0
          for (let relation of data.relations) {
            vm.countEdges[relation.name] += 1
            let target = null
            let source = null
            for (let i = 0; i < vm.graph.nodes.length; i++) {
              if (vm.graph.nodes[i].id === relation.source) source = vm.graph.nodes[i]
              if (vm.graph.nodes[i].id === relation.target) target = vm.graph.nodes[i]
            }
            vm.graph.edges.push({
              id: relation.id,
              source: source,
              label: relation.count + ' ' + this.$t('статей'),
              target: target,
              size: getParameter(relation.count, data.maxCount),
              color: getEdgeColor(relation.count, data.maxCount, relation.name),
              count: relation.count,
              hidden: false,
              type: relation.name,
              isActive: false,
              isFiltered: false
            })
            i++
          }
          // socket.disconnect()

          vm.isLoading = false
          vm.loadNodesNames()
          vm.draw()
        })
    },

    draw(action) {
      const vm = this
      this.controller.updateGraph(this.graph)

      this.controller.removeElements('.node')
      this.controller.removeElements('.link')

      this.controller.restartSimulation(this.forceProperties)

      function ontologyMouseDown(d) {
        //vm.controller.resetMouseVars()
        vm.menu.display = 'none'
        vm.selectedNode = d
        vm.controller.selectedNode = d
        if (vm.controller.event().button === 0) {
          d.freeze = !d.freeze
          if (d.freeze) {
            vm.controller.select(this).select('.label').attr('style', 'display: block')
          } else  {
            vm.controller.select(this).select('.label').attr('style', 'display: none')
          }
        } else if (vm.controller.event().button === 2) {

          vm.menu.display = 'block'

          vm.controller.graphSelection.select('#addToCart')
                  .attr('x', d.x)
                  .attr('y', d.y)
        }
      }

      function ontologyMouseDouble(d) {
        vm.controller.event().preventDefault();
        //vm.controller.resetMouseVars()
        if (vm.controller.event().button === 0) {
          const current = d
          vm.controller.selectNodeAndNighbours(current.id)

        }
      }

      function ontologyMouseEnter(d) {
        vm.controller.event().preventDefault();
        d.showElementInfo = true
        vm.showElementInfoTimeout = setTimeout(function () {
          if (d.showElementInfo) {
            vm.$emit('show-info', d)
          }
        }, 600)

      }
      function ontologyMouseLeave(d) {
        vm.controller.event().preventDefault();
        clearTimeout(vm.showElementInfoTimeout);
        d.showElementInfo = false
        vm.$emit('close-info', d)
      }

      this.controller.createOntology(action, '#addToCart', ontologyMouseDown, null, ontologyMouseDouble, ontologyMouseEnter, ontologyMouseLeave)

      this.controller.createLinks(action, '.node', null, null)
      this.controller.enableDrag()
      if (this.controller.forceMode) {
        this.controller.simulation.tick()
      }
    },
    resetMouseVars () {
      this.controller.resetMouseVars()
      this.menu.display = 'none'
    },

    acceptFilter () {
      this.isLoading = true
      const vm = this
      const type = 0
      setTimeout(() => {
        let arrayOfNodes = []
        vm.s.graph.nodes().forEach(function (n) {
          let level = n.level
          if (level > vm.levelValue[1] || level < vm.levelValue[0]) {
            n.hidden = true
            n.isFiltered = true
          } else {
            n.hidden = false
            n.isFiltered = false
          }
          let node = {
            id: n.id,
            hidden: n.hidden
          }
          arrayOfNodes.push(node)
        })
        vm.s.graph.edges().forEach(function (e) {
          if (e.type === type) {
            if (e.count > vm.countValue[1] || e.count < vm.countValue[0]) {
              e.hidden = true
              e.isFiltered = true
            } else {
              e.hidden = false
              e.isFiltered = false
            }

            for (let node of arrayOfNodes) {
              if (node.id === e.source || node.id === e.target) {
                if (node.hidden) {
                  e.hidden = true
                  e.isFiltered = true
                  break
                }
              }
            }
          }
        })
        vm.s.graph.nodes().forEach(function (n) {
          let id = n.id
          let flag = true
          vm.s.graph.edges().forEach(function (e) {
            if (e.type === type) {
              if (id === e.source || id === e.target) {
                if (!e.hidden) {
                  flag = false
                }
              }
            }
          })
          if (flag) {
            n.hidden = true
            n.isFiltered = true
          }
        })
        vm.isLoading = false
        vm.s.refresh()
      }, 40)
    },
    searchNodeStart () {
      const vm = this
      vm.isLoading = true
      setTimeout(() => {
        let nodeId = vm.nodeSearch.value
        vm.controller.selectNodeAndNighbours(nodeId)
        vm.isLoading = false
        vm.nodeSearch = null
      }, 40)
    },
    resetFilter () {
      this.levelValue = [0, this.maxLevel]
      this.countValue = [0, this.maxCount]
      this.controller.resetMouseVars()
      //this.acceptFilter()
    },
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
      integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
      crossorigin=""/>
<style scoped>
#container {
  position: absolute;
  width: 100%;
  height: 100%;
        background: rgba(255, 255, 255, 1);
}
#image-map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.leaflet-pane,
.leaflet-map-pane {
  position: absolute;
  left: 0;
}
.muted {
  fill-opacity: 0.1;
  stroke-opacity: 0.1;
}
#control-show-labels {
  top: 68px;
  /*bottom: 10px;*/
  right: 316px;
  margin-right: 24px;
  z-index: 1900;
  margin-top: 2px;
  position: absolute;
}
#control-force {
  top: 10px;
  /*bottom: 10px;*/
  right: 310px;
  margin-right: 24px;
  z-index: 1900;
  margin-top: 2px;
  position: absolute;
}
#control-search {
  top: 10px;
  /*bottom: 10px;*/
  right: 424px;
  margin-right: 24px;
  z-index: 1900;
  margin-top: 2px;
  position: absolute;
}
#control-map {
  top: 10px;
  /*bottom: 10px;*/
  right: 480px;
  margin-right: 24px;
  z-index: 1900;
  margin-top: 2px;
  position: absolute;
}
#control-pane {
  top: 10px;
  /*bottom: 10px;*/
  right: 10px;
  margin-right: 16px;
  position: absolute;
  width: 300px;
  z-index: 1900;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
#control-pane-collapse {
  top: 10px;
  /*bottom: 10px;*/
  right: 10px;
  margin-right: 16px;
  position: absolute;
  width: 300px;
  height: 50px;
  z-index: 50000;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
#control-pane > div {
  margin: 10px;
  overflow-x: auto;
}
.line {
  clear: both;
  display: block;
  width: 100%;
  margin: 0;
  padding: 12px 0 0 0;
  border-bottom: 1px solid #000;
  background: transparent;
}
.leaflet-container {
  background-color:rgba(255,250,250,0.0);
}
h2,
h3,
h4 {
  padding: 0;
}

h2.underline {
  color: #000;
  background: rgb(240, 240, 240);
  margin: 0;
  border-radius: 2px;
  padding: 8px 12px;
  font-weight: normal;
}
.hidden {
  display: none;
  visibility: hidden;
}

input[type="range"] {
  width: 160px;
}

.graph-label {
  width: 20px;
  height: 10px;
  border-radius: 20%;
  text-align: center;
  padding: 1.4644px;
  background: rgb(184, 54, 39);
}

.filter {
  width: 120px;
  text-align: center;
}
</style>
