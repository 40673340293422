<template>
  <div class="hello" :style="{display:'block', width: 'auto', height: 'auto', 'overflow': 'hidden'}">
    <div
      class="text-center"
      v-if="isLoadingConstructor"
      :style="{  'z-index': 500, position: 'absolute', left:0, right: 0, height: (height - 32) + 'px', opacity: 0.5, backgroundColor: '#efefef'}"
    >
      <b-spinner
        :style="{ position: 'relative',  top: height/2+'px' , width:64 + 'px', height: 64 + 'px'}"
        label="Loading..."
      ></b-spinner>
    </div>
    <el-container >
      <el-main :style="{padding: 0 + 'px'}">
        <el-row align="middle" type="flex" :style="{height: '42px', textAlign: 'left', backgroundColor: '#123c69'}">
        </el-row>
        <el-row>

          <el-col :span="24 - rightWindowSize" :style="{'padding-left': 0 + 'px', backgroundColor: '#fff'}">
            <el-row>
              <path-maker ref="constructor" :data="$data"
                          @restart="restartMaker"
                          @select-element="selectElement"
                            @instrument="changeInstrument($event)" @node-type="changeTypeNode($event)"></path-maker>
            </el-row>
          </el-col>
          <el-col :span="rightWindowSize" :style="{height: (height - 72 - 14) + 'px'}">
            <div class="ml-1 right-window" style="padding: 8px">
              <el-row>
                <el-col>
                  <div v-if="instruments[1]">
                    <el-collapse v-model="addTypes">
                      <el-collapse-item title="Узлы" name="node">
                        <el-row v-for="row in getRows(ontology.nodes)" :key="row" :style="{'margin-bottom': row == getRows(ontology.nodes) ? '0px' : '20px', textAlign: 'center'}">
                          <el-col :span="6" v-for="node in getCols(ontology.nodes, row - 1)" :key="node.id" :style="{textAlign: 'center'}">
                            <div class="cc-type-selector" name="type-show" :style="{'pointer-events':'all', height: '40px'}">
                              <input type="radio" v-model="addPick" :id="node.name + '-type'" :value="node"/>
                              <el-tooltip :open-delay="400" :content="node.label" placement="bottom" effect="light">
                                <label class="typecard-cc" v-if="node.icon.unicode.length > 0" :for="node.name + '-type'">
                                  <i :class="'ic-type ' + node.icon.name" :style="{color: node.color}"></i>
                                </label>
                                <label v-else class="typecard-cc operator-label" :style="{textAlign: 'center'}" :for="node.name + '-type'">{{cutString(node.name, 4)}}</label>
                              </el-tooltip>
                            </div>
                          </el-col>
                        </el-row>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from 'axios'
import PathMaker from './PathMaker'
import SchemeController from "../../controllers/scheme.controller";
import Utils from "../../services/utils";
import basic from "../../store/basic";
import OntologyController from "../../controllers/ontology.controller";


export default {
  name: 'constructor-v-graph',
  props: {
    data: {}
  },
  components: {
    PathMaker
  },
  data () {
    return {
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ),
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 48,
      openScheme: true,
      addTypes: [],
      presentSchemes: [],
      selectSchemeToLoad: {},
      editQuery: false,
      addPick: {},
      linkPropertiesScheme: {
        'transaction': {
           date0: {
            type: 'String',
            isList: false
          },
             date1: {
            type: 'String',
            isList: false
          },
             date2: {
            type: 'String',
            isList: false
          },
             smthn1: {
            type: 'String',
            isList: false
          },
           value: {
            type: 'String',
            isList: false
          }
        }

      },
      nodePropertiesScheme: {
        'bank': {
          bank_name: {
            type: 'String',
            isList: false
          },
          smthn1: {
            type: 'String',
            isList: false
          },
          'keys=299': {
            type: 'String',
            isList: false
          }
        }
      },
      ontology: null,
      selectedProperties: [],
      instruments: [true, false, false, false],
      formConstructor: {
        ID: '',
        nodeType:  {type: 'node', label: 'Bank', name: 'Bank', icon: '', short: 'b', color: '#278bf3'},
        nodePropertySelect: {},
        name: ''
      },
      completedSchemeForm: {
        id: '',
        name: ''
      },
      rulesSchemeForm: {
        name: [
          {required: true, message: this.$t('Введите имя схемы'), trigger: 'change'}
        ]
      },
      cypherQuery: [],
      subgraphQuery:[],
      queryFirst: '',
      constructorItems: [],
      editableElement: {},
      editableElementIndex: 0,
      isID: false,
      isLoadingConstructor: false,
      loadedSchemeID: -1,
      nodesCount: [],
      graphParts: {
        sum: [],
        negative: []
      },
      dialogOpenVisible: false,
      dialogSaveVisible: false,
      dialogSaveAsVisible: false,
      dialogDeleteVisible: false,
      rightWindowSize: 0,
      schemeController: null,
      ontologyController: null,
      basic: null
    }
  },
  watch: {
    addPick: function (newValue) {
      this.changeTypeNode(newValue)
    }
  },
  mounted () {
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    this.basic = basic
    this.schemeController = new SchemeController('constructor')
    // this.resetConstructor()
    this.createStartNodes(this.data.selectedNodes)
  },
  methods: {
    restartMaker () {
      this.$emit('v-graph-element', this.$refs.constructor.graph)
      this.updateScheme()
    },
    updateScheme(){
      let scheme = this.schemeController.newScheme(this.$refs.constructor.graph.links, this.$refs.constructor.graph.nodes,
              this.$refs.constructor.graph.attributes, this.$refs.constructor.graph.values,
              this.$refs.constructor.graph.operators, this.$refs.constructor.graph.logics)
      scheme.getGraphParts()
      this.graphParts = scheme.graphParts
    },
    selectElement(obj) {
      if (this.instruments[3] && Object.keys(this.editableElement).length === 0) {
        this.$refs.constructor.width = this.$refs.constructor.width * 3 / 4 + 4
        this.rightWindowSize = 6
      }
      if (this.instruments[3] && obj !== {}) {
        this.editableElement = obj
        this.loadElement(obj)
      }
    },
    loadOntology () {
      axios({
            method: 'get',
            url: "http://localhost:8081/getOntology",
        })
      //axios({
      //      method: 'get',
      //      url: "http://localhost:8081/getOntology",
      //  }).then(response => {
      //      // let data = response.data;
      //      // console.log(data)
      //  })
    },
    getRows (obj) {
      return Math.ceil((Object.keys(obj).length * 1.0) / 4)
    },
    getCols (obj, index) {
      let arrayCol = []
      for (let i = index * 4; i < (index + 1)*4; i++) {
        if (i < Object.keys(obj).length) {
          let element = Object.assign({}, obj[Object.keys(obj)[i]])
          element['name'] = Object.keys(obj)[i]
          arrayCol.push(element)
        }
      }
      return arrayCol
    },
    cutString(str, len) {
      return Utils.cutString(str, len)
    },
    schemeMenu (command) {
      if (command === 'new') {
        this.newScheme()
      } else if (command === 'open') {
        this.dialogOpenVisible = true
      } else if (command === 'save') {
        if (this.loadedSchemeID < 0) {
          this.dialogSaveVisible = true
        } else {
          this.preMakeQuery('saveScheme')
        }
      } else if (command === 'save as') {
        this.dialogSaveAsVisible = true
      } else if (command === 'delete') {
        this.dialogDeleteVisible = true
      }
    },
    inputBlur() {
      this.$refs.constructor.disableKeys = false
    },
    switchLinkType () {
      this.$refs.constructor.restart('delete', null)
    },
    createStartNodes (nodes) {
      if (nodes.first !== null && nodes.second !== null) {


        this.$refs.constructor.graph.nodes = []
        this.$refs.constructor.graph.links = []
        this.$refs.constructor.graph.attributes = []
        this.$refs.constructor.graph.operators = []
        this.$refs.constructor.graph.logics = []
        this.$refs.constructor.graph.values = []
        this.$refs.constructor.graph.functions = []
        let firstNode = {
          element: 'ontology',
          vGraphID: 1,
          name: nodes.first.name,
          x: Math.random()*100 + 150,
          y: Math.random()*100 + 150,
          dx: 0,
          dy: 0,
          part: null,
          short: 'first',
          color: this.ontology.nodes[nodes.first.name].color,
        }
        this.$refs.constructor.graph.nodes.push(firstNode)

        let secondNode = {
          element: 'ontology',
          vGraphID: 1,
          name: nodes.second.name,
          x: Math.random()*100 + 500,
          y: Math.random()*100 + 170,
          dx: 0,
          dy: 0,
          part: null,
          short: 'second',
          color: this.ontology.nodes[nodes.second.name].color,
        }

        this.$refs.constructor.graph.nodes.push(secondNode)

        this.$refs.constructor.instrument = 'cursor'
        this.$refs.constructor.nodesCounter += 2
        this.$refs.constructor.restart('delete', null)
        this.$refs.constructor.init()
        this.$refs.constructor.restart('add', null)

      }
    },
    resetConstructor () {
      this.$refs.constructor.nodesCounter = 0
      this.$refs.constructor.linksCounter = 0
      this.$refs.constructor.graph.nodes = []
      this.$refs.constructor.graph.links = []
      this.editQuery = false
      this.selectSchemeToLoad = {}
      this.instruments = [true, false, false, false]
      this.formConstructor = {
        nodeType: {name: 'Область', value: 0},
        nodePropertySelect: {},
        name: ''
      }
      this.completedSchemeForm = {
        id: '',
        name: ''
      }
      this.cypherQuery = []
      this.editableElement = {}
      this.graphParts = {}
      this.constructorItems = []
      this.editableElementIndex = 0
      this.openScheme = false
      this.nodesCount = []
    },
    compareNodes (n1, n2) {
      if (n1.element === n2.element && n1.index === n2.index) return true
      else return false
    },

    loadElement (newValue) {
      if (newValue.type === 'node') {
        let node = newValue.element
        this.formConstructor.name = node.name
        if (node.id[0]) this.formConstructor.ID = node.id[1]
        else this.formConstructor.ID = ''
      } else if (newValue.type === 'link') {
        let link = newValue.element
        this.formConstructor.name = link.name
      }
    },

    changeInstrument (instrument) {
      let num = 0
      this.isID = false
      if (instrument === 'add') {
        this.selectedProperties = []
        this.nodeProperties = []
        this.changeTypeNode(this.addPick)
        this.$refs.constructor.width = this.$refs.constructor.width * 3 / 4 + 4
        this.rightWindowSize = 6
        num = 1
      } else if (instrument === 'line') {
        this.$refs.constructor.width = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        )
        this.rightWindowSize = 0
        num = 2
      } else if (instrument === 'edit') {
        num = 3
        if (Object.keys(this.editableElement).length !== 0) {
          this.loadElement(this.editableElement)
          this.$refs.constructor.width = this.$refs.constructor.width * 3 / 4 + 4
          this.rightWindowSize = 6
        }
      } else {
        this.$refs.constructor.width = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        )
        this.rightWindowSize = 0
      }
      let array = [false, false, false, false]
      array[num] = true
      this.instruments = array
    },
    changeTypeNode (value) {
      this.formConstructor = {
        ID: '',
        nodeType: value,
        nodePropertySelect: {},
        name: ''
      }
    }

  }
}
</script>

<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }


  a {
    color: #42b983;
  }

  .card-header {
    font-size: 20px;
    font-weight: bold;
  }

  .bg-purple-dark {
    background: #99a9bf;
  }

  .bg-purple {
    background: #d3dce6;
  }

  .bg-purple-light {
    background: #e5e9f2;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .el-header {
    text-align: center;
  }

  .el-autocomplete-my {
    box-sizing:content-box;
    width:100%;
  }
  .ic-type {
    width: 42px;
    height: 42px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0px;
    padding-top: 0;
  }
  .right-window {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .18), 0 0 6px rgba(0, 0, 0, .07);
    height:100%;
  }

  .cc-selector input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .cc-selector input:active + .drinkcard-cc {
    opacity: .9;
  }

  .cc-selector input:checked + .drinkcard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .cc-type-selector input:active + .typecard-cc {
    opacity: 1;
  }

  .cc-type-selector input:checked + .typecard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .typecard-cc {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: brightness(1.2) grayscale(.6) opacity(.6);
    -moz-filter: brightness(1.2) grayscale(.6) opacity(.6);
    filter: brightness(1.2) grayscale(.6) opacity(.6);
  }

  .typecard-cc:hover {
    -webkit-filter: brightness(1.0) grayscale(.1) opacity(1);
    -moz-filter: brightness(1.0) grayscale(.1) opacity(1);
    filter: brightness(1.0) grayscale(.1) opacity(1);
  }
  input[type="radio"]{
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .operator-label {
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    margin-right: 0px;
    background-color: #21cb7a;
    color: white;
    width: 60px;
    padding: 4px 4px;
    border-radius: 3px;
  }
</style>
