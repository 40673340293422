<template>
  <div
    id="scheme-maker"
    :style="{  position: 'relative', left:0, right: 0, width: (width) + 'px', height: (height-2) + 'px'}"
    oncontextmenu="return false;"
  >
    <el-dialog
            :title="$t('Таких связей несколько')"
            v-model="avaliableLinks.dialog"
            width="30%">

      <el-select v-model="avaliableLinks.selected"
                 value-key="type"
                 v-if="avaliableLinks.dialog"
                 :no-match-text="$t('Ничего не найдено')"
                 :no-data-text="$t('Нет данных')"
                 :placeholder="$t('Выберите связь')">
        <el-option
                style="width: 90%;"
                :label="$t('Пустой')"
                :value="{type: ''}">
        </el-option>
        <el-option
                style="width: 90%;"
                v-for="item in avaliableLinks.data"
                :key="item.type"
                :label="item.type"
                :value="item">
        </el-option>
      </el-select>
      <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="avaliableLinks.dialog = false">{{ $t('Отмена') }}</el-button>
                      <el-button type="primary" @click="createLink">{{ $t('Выбрать') }}</el-button>
                    </span>
      </template>
    </el-dialog>

    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
         :style="{  position: 'absolute', left:0, right: 0, width: (width-2) + 'px', height: (height-2) + 'px'}">
      <g height="100%" width="100%" id="gElement">
        <rect :x="-10000" :y="-10000" height="20000" width="20000" style="fill: white"></rect>
        <foreignObject :style="menu" id="contextMenu">
          <div :style="menu" id="contextMenu">
            <div @click="menuAddAttribute">
              <el-dropdown-item command="a">{{ $t('Добавить атрибут') }}
              </el-dropdown-item>
            </div>
          </div>
        </foreignObject>

        <foreignObject :style="{'display': displayEditElement('value', 'Number', false) || displayEditElement('value', 'String', false) && !editableValue.list.isList ? 'block' : 'none', height: 40 + 'px', width: (120 + additionalValueWidth)  + 'px'}" id="valueEditObject" ref="valueEditObject" >
          <el-input :style="{'background-color':'#f0f0f0',height: 40 + 'px', width: (120 + additionalValueWidth)  + 'px', 'z-index':100000}" v-model="inputValue" @input = "valueInput($event)" @change = "valueChange($event)" id="valueEdit" ref="valueEdit" />
        </foreignObject>
        <foreignObject  :style="{'display': displayEditElement('value', 'Boolean', false) ? 'block' : 'none', height: 40 + 'px', width: (120)  + 'px'}" id="valueBoolEditObject" ref="valueBoolEditObject" >
          <el-radio-group
                  :style="{height: 40 + 'px', width: (120)  + 'px'}"
                  size="small"
                  @change="valueBoolChange($event)"
                  v-model="inputValue">
            <el-radio-button label="True"></el-radio-button>
            <el-radio-button label="False"></el-radio-button>
          </el-radio-group>
        </foreignObject>

        <foreignObject  :style="{'display': displayEditElement('value', 'Date', false) ? 'block' : 'none', height: 40 + 'px', width: (150)  + 'px'}" id="valueDateEditObject" ref="valueDateEditObject" >
          <el-input
                  v-model="inputValue"
                  id = "valueDateEdit"
                  size="small"
                  type = "date"
                  @change = "valueDateChange($event)">
          </el-input>
        </foreignObject>

        <foreignObject  :style="{'display': displayEditElement('value', 'String', true) ? 'block' : 'none', height: 500 + 'px', width: (200)  + 'px'}" id="valueListEditObject" ref="valueListEditObject" >
          <el-select v-model="inputValue"
                     @change="valueInput"
                     :no-match-text="$t('Ничего не найдено')"
                     :no-data-text="$t('Нет данных')"
                     id="valueListEdit"
                     style="margin-top: 250px; width: 200px;"
                     :placeholder="$t('Выберите значение')">
            <el-option
                    style="width: 90%;"
                    v-for="item in valueList"
                    :key="item"
                    :label="item"
                    :value="item">
            </el-option>
          </el-select>
        </foreignObject>

        <foreignObject  :style="{'display': displayEditElement('attribute', 'String', false) || displayEditElement('attribute-display-name', 'String', false) ? 'block' : 'none',
      height: 500 + 'px', width: (200)  + 'px'}" id="attributeEditObject" ref="attributeEditObject" >
          <el-select v-model="propertySelect"
                     @change="addNodeProperty"
                     id="attributeEdit"
                     value-key="name"
                     :no-data-text="$t('Нет атрибутов')"
                     :no-match-text="$t('Ничего не найдено')"
                     width = "200px"
                     style="margin-top: 250px; width: 200px;"
                     :placeholder="$t('Выберите свойство')">
            <el-option
                    style="width: 90%;"
                    v-for="item in orderedProperties()"
                    :key="item.name"
                    :label="item.name"
                    :value="item">
            </el-option>
          </el-select>
        </foreignObject>


      </g>
    </svg>
    <div :style="{left:0+'px', position: 'absolute', 'z-index':500, 'pointer-events':'none'}" >
      <div class="mt-1 mb-3 cc-selector" name="radio-show"
                          :style="{'padding-left': '8px', 'padding-top': '8px'}">
        <el-row :style="{textAlign: 'left'}">
          <el-col>
            <a :style="{textAlign: 'left', 'pointer-events':'all'}" class="mr-2">
              <input id="instrument-cursor" type="radio" v-model="instrument" value="cursor"/>
              <label :style="{textAlign: 'center'}" class="drinkcard-cc ic" for="instrument-cursor">
                <i class="fas fa-mouse-pointer fa-lg icon-cog" ></i>
              </label>
            </a>
          </el-col>
        </el-row>
        <el-row :style="{textAlign: 'left', width: '350px'}">
          <el-col :span="3">
            <a :style="{textAlign: 'left', 'pointer-events':'all'}" class="mr-2">
              <input id="instrument-add" type="radio" v-model="instrument" value="add"/>
              <label :style="{textAlign: 'center'}" class="drinkcard-cc ic" for="instrument-add">
                <i class="fas fa-plus fa-lg icon-cog" ></i>
              </label>
            </a>
          </el-col>
        </el-row>
        <el-row :style="{textAlign: 'left'}">
          <el-col>
            <a :style="{textAlign: 'left', 'pointer-events':'all'}" class="mr-2">
              <input id="instrument-line" type="radio" v-model="instrument" value="line"/>
              <label :style="{textAlign: 'center'}" class="drinkcard-cc ic" for="instrument-line">
                <i class="fas fa-share-alt fa-lg icon-cog" ></i>
              </label>
            </a>
          </el-col>
        </el-row>
        <el-row :style="{textAlign: 'left'}">
          <el-col>
            <a :style="{textAlign: 'left', 'pointer-events':'all'}" class="mr-2">
              <input id="instrument-edit" type="radio" v-model="instrument" value="edit"/>
              <label :style="{textAlign: 'center'}" class="drinkcard-cc ic" for="instrument-edit">
                <i class="fas fa-edit fa-lg icon-cog" ></i>
              </label>
            </a>
          </el-col>
        </el-row>
        <el-row :style="{textAlign: 'left'}">
          <el-col>
            <a :style="{textAlign: 'left', 'pointer-events':'all'}" class="mr-2">
              <input id="instrument-navigation" type="radio" v-model="instrument" value="navigation"/>
              <label :style="{textAlign: 'center'}" class="drinkcard-cc ic" for="instrument-navigation">
                <i class="fas fa-compass fa-lg icon-cog" ></i>
              </label>
            </a>
          </el-col>
        </el-row>
      </div>
    </div>


  </div>
</template>

<script>
import axios from "axios";
const ScienceAPI = `http://185.141.127.69:8083/api`
import D3Controller from "../../controllers/d3.controller";
import Utils from "../../services/utils";
import OntologyController from "../../controllers/ontology.controller";


export default {
  name: 'path-maker',
  props: {
    data: {}
  },
  data () {
    return {
      graph: {
        nodes: [],
        attributes: [],
        links: [],
        values: [],
        operators: [],
        logics: [],
        functions: []
      },
      avaliableLinks: {
        data: [],
        selected: {},
        dialog: false
      },
      menu: {
        backgroundColor: 'white',
        display: 'none',
        height: 40 + 'px',
        width: (200) + 'px',
        'z-index': 1000000
      },
      forceProperties: {
        center: {
          x: 0.5,
          y: 0.5
        },
        charge: {
          enabled: true,
          strength: -200,
          distanceMin: 100,
          distanceMax: 2000
        },
        collide: {
          enabled: true,
          strength: 0.1,
          iterations: 1,
          radius: 35
        },
        forceX: {
          enabled: true,
          strength: 0.05,
          x: 0.5
        },
        forceY: {
          enabled: true,
          strength: 0.05,
          y: 0.5
        },
        link: {
          enabled: true,
          distance: 100,
          iterations: 1
        }
      },
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ),
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 56 - 72 - 4,
      selections: {},
      countEdges: 0,
      typeNode: 0,
      nodesCounter: 0,
      linksCounter: 0,
      isLoading: false,
      instrument: 'cursor',
      simulation: null,
      drag: null,
      dragged_line: null,
      selected_node: null,
      selected_link: null,
      mousedown_link: null,
      mousedown_node: null,
      mouseup_node: null,
      drag_line: null,
      disableKeys: false,
      editValue: false,
      editableValue: null,
      editableText: null,
      inputValue: '',
      additionalValueWidth: 0,
      propertySelect: '',
      properties: [],
      valueList: [],
      controller: null,
      ontologyController: null
    }
  },
  computed: {
    nodes () {
      return this.graph.nodes
    },
    links () {
      return this.graph.links
    },
    getIcon(path) {
      return `url(${require('../../assets/' +  path )})`
    }
  },
  mounted: function () {
    this.controller = new D3Controller(this.$el.querySelector('svg'), this.width, this.height)
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    this.bindMouseActions()
    this.bindKeyActions()
    // this.controller.bindZoom([1 / 4, 4])
    this.init()
  },
  watch: {
    instrument: function (newValue) {
      if (newValue === 'cursor') {
        this.unbindZoom()
        this.controller.canBeSelected = true
        this.controller.canBeEdited = true
        this.controller.enableDrag()
        this.$emit('instrument', 'cursor')
      } else if (newValue === 'add') {
        this.$emit('node-type', this.ontology.nodes[this.typeNode])
        this.unbindZoom()
        this.controller.enableDrag()
        this.controller.canBeSelected = false
        this.controller.canBeEdited = false
        this.$emit('instrument', 'add')
      } else if (newValue === 'line') {
        this.unbindZoom()
        this.controller.disableDrag()
        this.controller.canBeSelected = true
        this.controller.canBeEdited = false
        this.$emit('instrument', 'line')
      } else if (newValue === 'edit') {
        this.unbindZoom()
        this.controller.canBeSelected = true
        this.controller.canBeEdited = true
        this.$emit('instrument', 'edit')
      } else if (newValue === 'navigation') {
        this.controller.bindZoom([1 / 4, 4])
        this.controller.disableDrag()
        this.controller.canBeSelected = false
        this.controller.canBeEdited = false
        this.$emit('instrument', 'navigation')
      }
    },
    typeNode: function (newValue) {
      this.$emit('node-type', this.ontology.nodes[newValue])
    }
  },
  methods: {
    unbindZoom() {
      this.controller.unbindZoom()
    },
    bindMouseActions() {

      const vm = this

      function svgDoubleClick () {
        vm.resetMouseVars()
      }
      function svgMouseDown () {
        if (vm.controller.event().button === 0) {
          if (vm.instrument === 'add') {
            let xy0 = vm.controller.mouse(this)
            // console.log(vm.data.formConstructor.nodeType.element, vm.data.formConstructor)
            if (vm.data.formConstructor.nodeType.element === 'ontology') {
              let name = vm.data.formConstructor.nodeType.name
              let newNode = {
                element: 'ontology',
                name: name,
                x: xy0[0],
                y: xy0[1],
                dx: 0,
                dy: 0,
                part: null,
                short: vm.ontology.nodes[name].short + vm.nodesCounter,
                color: vm.ontology.nodes[name].color,
                form: {},
                id: [false, -1]
              }
              vm.nodesCounter++
              vm.graph.nodes.push(newNode)
              vm.restart('add', newNode)
            } else if (vm.data.formConstructor.nodeType.element === 'value') {
              let additionalWidth = 0
              if (vm.data.formConstructor.nodeType.id === 3) additionalWidth = 10
              let newValue = {
                element: 'value',
                name: vm.data.formConstructor.nodeType.name,
                part: null,
                x: xy0[0],
                y: xy0[1],
                list: {
                  isList: false,
                  listName: ''
                },
                additionalWidth: additionalWidth,
                value: '',
                dx: 0,
                dy: 0,
                color: '#000000',
                form: {},
              }
              vm.nodesCounter++
              vm.graph.values.push(newValue)
              vm.restart('add', newValue)
            } else if (vm.data.formConstructor.nodeType.element === 'operator') {
              let newOperator = {
                element: 'operator',
                name: vm.data.formConstructor.nodeType.name,
                x: xy0[0],
                part: null,
                additionalWidth: 0,
                y: xy0[1],
                dx: 0,
                dy: 0,
                color: '#000000',
                form: {},
              }
              vm.nodesCounter++
              vm.graph.operators.push(newOperator)
              vm.restart('add', newOperator)
            } else if (vm.data.formConstructor.nodeType.element === 'logic') {
              let newLogic = {
                element: 'logic',
                name: vm.data.formConstructor.nodeType.name,
                x: xy0[0],
                part: null,
                additionalWidth: 0,
                y: xy0[1],
                dx: 0,
                dy: 0,
                color: '#000000',
                form: {},
              }
              vm.nodesCounter++
              vm.graph.logics.push(newLogic)
              vm.restart('add', newLogic)
            }
          } else if (vm.instrument === 'line') {
            if (!vm.controller.mousedown_node && !vm.controller.mousedown_link) {
              vm.controller.selected_node = null
              vm.controller.selected_link = null
              // vm.restart('')
              return
            }
            if (vm.controller.mousedown_node) {
              // reposition drag line
              vm.controller.drag_line
                      .attr('class', 'link')
                      .attr('style', 'stroke: #999;stroke-width: 5;')
                      .attr('x1', vm.controller.mousedown_node.x + vm.controller.mousedown_node.dx)
                      .attr('y1', vm.controller.mousedown_node.y + vm.controller.mousedown_node.dy)
                      .attr('x2', vm.controller.mousedown_node.x + vm.controller.mousedown_node.dx)
                      .attr('y2', vm.controller.mousedown_node.y + vm.controller.mousedown_node.dy)
            }
            // vm.restart()
          }
        }

      }
      function svgMouseUp () {
        if (vm.controller.event().button === 0) {
          if (vm.instrument === 'add') {
            //
          } else if (vm.instrument === 'line') {
            vm.controller.drag_line
                    .attr('class', 'drag_line_hidden')
                    .attr('style', 'stroke: #999;stroke-width: 0;pointer-events: none;')
            if (vm.controller.mouseup_node === vm.controller.mousedown_node) {
              vm.resetMouseVars()
              return
            }
            if (vm.controller.mouseup_node) {
              let linkAlreadyExist = false
              let linkData = vm.controller.graphSelection.selectAll('.link').data()
              let counterOfSameLink = 0
              for (let l of linkData) {
                if (l.target === vm.controller.mouseup_node && l.source === vm.controller.mousedown_node) counterOfSameLink++
                else if (l.source === vm.controller.mouseup_node && l.target === vm.controller.mousedown_node) counterOfSameLink++
              }
              if (vm.controller.mousedown_node.element !== 'ontology' || vm.controller.mouseup_node.element !== 'ontology') {
                if (counterOfSameLink > 0) linkAlreadyExist = true
              }
              if (!linkAlreadyExist) {
                vm.avaliableLinks.selected = {}
                let avaliableLinks = vm.data.schemeController.getTypeLink(vm.controller.mousedown_node,
                        vm.controller.mouseup_node)
                if (avaliableLinks !== null) {
                  vm.controller.mousedown_node = avaliableLinks.source
                  vm.controller.mouseup_node = avaliableLinks.target
                  vm.avaliableLinks.data = avaliableLinks.links
                  if (avaliableLinks.libType === 'basic' || avaliableLinks.links.length === 1) {
                    vm.avaliableLinks.selected =  avaliableLinks.links[0]
                    vm.createLink()
                  } else {
                    vm.avaliableLinks.dialog = true
                  }
                }
              } else {
                vm.resetMouseVars()
                return
              }
            } else {
              vm.resetMouseVars()
              return
            }
            // clear mouse event vars
          }
        }
      }
      function svgMouseMove () {
        if (vm.controller.event().button === 0) {
          if (vm.instrument === 'add') {
            //
          } else if (vm.instrument === 'line') {
            if (!vm.controller.mousedown_node) return

            // update drag line
            vm.controller.drag_line
                    .attr('x1', vm.controller.mousedown_node.x + +vm.controller.mousedown_node.dx)
                    .attr('y1', vm.controller.mousedown_node.y + +vm.controller.mousedown_node.dy)
                    .attr('x2', vm.controller.mouse(this)[0])
                    .attr('y2', vm.controller.mouse(this)[1])
          }
        }
      }

      this.controller.bindActions(svgDoubleClick, svgMouseDown, svgMouseUp, svgMouseMove)
    },
    orderedProperties () {
      const vm = this
      return vm.properties.sort(function (a, b) {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    bindKeyActions(){
      const vm = this
      function keyup(){
        vm.editValue = false
      }
      function keydown() {
        if (vm.disableKeys) return
        switch (vm.controller.event().keyCode) {
          case 46: { // delete
            if (vm.controller.selected_node) {
              // console.log(vm.graph.nodes.indexOf(vm.controller.selected_node))
              if (vm.controller.selected_node.element === 'ontology') {
                vm.graph.nodes.splice(vm.graph.nodes.indexOf(vm.controller.selected_node), 1)
              } else if (vm.controller.selected_node.element === 'value') {
                vm.graph.values.splice(vm.graph.values.indexOf(vm.controller.selected_node), 1)
              } else if (vm.controller.selected_node.element === 'operator') {
                vm.graph.operators.splice(vm.graph.operators.indexOf(vm.controller.selected_node), 1)
              } else if (vm.controller.selected_node.element === 'logic') {
                vm.graph.logics.splice(vm.graph.logics.indexOf(vm.controller.selected_node), 1)
              } else if (vm.controller.selected_node.element === 'attribute') {
                vm.graph.attributes.splice(vm.graph.attributes.indexOf(vm.controller.selected_node), 1)
              } else if (vm.controller.selected_node.element === 'function') {
                vm.graph.functions.splice(vm.graph.functions.indexOf(vm.controller.selected_node), 1)
              }
              vm.spliceLinksForNode(vm.controller.selected_node)
              vm.$emit('select-element', {})
            } else if (vm.controller.selected_link) {
              let counter = -1
              let linkToDelete = []
              for (let link of vm.graph.links) {
                if ((vm.compareNodes(link.source, vm.controller.selected_link.source) && vm.compareNodes(link.target, vm.controller.selected_link.target)) ||
                        (vm.compareNodes(link.source, vm.controller.selected_link.target) && vm.compareNodes(link.target, vm.controller.selected_link.source))) {
                  if (link !== vm.controller.selected_link) {
                    counter++
                  }
                }
                if (link.source.element === vm.controller.selected_link.element && link.source.name === vm.controller.selected_link.name) {
                  linkToDelete.push(link)
                }
              }
              for (let link of linkToDelete) {
                vm.spliceLinksForNode(link.target)
                vm.graph.attributes.splice(vm.graph.attributes.indexOf(link.target), 1)
              }
              for (let link of vm.graph.links) {
                if ((vm.compareNodes(link.source, vm.controller.selected_link.source) && vm.compareNodes(link.target, vm.controller.selected_link.target)) ||
                        (vm.compareNodes(link.source, vm.controller.selected_link.target) && vm.compareNodes(link.target, vm.controller.selected_link.source))) {
                  if (link !== vm.controller.selected_link) {
                    if (link.sameLink.current > vm.controller.selected_link.sameLink.current) link.sameLink = {
                      current: link.sameLink.current - 1,
                      all: counter
                    }
                    else link.sameLink = {current: link.sameLink.current, all: counter}
                  }
                }
              }
              vm.graph.links.splice(vm.graph.links.indexOf(vm.controller.selected_link), 1)
            }
            vm.controller.selected_link = null
            vm.controller.selected_node = null
            vm.restart('delete', null)
            break
          }
          case 69: {
            vm.editValue = true
            break
          }

        }
      }

      this.controller.bindKeyDown(keydown)
      this.controller.bindKeyUp(keyup)
    },
    init() {
      this.controller.bindSimulation(this.forceProperties)
      this.controller.enableDrag()
    },
    menuAddAttribute() {
      if (this.controller.selected_node !== null) {
        const selected_node = this.controller.selected_node
        let xy0 = this.computeFreeCoords(selected_node.x, selected_node.y)

        let newAttribute = {
          element: 'attribute',
          attribute: null,
          parent: selected_node,
          name: '',
          x: xy0[0],
          part: null,
          y: xy0[1],
          additionalWidth: 0,
          dx: -86 / 2,
          dy: 0,
          color: '#000000',
          form: {},
        }

        this.nodesCounter++
        this.graph.attributes.push(newAttribute)


        let link = {
          element: 'link',
          name: 'toAttribute',
          short: 'r' + this.linksCounter,
          source: selected_node,
          target: newAttribute,
          sameLink: {current: 0, all: 0},
          sourceD: {dx: 0, dy: 0},
          targetD: {dx: newAttribute.dx, dy: newAttribute.dy},
          sourcePart: null,
          targetPart: 'in',
          directed: true,
          label: 'toAttribute' + ' ' + this.linksCounter,
          linkType: 'common',
          form: {}
        }
        this.linksCounter++
        this.graph.links.push(link)
        this.resetMouseVars()
        this.restart('add', null)


      } else if (this.controller.selected_link !== null) {
        const selected_link = this.controller.selected_link
        let xy0 = this.computeFreeCoords((selected_link.source.x + selected_link.sourceD.dx + selected_link.target.x + selected_link.target.dx) / 2,
                (selected_link.source.y + selected_link.sourceD.dy + selected_link.target.y + selected_link.target.dy) / 2)
        let newAttribute = {
          element: 'attribute',
          attribute: null,
          name: '',
          parent: selected_link,
          x: xy0[0],
          part: null,
          y: xy0[1],
          additionalWidth: 0,
          dx: -86 / 2,
          dy: 0,
          color: '#000000',
          form: {},
        }
        this.nodesCounter++
        this.graph.attributes.push(newAttribute)

        let link = {
          element: 'link-attribute',
          name: 'toAttribute',
          short: 'r' + this.linksCounter,
          source: selected_link,
          sameLink: {current: 0, all: 0},
          target: newAttribute,
          sourceD: {dx: 0, dy: 0},
          targetD: {dx: newAttribute.dx, dy: newAttribute.dy},
          sourcePart: null,
          targetPart: 'in',
          directed: true,
          label: 'toAttribute' + ' ' + this.linksCounter,
          linkType: 'common',
          form: {}
        }
        this.linksCounter++
        this.graph.links.push(link)
        this.resetMouseVars()
        this.restart('delete', null)
      }
    },
    displayEditElement(element, type, list) {
      // console.log(element, type, list, this.editableValue)
      if (this.editableValue !== null) {
        if ((element === 'attribute' || element === 'attribute-display-name')&& this.editableValue.element === element) {
          return true
        } else if (element === 'value' && this.editableValue.element === element) {
          if (this.editableValue.list.isList && list) {
            return true
          } else if (!list && this.editableValue.name === type) {
            return true
          }
        }
      }
      return false
    },
    addNodeProperty(e) {
      this.editableValue.attribute = e
      this.editableValue.name = e.type
      this.controller.graphSelection.selectAll('.attribute').selectAll('.text-value').text(d => {
        d.name = 'attr' + e.type
        if (d.attribute !== null) return d.attribute.name
        else return ''
      })
      this.controller.graphSelection.selectAll('.attribute').selectAll('.data-type-label').attr('style', d => {
        if (d.attribute !== null) return 'display: block;'
        else return 'display: none;'
      })
      this.controller.graphSelection.selectAll('.attribute').selectAll('.data-type-text').text(d => {
        if (d.attribute !== null) return d.attribute.type
        else return ''
      })
      this.propertySelect = {}
      this.setWidthAttribute(this.editableText._groups[0][0].getComputedTextLength())
      this.resetMouseVars()
      this.dataTypeViolationTest()
    },
    valueChange () {
      // console.log(e)
    },
    valueBoolChange () {
      this.setTextValue()
    },
    valueDateChange () {
      this.setTextValue()
    },
    setTextValue() {
      const vm = this
      this.controller.graphSelection.selectAll('.value').selectAll('.text-value').text(d => {
        if (d === vm.editableValue) {
          d.value = vm.inputValue
          return d.value
        } else {
          return d.value
        }
      })
    },
    resetTextValue() {
      this.controller.graphSelection.selectAll('.value').selectAll('.text-value').text(d => {
        return d.value
      })
    },
    getAdditionalWidth (text, start, boundaries, max) {
      if (text > start - boundaries && text < max - boundaries) {
        return (text - (start - boundaries))
      } else if (text < start - boundaries){
        return 0
      } else {
        return max - start
      }
    },
    setWidthAttribute(w) {
      const vm = this
      let startW = 92
      this.editableValue.additionalWidth = this.getAdditionalWidth(w, startW, 44, 300)
      this.controller.graphSelection.selectAll('.attribute').selectAll('.polygon-body').attr('points', function (d) {
        return '0,-26 ' + (startW + d.additionalWidth) / 2 + ',0 0,26 -' + (startW + d.additionalWidth) / 2 + ',0'
      })
      this.controller.graphSelection.selectAll('.attribute').selectAll('.in-node').attr('transform', function (d) {
        return 'translate(' + (-(startW + d.additionalWidth - 6) / 2) + ')'
      })
      this.controller.graphSelection.selectAll('.attribute').selectAll('.out-node').attr('transform', function (d) {
        return 'translate(' + ((startW + d.additionalWidth - 6) / 2) + ')'
      })
      for (let link of vm.links) {
        if (link.source.index === vm.editableValue.index && link.source.element === vm.editableValue.element && link.sourcePart === 'in') {
          link.sourceD.dx = -(startW + vm.editableValue.additionalWidth - 6) / 2
        } else if (link.target.index === vm.editableValue.index && link.target.element === vm.editableValue.element && link.targetPart === 'in') {
          link.targetD.dx = -(startW + vm.editableValue.additionalWidth - 6) / 2
        }
        if (link.source.index === vm.editableValue.index && link.source.element === vm.editableValue.element && link.sourcePart === 'out') {
          link.sourceD.dx = (startW + vm.editableValue.additionalWidth - 6) / 2
        } else if (link.target.index === vm.editableValue.index && link.target.element === vm.editableValue.element && link.targetPart === 'out') {
          link.targetD.dx = (startW + vm.editableValue.additionalWidth - 6) / 2
        }
      }
      this.controller.tick()
    },
    setWidthValue(w) {
      const vm = this
      let startW = 80
      this.additionalValueWidth = this.getAdditionalWidth(w, startW, 30, 300)
      this.editableValue.additionalWidth = this.additionalValueWidth
      this.controller.graphSelection.selectAll('.value').selectAll('rect').attr('width', function (d) {
        return startW + d.additionalWidth
      }).attr('x', function (d) {
        return -(startW + d.additionalWidth) / 2
      })
      this.controller.graphSelection.selectAll('.value').selectAll('.in-node').attr('transform', function (d) {
        return 'translate(' + (-(startW + d.additionalWidth) / 2) + ')'
      })
      this.controller.graphSelection.select('#valueEditObject').attr('x', this.editableValue.x - (120 + this.additionalValueWidth) / 2)
      for (let link of vm.links) {
        if (link.source === vm.editableValue) {
          link.sourceD.dx = -(startW + vm.additionalValueWidth) / 2
        } else if (link.target === vm.editableValue) {
          link.targetD.dx = -(startW + vm.additionalValueWidth) / 2
        }
      }
      this.controller.tick()
    },
    valueInput(e) {
      const vm = this
      // console.log(vm.editableValue.name, Utils.testNumber(e))
      if (vm.editableValue.name === 'Number') {
        if (Utils.testNumber(e) !== null) {
          this.inputValue = e
          this.setTextValue()
          vm.setWidthValue(vm.editableText._groups[0][0].getComputedTextLength())
        } else {
          this.inputValue = this.inputValue.slice(0, this.inputValue.length - 1)
        }
      } else {
        this.inputValue = e
        this.setTextValue()
        vm.setWidthValue(vm.editableText._groups[0][0].getComputedTextLength())
      }
    },
    computeFreeCoords (x, y) {
      let flag = true
      let x0
      let y0
      let k = 1
      while (flag) {
        flag = false
        x0 = x + (Math.random() - 0.5)*150*k
        y0 = y + (Math.random() - 0.5)*150*k
        if (x < 0 || x > this.width) flag = true
        if (y < 0 || y > this.height) flag = true
        let allNodes = (((this.graph.nodes.concat(this.graph.values)).concat(this.graph.operators)).concat(this.graph.logics)).concat(this.graph.attributes)
        for (let node of allNodes) {
          if (x0 > node.x - 100 && x0 < node.x + 100 && y0 > node.y - 100 && y0 < node.y + 100) {
            flag = true
            break
          }
        }
        k = k + 0.01
      }
      return [x0, y0]
    },
    getNeighbours (node, links) {
      let array = []
      for (let link of links) {
        if (link.target === node) {
          array.push(link.source)
        } else if (link.source === node) {
          array.push(link.target)
        }
      }
      return array
    },
    dataTypeViolationTest() {
      for (let attr of this.graph.attributes) {
        let paths = [[attr]]
        let doneNodes = [attr]
        let allValues = []
        let flag = true
        while (flag) {
          let oldPaths = paths.slice()
          flag = false
          for (let i = 0; i < oldPaths.length; i++) {
            let last = oldPaths[i][oldPaths[i].length - 1]
            let neighbours = this.getNeighbours(last, this.graph.links)

            if (neighbours.length === 2) {
              for (let n of neighbours) {
                if (n !== oldPaths[i][oldPaths[i].length - 2]) {
                  if (!doneNodes.includes(n) && n.element !== 'logic') {
                    paths[i].push(n)
                    doneNodes.push(n)
                    if (n.element === 'value') allValues.push(n)
                    flag = true
                  }
                }
              }
            } else if (neighbours.length > 2) {
              let continuePath = true
              let copyPath = paths[i].slice()
              for (let n of neighbours) {
                if (n !== oldPaths[i][oldPaths[i].length - 2] && continuePath) {
                  if (!doneNodes.includes(n) && n.element !== 'logic') {
                    doneNodes.push(n)
                    if (n.element === 'value') allValues.push(n)
                    paths[i].push(n)
                    continuePath = false
                    flag = true
                  }
                } else if (n !== oldPaths[i][oldPaths[i].length - 2] && !continuePath) {
                  if (!doneNodes.includes(n) && n.element !== 'logic') {
                    doneNodes.push(n)
                    if (n.element === 'value') allValues.push(n)
                    let copyPath2 = copyPath.slice()
                    copyPath2.push(n)
                    paths.push(copyPath2)
                    flag = true
                  }
                }
              }
            }
          }
        }
        let deleteLink = false
        for (let value of allValues) {
          if (attr.attribute !== null) {
            if (value.name !== attr.attribute.type) {
              deleteLink = true
              break
            }
          }
        }
        if (deleteLink) {
          for (let link of this.graph.links) {
            if (link.source.element !== 'ontology' && link.target.element !== 'ontology') {
              if (link.source.element === 'attribute' && link.source.index === attr.index)  this.graph.links.splice(this.graph.links.indexOf(link), 1)
              if (link.target.element === 'attribute' && link.target.index === attr.index)  this.graph.links.splice(this.graph.links.indexOf(link), 1)
            }
          }
          this.restart('delete', null)
        } else {
          for (let value of allValues) {
            if (attr.attribute !== null) {
              if (value.list.isList && !attr.attribute.isList) {
                this.graph.values[this.graph.values.indexOf(value)].value = ''
                this.selections.svg.selectAll('.value').selectAll('.text-value').text(d => {
                  return d.value

                })
                this.graph.values[this.graph.values.indexOf(value)].list = {
                  isList: false,
                  listName: ''
                }
              } else if (!value.list.isList && attr.attribute.isList) {
                this.graph.values[this.graph.values.indexOf(value)].value = ''
                this.selections.svg.selectAll('.value').selectAll('.text-value').text(d => {
                  return d.value

                })
                this.graph.values[this.graph.values.indexOf(value)].list = {
                  isList: true,
                  listName: attr.attribute.name
                }
              }
            }
          }
        }
      }
    },
    createLink() {
      this.avaliableLinks.dialog = false
      let counterOfSameLink = 0
      for (let l of this.graph.links) {
        if (l.target === this.controller.mouseup_node && l.source === this.controller.mousedown_node) counterOfSameLink++
        else if (l.source === this.controller.mouseup_node && l.target === this.controller.mousedown_node) counterOfSameLink++
      }
      let idLink = ''
      let linkSource = null
      let linkTarget = null
      let isLinked = false
      if (this.avaliableLinks.selected.type.length > 0) {
        let rule = this.data.schemeController.testRule(this.controller.mousedown_node,
                this.controller.mouseup_node, this.graph.links, this.avaliableLinks.selected)
        if (rule === null) isLinked = true
        if (rule !== null) {
          idLink = rule.link
          linkSource = rule.source
          linkTarget = rule.target
          let parts = []
          // console.log('vm.data.graphParts', this.data.graphParts)
          for (let j = 0; j < this.data.graphParts.sum.length; j++) {
            let part = this.data.graphParts.sum[j]
            if (part.includes(this.controller.mouseup_node.index) && part.includes(this.controller.mousedown_node.index)) {
              for (let k = 0; k < this.data.graphParts.negative.length; k++) {
                let part2 = this.data.graphParts.negative[j]
                if (part2.includes(this.controller.mouseup_node.index) && part2.includes(this.controller.mousedown_node.index)) {
                  break
                } else {
                  if (part2.includes(this.controller.mouseup_node.index) || part2.includes(this.controller.mousedown_node.index)) {
                    parts.push(part2)
                  }
                }
              }
              if (parts.length > 0) {
                if (part.includes(parts[0][0]) && part.includes(parts[1][0])) {
                  isLinked = true
                  break
                }
              }
            }
          }

        }
      } else {
        idLink = ''
        linkSource = this.controller.mousedown_node
        linkTarget = this.controller.mouseup_node
      }

      if (this.controller.mouseup_node.name === 'first' || this.controller.mouseup_node.name === 'second') {
        for (let l of this.graph.links) {
          if (l.target.type === this.controller.mouseup_node.type && l.target.index === this.controller.mouseup_node.index && l.source.element === 'ontology') {
            isLinked = true
          } else if (l.source.type === this.controller.mouseup_node.type && l.source.index === this.controller.mouseup_node.index && l.target.element === 'ontology') {
            isLinked = true
          }
        }
      } else {
        let count = 0
        for (let l of this.graph.links) {
          if (l.target.type === this.controller.mouseup_node.type && l.target.index === this.controller.mouseup_node.index && l.source.element === 'ontology') {
            count++
          } else if (l.source.type === this.controller.mouseup_node.type && l.source.index === this.controller.mouseup_node.index && l.target.element === 'ontology') {
            count++
          }
        }
        if (count >= 2) isLinked = true
      }

      if (this.controller.mousedown_node.name === 'first' || this.controller.mousedown_node.name === 'second') {
        for (let l of this.graph.links) {
          if (l.target.type === this.controller.mousedown_node.type && l.target.index === this.controller.mousedown_node.index && l.source.element === 'ontology') {
            isLinked = true
          } else if (l.source.type === this.controller.mousedown_node.type && l.source.index === this.controller.mousedown_node.index && l.target.element === 'ontology') {
            isLinked = true
          }
        }
      } else {
        let count = 0
        for (let l of this.graph.links) {
          if (l.target.type === this.controller.mousedown_node.type && l.target.index === this.controller.mousedown_node.index && l.source.element === 'ontology') {
            count++
          } else if (l.source.type === this.controller.mousedown_node.type && l.source.index === this.controller.mousedown_node.index && l.target.element === 'ontology') {
            count++
          }
        }
        if (count >= 2) isLinked = true
      }

      if (!isLinked) {

        // add link
        let link = {
          element: 'link',
          name: idLink,
          sameLink: {current: counterOfSameLink, all: counterOfSameLink},
          short: 'r' + this.linksCounter,
          source: linkSource,
          target: linkTarget,
          sourceD: {dx: linkSource.dx, dy: linkSource.dy},
          targetD: {dx: linkTarget.dx, dy: linkTarget.dy},
          sourcePart: linkSource.part,
          targetPart: linkTarget.part,
          directed: true,
          label: idLink + ' ' + this.linksCounter,
          linkType: 'common',
          form: {}
        }
        this.linksCounter++
        this.graph.links.push(link)

        for (let l of this.graph.links) {
          if ((this.compareNodes(l.source, link.source) && this.compareNodes(l.target, link.target)) ||
                  (this.compareNodes(l.source, link.target) && this.compareNodes(l.target, link.source))) {
            if (l !== link) {
              l.sameLink = {
                current: l.sameLink.current,
                all: counterOfSameLink
              }
            }
          }
        }

        // select new link
        this.$emit('select-element', {element: link, type: 'link'})
        this.controller.selected_link = link
        this.controller.selected_node = null
      }

      this.resetMouseVars()
      this.restart('addLink', null)
      this.dataTypeViolationTest()
    },
    restart (action, newNode) {
      const vm = this

      this.controller.updateGraph(this.graph)

      if (action === 'delete' || action === 'info') {
        this.controller.removeElements('.node')
        this.controller.removeElements('.link')
        this.controller.removeElements('.marker')
        this.controller.removeElements('.node-link')
        this.controller.removeElements('.text-link')
      }

      function linkMouseDown(d) {
        vm.resetMouseVars()
        vm.controller.select(this).attr('style', d => {
          if (d.linkType === 'negative') {
            return 'stroke:#AC3B61;stroke-width:4px;stroke-dasharray:0.5%;fill: none;'
          } else if (d.linkType === 'exclusive') {
            return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
          } else {
            return 'stroke:#AC3B61;stroke-width:4px;fill: none;'
          }
        })
        const selected = d
        vm.controller.graphSelection.selectAll('.marker').selectAll('polygon').attr('style', r => {
          if (r === selected) {
            if (r.linkType === 'negative') return 'fill:#AC3B61'
            else if (r.linkType === 'exclusive') return 'fill:#AC3B61'
            else return 'fill:#AC3B61'
          } else {
            if (r.linkType === 'negative') return 'fill:#550000'
            else if (r.linkType === 'exclusive') return 'fill:#999'
            else return 'fill:#999'
          }
        })
        vm.controller.mousedown_link = d
        vm.controller.selected_link = d
        vm.controller.selected_node = null

        // vm.restart()
        if (vm.controller.canBeEdited) {
          vm.$emit('select-element', {element: d, type: 'link'})
        }
        vm.controller.graphSelection.selectAll('.add-property').attr('style', 'display: none;')
        if (vm.controller.event().button === 2) {
          vm.controller.selected_link = d
          vm.menu.display = 'block'

          vm.controller.graphSelection.select('#contextMenu')
                  .attr('x', (d.source.x + d.sourceD.dx + d.target.x + d.targetD.dx) / 2)
                  .attr('y', (d.source.y + d.sourceD.dy + d.target.y + d.targetD.dy) / 2)
        }
      }

      function linkNodeMouseDown(d) {
        vm.resetMouseVars()
        const selected = d
        vm.controller.graphSelection.selectAll('.link').attr('style', r => {
          if (selected === r) {
            if (r.linkType === 'negative') {
              return 'stroke:#AC3B61;stroke-width:4px;stroke-dasharray:0.5%;fill: none;'
            } else if (r.linkType === 'exclusive') {
              return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
            } else {
              return 'stroke:#AC3B61;stroke-width:4px;fill: none;'
            }
          } else {
            if (r.linkType === 'negative') return 'stroke:#550000;stroke-width:4px;stroke-dasharray:0.5%;fill: none;'
            if (r.linkType === 'exclusive') return 'stroke:#000;stroke-width:6px;fill: none;'
            return 'stroke:#999;stroke-width:4px;fill: none;'
          }
        })
        vm.controller.graphSelection.selectAll('.marker').selectAll('polygon').attr('style', r => {
          if (r === selected) {
            if (r.linkType === 'negative') return 'fill:#AC3B61'
            else if (r.linkType === 'exclusive') return 'fill:#AC3B61'
            else return 'fill:#AC3B61'
          } else {
            if (r.linkType === 'negative') return 'fill:#550000'
            else if (r.linkType === 'exclusive') return 'fill:#999'
            else return 'fill:#999'
          }
        })
        if (vm.controller.event().button === 0) {
          vm.controller.mousedown_link = d
          vm.controller.selected_link = d
          vm.controller.selected_node = null

          // vm.restart()
          if (vm.controller.canBeEdited) {
            vm.$emit('select-element', {element: d, type: 'link'})
          }
        } else if (vm.controller.event().button === 2) {
          vm.controller.selected_link = d
          vm.menu.display = 'block'

          vm.controller.graphSelection.select('#contextMenu')
                  .attr('x', d.x)
                  .attr('y', d.y)
        }
      }

      function ontologyMouseDown(d) {
        vm.resetMouseVars()
        vm.controller.select(this).select('.stroke').attr('style', d => {
          return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:#AC3B61;stroke-width:4px'
        })
        if (vm.controller.event().button === 0) {
          if (vm.controller.canBeSelected) {
            vm.controller.mousedown_node = d
            vm.controller.selected_node = d
            vm.controller.selected_link = null
            // vm.restart('')
            if (vm.controller.canBeEdited) {
              vm.$emit('select-element', {element: d, type: 'node'})
            }
          }
        } else if (vm.controller.event().button === 2) {
          vm.controller.selected_node = d
          vm.menu.display = 'block'

          vm.controller.graphSelection.select('#contextMenu')
                  .attr('x', d.x)
                  .attr('y', d.y)
        }
      }

      function ontologyMouseUp(d) {
        if (vm.controller.event().button === 0) {
          if (vm.controller.canBeSelected) {
            vm.controller.selected_node = d
            vm.controller.mouseup_node = d
            // vm.restart('')
          }
        }
      }

      function valueMouseDown(d) {
        if (vm.editValue && vm.controller.event().button === 0) {
          vm.controller.select(window)
                  .on('keydown', function () {
                    switch (vm.controller.event().keyCode) {
                      case 13: {
                        vm.resetMouseVars()
                      }
                    }
                  })
                  .on('keyup', null)
          vm.inputValue = d.value
          if ((d.name === 'String' || d.name === 'Number') && !d.list.isList) {
            document.getElementById('valueEdit').focus()
            vm.controller.graphSelection.selectAll('#valueEditObject').raise()
            vm.controller.graphSelection.select('#valueEditObject')
                    .attr('x', d.x - (120 + d.additionalWidth) / 2)
                    .attr('y', d.y + 22)
            vm.additionalValueWidth = d.additionalWidth
            vm.editableText = vm.controller.select(this).select('.text-value')
          } else if (d.name === 'Boolean' && !d.list.isList) {
            vm.controller.graphSelection.selectAll('#valueBoolEditObject').raise()
            vm.controller.graphSelection.select('#valueBoolEditObject')
                    .attr('x', d.x - (120) / 2)
                    .attr('y', d.y + 22)
          } else if (d.name === 'Date' && !d.list.isList) {
            document.getElementById('valueDateEdit').focus()
            vm.controller.graphSelection.selectAll('#valueDateEditObject').raise()
            vm.controller.graphSelection.select('#valueDateEditObject')
                    .attr('x', d.x - (150) / 2)
                    .attr('y', d.y + 22)
          } else if (d.list.isList) {
            vm.valueList = []
            axios
                    .get(`${ScienceAPI}/getList/` + d.list.listName)
                    .then(function (response) {
                      // handle success
                      const data = response.data.data
                      for (let i = 0; i < data.length; i++) {
                        vm.valueList.push(data[i]['1'])
                      }
                    })
                    .catch(function (error) {
                      // handle error
                      console.log(error)
                    })
                    .finally(function () {
                      // always executed
                    })
            document.getElementById('valueListEdit').focus()
            vm.controller.graphSelection.selectAll('#valueListEditObject').raise()
            vm.controller.graphSelection.select('#valueListEditObject')
                    .attr('x', d.x - (200) / 2)
                    .attr('y', d.y + 22 - 250)
            vm.editableText = vm.controller.select(this).select('.text-value')
          }

          vm.editableValue = d
          vm.controller.graphSelection.selectAll('.link').attr('style', d => {
            if (d.linkType === 'negative') return 'stroke:#550000;stroke-width:4px;stroke-dasharray:0.5%; opacity: 0.3;fill: none;'
            if (d.linkType === 'exclusive') return 'stroke:#000000;stroke-width:6px;opacity: 0.3;fill: none;'
            return 'stroke:#999;stroke-width:4px;opacity: 0.3;fill: none;'
          })
          vm.controller.graphSelection.selectAll('.text-link').attr('style', 'opacity: 0.5;')
          vm.controller.graphSelection.selectAll('.node').attr('style', 'opacity: 0.7;')
          vm.controller.select(this).attr('style', 'pointer-events: none; opacity: 1;')
        }
      }

      function attributeMouseDown(d) {
        if (vm.editValue && vm.controller.event().button === 0) {
          vm.editableValue = d
          vm.propertySelect = d.attribute
          vm.properties = []
          if (d.element === 'attribute') {
            if (d.parent.element === 'ontology') {
              let parentNode = null
              let alreadyUsed = []
              for (let link of vm.links) {
                if (link.source.index === d.parent.index && link.target.element === 'attribute') {
                  parentNode = vm.ontology.nodes[link.source.name]
                  if (link.target.attribute !== null) alreadyUsed.push(link.target.attribute.name)
                } else if (link.target.index === d.parent.index && link.source.element === 'attribute') {
                  parentNode = vm.ontology.nodes[link.target.name]
                  if (link.source.attribute !== null) alreadyUsed.push(link.source.attribute.name)
                }
              }
              // console.log('parentNode', vm.propertySelect, parentNode)
              for (let property of Object.keys(parentNode.properties)) {
                if (!alreadyUsed.includes(property)) vm.properties.push({
                  name: property,
                  type: parentNode.properties[property].type,
                  isList: parentNode.properties[property].isList
                })
              }
              if (!alreadyUsed.includes('neoID')) vm.properties.push({
                name: 'neoID',
                type: 'Number',
                isList: false
              })

            } else {
              let parentLink = null
              let alreadyUsed = []
              for (let link of vm.links) {
                if (link.element === 'link-attribute' && link.source.index === d.parent.index && link.target.element === 'attribute') {
                  parentLink = vm.data.linkTypes[link.source.type]
                  if (link.target.attribute !== null) alreadyUsed.push(link.target.attribute.name)
                } else if (link.element === 'link-attribute' && link.target.index === d.parent.index && link.source.element === 'attribute') {
                  parentLink = vm.data.linkTypes[link.target.type]
                  if (link.source.attribute !== null) alreadyUsed.push(link.source.attribute.name)
                }
              }

              for (let property of Object.keys(parentLink.properties)) {
                if (!alreadyUsed.includes(property)) vm.properties.push({
                  name: property,
                  type: parentLink.properties[property].type,
                  isList: parentLink.properties[property].isList
                })
              }
              if (!alreadyUsed.includes('neoID')) vm.properties.push({
                name: 'neoID',
                type: 'Number',
                isList: false
              })
            }
          } else {
            let parentNode = null
            let alreadyUsed = []
            for (let link of vm.links) {
              if (link.source.index === d.parent.index && link.target.element === 'attribute-display-name') {
                parentNode = vm.ontology.nodes[link.source.name]
                if (link.target.attribute !== null) alreadyUsed.push(link.target.attribute.name)
              } else if (link.target.index === d.parent.index && link.source.element === 'attribute-display-name') {
                parentNode = vm.ontology.nodes[link.target.name]
                if (link.source.attribute !== null) alreadyUsed.push(link.source.attribute.name)
              }
            }
            // console.log('parentNode', vm.propertySelect, parentNode)
            for (let property of Object.keys(parentNode.properties)) {
              if (!alreadyUsed.includes(property)) vm.properties.push({
                name: property,
                type: parentNode.properties[property].type,
                isList: parentNode.properties[property].isList
              })
            }
          }
          vm.controller.graphSelection.select('#attributeEditObject')
                  .attr('x', d.x - (200) / 2)
                  .attr('y', d.y + 22 - 250)
          vm.controller.graphSelection.selectAll('#attributeEditObject').raise()
          vm.editableText = vm.controller.select(this).select('.text-value')
          vm.additionalValueWidth = d.additionalWidth


          vm.controller.graphSelection.selectAll('.link').attr('style', d => {
            if (d.linkType === 'negative') return 'stroke:#550000;stroke-width:4px;stroke-dasharray:0.5%; opacity: 0.3;fill: none;'
            if (d.linkType === 'exclusive') return 'stroke:#000000;stroke-width:6px;opacity: 0.3;fill: none;'
            return 'stroke:#999;stroke-width:4px;opacity: 0.3;fill: none;'
          })
          vm.controller.graphSelection.selectAll('.text-link').attr('style', 'opacity: 0.5;')
          vm.controller.graphSelection.selectAll('.node').attr('style', 'opacity: 0.7;')
          vm.controller.select(this).attr('style', 'pointer-events: none; opacity: 1;')
        }
      }


      let gNode = this.controller.createOntology(action, '#contextMenu', ontologyMouseDown, ontologyMouseUp)
      this.controller.createValue(action, '#contextMenu', valueMouseDown, null)
      this.controller.createAttribute(action, '#contextMenu', attributeMouseDown, null)
      this.controller.createOperator(action, '#contextMenu', null, null)
      this.controller.createLogic(action, '#contextMenu', null, null)
      this.controller.createLinks(action, '.node', linkMouseDown, null, linkNodeMouseDown, null)
      this.controller.removeElements('#dragLine')
      this.controller.createDragLine()



      if (this.instrument === 'cursor') {
        this.controller.enableDrag()
      }


      if (this.controller.event()) {
        // prevent browser's default behavior
        this.controller.event().preventDefault()
      }

      this.controller.restartSimulation()


      gNode.insert('circle')
        .attr('style', d => {
          if (d.short === 'first')  return 'pointer-events: fill; fill:#AC3B61; stroke:none;'
          if (d.short === 'second')  return 'pointer-events: fill; fill:#3BAC61; stroke:none;'
        })
        .attr('r', d => {
          if (d.short === 'first') return 13
          if (d.short === 'second') return 13
          return 0
        })
        .attr('cx', 80 / 2 * Math.cos(Math.PI / 4))
        .attr('cy', 80 / 2* Math.cos(Math.PI / 4))

      gNode.insert('text')
              .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
              .attr('font-size', '12px')
              .attr('fill', 'white')
              .attr('font-weight', 'bold')
              .attr('text-anchor', 'middle')
              .attr('x', 80 / 2* Math.cos(Math.PI / 4))
              .attr('y', 80 / 2* Math.cos(Math.PI / 4) + 3)
              .text(d => {
                if (d.short === 'first') return '1'
                if (d.short === 'second') return '2'
                else return ''
              })

      if (action === 'add') {
        this.$emit('restart', {action: 'add', node: newNode})
      } else {
        this.$emit('restart', {action: 'restart', node: newNode})
      }
    },
    spliceLinksForNode (node) {
      const vm = this
      let toSplice = this.graph.links.filter(
        function (l) {
          return (l.source === node) || (l.target === node)
        })
      toSplice.map(
        function (l) {
          vm.graph.links.splice(vm.graph.links.indexOf(l), 1)
        })
    },
    compareNodes (n1, n2) {
      if (n1.element === n2.element && n1.index === n2.index) return true
      else return false
    },
    resetMouseVars () {
      this.dragged = null
      this.editValue = false
      this.editableValue = null
      this.controller.resetMouseVars()
    }
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h1,
  h2 {
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  #container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .cc-selector input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .cc-selector input:active + .drinkcard-cc {
    opacity: .9;
  }

  .cc-selector input:checked + .drinkcard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .cc-type-selector input:active + .typecard-cc {
    opacity: 1;
  }

  .cc-type-selector input:checked + .typecard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .ic {
    width: 32px;
    height: 32px;
    margin-top: 0;
    padding-top: 0;
    background-color: #123c69;
    border-radius: 25%;
  }

  .ic-type {
    width: 32px;
    height: 32px;
    margin-top: 0;
    padding-top: 0;
  }

  .icon-cog {
    color: white;
    margin-top: 5px;
    margin-left: 0px;
  }

  .drinkcard-cc {
    cursor: pointer;
    margin-right: -14px;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: brightness(1.4) grayscale(.7) opacity(.5);
    -moz-filter: brightness(1.4) grayscale(.7) opacity(.5);
    filter: brightness(1.4) grayscale(.7) opacity(.5);
  }

  .drinkcard-cc:hover {
    -webkit-filter: brightness(1.1) grayscale(.5) opacity(.9);
    -moz-filter: brightness(1.1) grayscale(.5) opacity(.9);
    filter: brightness(1.1) grayscale(.5) opacity(.9);
  }

  .typecard-cc {
    cursor: pointer;
    margin-right: -14px;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: brightness(1.2) grayscale(.6) opacity(.6);
    -moz-filter: brightness(1.2) grayscale(.6) opacity(.6);
    filter: brightness(1.2) grayscale(.6) opacity(.6);
  }

  .typecard-cc:hover {
    -webkit-filter: brightness(1.0) grayscale(.1) opacity(1);
    -moz-filter: brightness(1.0) grayscale(.1) opacity(1);
    filter: brightness(1.0) grayscale(.1) opacity(1);
  }

  #control-pane > div {
    margin: 10px;
    overflow-x: auto;
  }

  .drag_line {
    stroke: #999;
    stroke-width: 3;
    pointer-events: none;
  }

  .drag_line_hidden {
    stroke: #999;
    stroke-width: 0;
    pointer-events: none;
  }

  .link {
    stroke: #999;
    stroke-width: 3;
  }

  .link_selected {
    stroke: #ff7f0e;
  }

</style>
