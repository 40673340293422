const arrowJson = {
    "0": [0.5, 0.512195, 0.52381, 0.534884, 0.545455, 0.555556, 0.565217,
        0.574468, 0.583333, 0.591837, 0.6, 0.607843, 0.615385, 0.622642,
        0.62963, 0.636364, 0.642857, 0.649123, 0.655172, 0.661017, 0.666667,
        0.672131, 0.677419, 0.68254, 0.6875, 0.692308, 0.69697, 0.701493,
        0.705882, 0.710145, 0.714286, 0.71831, 0.722222, 0.726027, 0.72973,
        0.733333, 0.736842, 0.74026, 0.74359, 0.746835, 0.75, 0.753086,
        0.756098, 0.759036, 0.761905, 0.764706, 0.767442, 0.770115, 0.772727,
        0.775281, 0.777778, 0.78022, 0.782609, 0.784946, 0.787234, 0.789474,
        0.791667, 0.793814, 0.795918, 0.79798, 0.8, 0.80198, 0.803922,
        0.805825, 0.807692, 0.809524, 0.811321, 0.813084, 0.814815, 0.816514,
        0.818182, 0.81982, 0.821429, 0.823009, 0.824561, 0.826087, 0.827586,
        0.82906, 0.830508, 0.831933, 0.833333, 0.834711, 0.836066, 0.837398,
        0.83871, 0.84, 0.84127, 0.84252, 0.84375, 0.844961, 0.846154,
        0.847328, 0.848485, 0.849624, 0.850746, 0.851852, 0.852941, 0.854015,
        0.855072, 0.856115, 0.857143, 0.858156, 0.859155, 0.86014, 0.861111,
        0.862069, 0.863014, 0.863946, 0.864865, 0.865772, 0.866667, 0.86755,
        0.868421, 0.869281, 0.87013, 0.870968, 0.871795, 0.872611, 0.873418,
        0.874214, 0.875, 0.875776, 0.876543, 0.877301, 0.878049, 0.878788,
        0.879518, 0.88024, 0.880952, 0.881657, 0.882353, 0.883041, 0.883721,
        0.884393, 0.885057, 0.885714, 0.886364, 0.887006, 0.88764, 0.888268,
        0.888889, 0.889503, 0.89011, 0.89071, 0.891304, 0.891892, 0.892473,
        0.893048, 0.893617, 0.89418, 0.894737, 0.895288, 0.895833, 0.896373,
        0.896907, 0.897436, 0.897959, 0.898477, 0.89899, 0.899497, 0.9,
        0.900498, 0.90099, 0.901478, 0.901961, 0.902439, 0.902913, 0.903382,
        0.903846, 0.904306, 0.904762, 0.905213, 0.90566, 0.906103, 0.906542,
        0.906977, 0.907407, 0.907834, 0.908257, 0.908676, 0.909091, 0.909502,
        0.90991, 0.910314, 0.910714, 0.911111, 0.911504, 0.911894, 0.912281,
        0.912664, 0.913043, 0.91342, 0.913793, 0.914163, 0.91453, 0.914894,
        0.915254, 0.915612, 0.915966, 0.916318, 0.916667, 0.917012, 0.917355,
        0.917695, 0.918033, 0.918367, 0.918699, 0.919028, 0.919355, 0.919679,
        0.92, 0.920319, 0.920635, 0.920949, 0.92126, 0.921569, 0.921875,
        0.922179, 0.922481, 0.92278, 0.923077, 0.923372, 0.923664, 0.923954,
        0.924242, 0.924528, 0.924812, 0.925094, 0.925373, 0.925651, 0.925926,
        0.926199, 0.926471, 0.92674, 0.927007, 0.927273, 0.927536, 0.927798,
        0.928058, 0.928315, 0.928571, 0.928826, 0.929078, 0.929329, 0.929577,
        0.929825, 0.93007, 0.930314, 0.930556, 0.930796, 0.931034, 0.931271,
        0.931507, 0.931741, 0.931973, 0.932203, 0.932432, 0.93266, 0.932886,
        0.93311, 0.933333, 0.933555, 0.933775, 0.933993, 0.934211, 0.934426,
        0.934641, 0.934853, 0.935065, 0.935275, 0.935484, 0.935691, 0.935897,
        0.936102, 0.936306, 0.936508, 0.936709, 0.936909, 0.937107, 0.937304,
        0.9375, 0.937695, 0.937888, 0.93808, 0.938272, 0.938462, 0.93865,
        0.938838, 0.939024, 0.93921, 0.939394, 0.939577, 0.939759, 0.93994,
        0.94012, 0.940299, 0.940476, 0.940653, 0.940828, 0.941003, 0.941176,
        0.941349, 0.94152, 0.941691, 0.94186, 0.942029, 0.942197, 0.942363,
        0.942529, 0.942693, 0.942857, 0.94302, 0.943182, 0.943343, 0.943503,
        0.943662, 0.94382, 0.943978, 0.944134, 0.94429, 0.944444, 0.944598,
        0.944751, 0.944904, 0.945055, 0.945205, 0.945355, 0.945504, 0.945652,
        0.945799, 0.945946, 0.946092, 0.946237, 0.946381, 0.946524, 0.946667,
        0.946809, 0.94695, 0.94709, 0.94723, 0.947368, 0.947507, 0.947644,
        0.947781, 0.947917, 0.948052, 0.948187, 0.94832, 0.948454, 0.948586,
        0.948718, 0.948849, 0.94898, 0.949109, 0.949239, 0.949367, 0.949495,
        0.949622, 0.949749, 0.949875, 0.95, 0.950125, 0.950249, 0.950372,
        0.950495, 0.950617, 0.950739, 0.95086, 0.95098, 0.9511, 0.95122,
        0.951338, 0.951456, 0.951574, 0.951691, 0.951807, 0.951923, 0.952038,
        0.952153, 0.952267, 0.952381, 0.952494, 0.952607, 0.952719, 0.95283,
        0.952941, 0.953052, 0.953162, 0.953271, 0.95338, 0.953488, 0.953596,
        0.953704, 0.953811, 0.953917, 0.954023, 0.954128, 0.954233, 0.954338,
        0.954442, 0.954545, 0.954649, 0.954751, 0.954853, 0.954955, 0.955056,
        0.955157, 0.955257, 0.955357, 0.955457, 0.955556, 0.955654, 0.955752,
        0.95585, 0.955947, 0.956044, 0.95614, 0.956236, 0.956332, 0.956427,
        0.956522, 0.956616, 0.95671, 0.956803, 0.956897, 0.956989, 0.957082,
        0.957173, 0.957265, 0.957356, 0.957447, 0.957537, 0.957627, 0.957717,
        0.957806, 0.957895, 0.957983, 0.958071, 0.958159, 0.958246, 0.958333,
        0.95842, 0.958506, 0.958592, 0.958678, 0.958763, 0.958848, 0.958932,
        0.959016, 0.9591, 0.959184, 0.959267, 0.95935, 0.959432, 0.959514,
        0.959596, 0.959677, 0.959759, 0.959839, 0.95992, 0.96, 0.96008,
        0.960159, 0.960239, 0.960317, 0.960396, 0.960474, 0.960552, 0.96063,
        0.960707, 0.960784, 0.960861, 0.960938, 0.961014, 0.961089, 0.961165,
        0.96124, 0.961315, 0.96139, 0.961464, 0.961538, 0.961612, 0.961686,
        0.961759, 0.961832, 0.961905, 0.961977, 0.962049, 0.962121, 0.962193,
        0.962264, 0.962335, 0.962406, 0.962477, 0.962547, 0.962617, 0.962687,
        0.962756, 0.962825, 0.962894, 0.962963, 0.963031, 0.9631, 0.963168,
        0.963235, 0.963303, 0.96337, 0.963437, 0.963504, 0.96357, 0.963636,
        0.963702, 0.963768, 0.963834, 0.963899, 0.963964, 0.964029, 0.964093,
        0.964158, 0.964222, 0.964286, 0.964349, 0.964413, 0.964476, 0.964539,
        0.964602, 0.964664, 0.964727, 0.964789, 0.964851, 0.964912, 0.964974,
        0.965035, 0.965096, 0.965157, 0.965217, 0.965278, 0.965338, 0.965398,
        0.965458, 0.965517, 0.965577, 0.965636, 0.965695, 0.965753, 0.965812,
        0.96587, 0.965928, 0.965986, 0.966044, 0.966102, 0.966159, 0.966216,
        0.966273, 0.96633, 0.966387, 0.966443, 0.966499, 0.966555, 0.966611,
        0.966667, 0.966722, 0.966777, 0.966833, 0.966887, 0.966942, 0.966997,
        0.967051, 0.967105, 0.967159, 0.967213, 0.967267, 0.96732, 0.967374,
        0.967427, 0.96748, 0.967532, 0.967585, 0.967638, 0.96769, 0.967742,
        0.967794, 0.967846, 0.967897, 0.967949, 0.968, 0.968051, 0.968102,
        0.968153, 0.968203, 0.968254, 0.968304, 0.968354, 0.968404, 0.968454,
        0.968504, 0.968553, 0.968603, 0.968652, 0.968701, 0.96875, 0.968799,
        0.968847, 0.968896, 0.968944, 0.968992, 0.96904, 0.969088, 0.969136,
        0.969183, 0.969231, 0.969278, 0.969325, 0.969372, 0.969419, 0.969466,
        0.969512, 0.969559, 0.969605, 0.969651, 0.969697, 0.969743, 0.969789,
        0.969834, 0.96988, 0.969925, 0.96997, 0.970015, 0.97006, 0.970105,
        0.970149, 0.970194, 0.970238, 0.970282, 0.970326, 0.97037, 0.970414,
        0.970458, 0.970501, 0.970545, 0.970588, 0.970631, 0.970674, 0.970717,
        0.97076, 0.970803, 0.970845, 0.970888, 0.97093, 0.970972, 0.971014,
        0.971056, 0.971098, 0.97114, 0.971182, 0.971223, 0.971264, 0.971306,
        0.971347, 0.971388, 0.971429, 0.971469, 0.97151, 0.97155, 0.971591,
        0.971631, 0.971671, 0.971711, 0.971751, 0.971791, 0.971831, 0.971871,
        0.97191, 0.97195, 0.971989, 0.972028, 0.972067, 0.972106, 0.972145,
        0.972184, 0.972222, 0.972261, 0.972299, 0.972337, 0.972376, 0.972414,
        0.972452, 0.97249, 0.972527, 0.972565, 0.972603, 0.97264, 0.972678,
        0.972715, 0.972752, 0.972789, 0.972826, 0.972863, 0.9729, 0.972936,
        0.972973, 0.973009, 0.973046, 0.973082, 0.973118, 0.973154, 0.97319,
        0.973226, 0.973262, 0.973298, 0.973333, 0.973369, 0.973404, 0.97344,
        0.973475, 0.97351, 0.973545, 0.97358, 0.973615, 0.97365, 0.973684,
        0.973719, 0.973753, 0.973788, 0.973822, 0.973856, 0.97389, 0.973924,
        0.973958, 0.973992, 0.974026, 0.97406, 0.974093, 0.974127, 0.97416,
        0.974194, 0.974227, 0.97426, 0.974293, 0.974326, 0.974359, 0.974392,
        0.974425, 0.974457, 0.97449, 0.974522, 0.974555, 0.974587, 0.974619,
        0.974651, 0.974684, 0.974716, 0.974747, 0.974779, 0.974811, 0.974843,
        0.974874, 0.974906, 0.974937, 0.974969, 0.975],
    "1": [0.578078, 0.587329, 0.596097, 0.604428, 0.61236, 0.619927, 0.627159,
        0.634082, 0.640718, 0.647088, 0.653211, 0.659104, 0.664779, 0.670253,
        0.675535, 0.680639, 0.685573, 0.690347, 0.694969, 0.699448, 0.703791,
        0.708005, 0.712095, 0.716067, 0.719928, 0.723682, 0.727333, 0.730887,
        0.734347, 0.737718, 0.741002, 0.744204, 0.747326, 0.750372, 0.753344,
        0.756246, 0.75908, 0.761849, 0.764554, 0.767198, 0.769783, 0.772312,
        0.774785, 0.777205, 0.779574, 0.781894, 0.784165, 0.786389, 0.788568,
        0.790704, 0.792797, 0.794849, 0.796861, 0.798834, 0.80077, 0.802669,
        0.804532, 0.806361, 0.808157, 0.80992, 0.811651, 0.813351, 0.815021,
        0.816662, 0.818274, 0.819859, 0.821416, 0.822947, 0.824453, 0.825933,
        0.827389, 0.828822, 0.830231, 0.831617, 0.832981, 0.834324, 0.835645,
        0.836946, 0.838226, 0.839487, 0.840729, 0.841952, 0.843157, 0.844344,
        0.845513, 0.846665, 0.8478, 0.848919, 0.850021, 0.851108, 0.85218,
        0.853236, 0.854278, 0.855305, 0.856318, 0.857317, 0.858303, 0.859275,
        0.860234, 0.861181, 0.862115, 0.863036, 0.863946, 0.864843, 0.865729,
        0.866604, 0.867468, 0.86832, 0.869162, 0.869994, 0.870815, 0.871626,
        0.872426, 0.873218, 0.873999, 0.874771, 0.875534, 0.876287, 0.877032,
        0.877768, 0.878495, 0.879214, 0.879925, 0.880627, 0.881321, 0.882008,
        0.882686, 0.883357, 0.88402, 0.884676, 0.885325, 0.885967, 0.886601,
        0.887229, 0.887849, 0.888463, 0.889071, 0.889672, 0.890266, 0.890854,
        0.891436, 0.892012, 0.892582, 0.893146, 0.893705, 0.894257, 0.894804,
        0.895345, 0.895881, 0.896412, 0.896937, 0.897457, 0.897971, 0.898481,
        0.898986, 0.899485, 0.89998, 0.90047, 0.900955, 0.901436, 0.901912,
        0.902384, 0.902851, 0.903314, 0.903772, 0.904226, 0.904676, 0.905122,
        0.905563, 0.906001, 0.906434, 0.906864, 0.90729, 0.907712, 0.90813,
        0.908544, 0.908955, 0.909362, 0.909765, 0.910165, 0.910562, 0.910955,
        0.911345, 0.911731, 0.912114, 0.912493, 0.91287, 0.913243, 0.913613,
        0.91398, 0.914344, 0.914705, 0.915063, 0.915418, 0.91577, 0.916119,
        0.916465, 0.916808, 0.917149, 0.917487, 0.917822, 0.918154, 0.918484,
        0.918811, 0.919136, 0.919458, 0.919778, 0.920095, 0.920409, 0.920721,
        0.921031, 0.921338, 0.921643, 0.921946, 0.922246, 0.922544, 0.92284,
        0.923133, 0.923424, 0.923713, 0.924, 0.924285, 0.924568, 0.924849,
        0.925127, 0.925404, 0.925678, 0.925951, 0.926221, 0.92649, 0.926757,
        0.927021, 0.927284, 0.927545, 0.927804, 0.928061, 0.928317, 0.928571,
        0.928823, 0.929073, 0.929321, 0.929568, 0.929813, 0.930056, 0.930298,
        0.930538, 0.930776, 0.931013, 0.931248, 0.931481, 0.931713, 0.931944,
        0.932173, 0.9324, 0.932626, 0.93285, 0.933073, 0.933295, 0.933514,
        0.933733, 0.93395, 0.934166, 0.93438, 0.934593, 0.934805, 0.935015,
        0.935224, 0.935431, 0.935637, 0.935842, 0.936046, 0.936248, 0.936449,
        0.936649, 0.936848, 0.937045, 0.937241, 0.937436, 0.93763, 0.937822,
        0.938013, 0.938204, 0.938393, 0.93858, 0.938767, 0.938953, 0.939137,
        0.939321, 0.939503, 0.939684, 0.939864, 0.940043, 0.940221, 0.940398,
        0.940574, 0.940749, 0.940923, 0.941096, 0.941268, 0.941439, 0.941608,
        0.941777, 0.941945, 0.942112, 0.942278, 0.942443, 0.942607, 0.942771,
        0.942933, 0.943094, 0.943255, 0.943414, 0.943573, 0.943731, 0.943888,
        0.944044, 0.944199, 0.944353, 0.944507, 0.944659, 0.944811, 0.944962,
        0.945112, 0.945262, 0.94541, 0.945558, 0.945705, 0.945851, 0.945997,
        0.946141, 0.946285, 0.946428, 0.94657, 0.946712, 0.946853, 0.946993,
        0.947132, 0.947271, 0.947409, 0.947546, 0.947682, 0.947818, 0.947953,
        0.948088, 0.948221, 0.948354, 0.948487, 0.948618, 0.948749, 0.94888,
        0.949009, 0.949138, 0.949267, 0.949394, 0.949522, 0.949648, 0.949774,
        0.949899, 0.950024, 0.950148, 0.950271, 0.950394, 0.950516, 0.950637,
        0.950758, 0.950879, 0.950998, 0.951118, 0.951236, 0.951354, 0.951472,
        0.951589, 0.951705, 0.951821, 0.951936, 0.952051, 0.952165, 0.952279,
        0.952392, 0.952504, 0.952616, 0.952728, 0.952839, 0.952949, 0.953059,
        0.953169, 0.953278, 0.953386, 0.953494, 0.953601, 0.953708, 0.953815,
        0.953921, 0.954026, 0.954131, 0.954236, 0.95434, 0.954443, 0.954546,
        0.954649, 0.954751, 0.954853, 0.954954, 0.955055, 0.955155, 0.955255,
        0.955355, 0.955454, 0.955552, 0.955651, 0.955748, 0.955846, 0.955943,
        0.956039, 0.956135, 0.956231, 0.956326, 0.956421, 0.956515, 0.956609,
        0.956703, 0.956796, 0.956889, 0.956981, 0.957073, 0.957165, 0.957256,
        0.957347, 0.957437, 0.957527, 0.957617, 0.957706, 0.957795, 0.957884,
        0.957972, 0.95806, 0.958147, 0.958234, 0.958321, 0.958407, 0.958493,
        0.958579, 0.958664, 0.958749, 0.958834, 0.958918, 0.959002, 0.959086,
        0.959169, 0.959252, 0.959334, 0.959417, 0.959498, 0.95958, 0.959661,
        0.959742, 0.959823, 0.959903, 0.959983, 0.960063, 0.960142, 0.960221,
        0.9603, 0.960378, 0.960456, 0.960534, 0.960611, 0.960689, 0.960766,
        0.960842, 0.960918, 0.960994, 0.96107, 0.961145, 0.961221, 0.961295,
        0.96137, 0.961444, 0.961518, 0.961592, 0.961665, 0.961738, 0.961811,
        0.961884, 0.961956, 0.962028, 0.9621, 0.962171, 0.962242, 0.962313,
        0.962384, 0.962454, 0.962525, 0.962594, 0.962664, 0.962733, 0.962803,
        0.962871, 0.96294, 0.963008, 0.963076, 0.963144, 0.963212, 0.963279,
        0.963346, 0.963413, 0.96348, 0.963546, 0.963612, 0.963678, 0.963744,
        0.963809, 0.963875, 0.96394, 0.964004, 0.964069, 0.964133, 0.964197,
        0.964261, 0.964324, 0.964388, 0.964451, 0.964514, 0.964577, 0.964639,
        0.964701, 0.964763, 0.964825, 0.964887, 0.964948, 0.965009, 0.96507,
        0.965131, 0.965191, 0.965252, 0.965312, 0.965372, 0.965432, 0.965491,
        0.96555, 0.965609, 0.965668, 0.965727, 0.965785, 0.965844, 0.965902,
        0.96596, 0.966017, 0.966075, 0.966132, 0.966189, 0.966246, 0.966303,
        0.96636, 0.966416, 0.966472, 0.966528, 0.966584, 0.966639, 0.966695,
        0.96675, 0.966805, 0.96686, 0.966915, 0.966969, 0.967024, 0.967078,
        0.967132, 0.967186, 0.967239, 0.967293, 0.967346, 0.967399, 0.967452,
        0.967505, 0.967557, 0.96761, 0.967662, 0.967714, 0.967766, 0.967818,
        0.967869, 0.967921, 0.967972, 0.968023, 0.968074, 0.968125, 0.968175,
        0.968226, 0.968276, 0.968326, 0.968376, 0.968426, 0.968476, 0.968525,
        0.968575, 0.968624, 0.968673, 0.968722, 0.968771, 0.968819, 0.968868,
        0.968916, 0.968964, 0.969012, 0.96906, 0.969107, 0.969155, 0.969202,
        0.96925, 0.969297, 0.969344, 0.969391, 0.969437, 0.969484, 0.96953,
        0.969576, 0.969623, 0.969669, 0.969714, 0.96976, 0.969806, 0.969851,
        0.969896, 0.969942, 0.969987, 0.970031, 0.970076, 0.970121, 0.970165,
        0.97021, 0.970254, 0.970298, 0.970342, 0.970386, 0.970429, 0.970473,
        0.970516, 0.97056, 0.970603, 0.970646, 0.970689, 0.970732, 0.970775,
        0.970817, 0.97086, 0.970902, 0.970944, 0.970986, 0.971028, 0.97107,
        0.971112, 0.971153, 0.971195, 0.971236, 0.971277, 0.971318, 0.971359,
        0.9714, 0.971441, 0.971482, 0.971522, 0.971563, 0.971603, 0.971643,
        0.971683, 0.971723, 0.971763, 0.971803, 0.971842, 0.971882, 0.971921,
        0.971961, 0.972, 0.972039, 0.972078, 0.972117, 0.972156, 0.972194,
        0.972233, 0.972271, 0.972309, 0.972348, 0.972386, 0.972424, 0.972462,
        0.9725, 0.972537, 0.972575, 0.972612, 0.97265, 0.972687, 0.972724,
        0.972761, 0.972798, 0.972835, 0.972872, 0.972909, 0.972945, 0.972982,
        0.973018, 0.973054, 0.973091, 0.973127, 0.973163, 0.973199, 0.973234,
        0.97327, 0.973306, 0.973341, 0.973377, 0.973412, 0.973447, 0.973482,
        0.973518, 0.973552, 0.973587, 0.973622, 0.973657, 0.973691, 0.973726,
        0.97376, 0.973795, 0.973829, 0.973863, 0.973897, 0.973931, 0.973965,
        0.973999, 0.974032, 0.974066, 0.9741, 0.974133, 0.974166, 0.9742,
        0.974233, 0.974266, 0.974299, 0.974332, 0.974365, 0.974398, 0.97443,
        0.974463, 0.974495, 0.974528, 0.97456, 0.974592, 0.974625, 0.974657,
        0.974689, 0.974721, 0.974753, 0.974784, 0.974816, 0.974848, 0.974879,
        0.974911, 0.974942, 0.974973, 0.975005, 0.975036],
    "2": [0.737714, 0.740131, 0.74252, 0.744881, 0.747214, 0.749518, 0.751794,
        0.754042, 0.756261, 0.758452, 0.760615, 0.76275, 0.764857, 0.766936,
        0.768988, 0.771013, 0.773011, 0.774983, 0.776928, 0.778847, 0.780741,
        0.782609, 0.784452, 0.78627, 0.788064, 0.789834, 0.791581, 0.793303,
        0.795003, 0.79668, 0.798335, 0.799968, 0.801578, 0.803168, 0.804736,
        0.806284, 0.807812, 0.809319, 0.810806, 0.812274, 0.813723, 0.815153,
        0.816564, 0.817957, 0.819332, 0.82069, 0.82203, 0.823352, 0.824659,
        0.825948, 0.827221, 0.828478, 0.829719, 0.830945, 0.832155, 0.833351,
        0.834531, 0.835697, 0.836849, 0.837986, 0.83911, 0.84022, 0.841316,
        0.8424, 0.84347, 0.844527, 0.845572, 0.846604, 0.847625, 0.848633,
        0.849629, 0.850614, 0.851587, 0.852549, 0.853499, 0.854439, 0.855368,
        0.856286, 0.857194, 0.858092, 0.85898, 0.859857, 0.860725, 0.861583,
        0.862431, 0.86327, 0.8641, 0.864921, 0.865733, 0.866536, 0.86733,
        0.868115, 0.868893, 0.869662, 0.870422, 0.871175, 0.871919, 0.872656,
        0.873385, 0.874106, 0.87482, 0.875527, 0.876226, 0.876918, 0.877603,
        0.878281, 0.878952, 0.879616, 0.880273, 0.880924, 0.881568, 0.882206,
        0.882838, 0.883463, 0.884082, 0.884695, 0.885302, 0.885904, 0.886499,
        0.887088, 0.887672, 0.888251, 0.888823, 0.889391, 0.889953, 0.890509,
        0.891061, 0.891607, 0.892148, 0.892684, 0.893215, 0.893741, 0.894263,
        0.894779, 0.895291, 0.895798, 0.896301, 0.896799, 0.897293, 0.897782,
        0.898267, 0.898747, 0.899223, 0.899695, 0.900163, 0.900627, 0.901087,
        0.901543, 0.901994, 0.902442, 0.902886, 0.903326, 0.903763, 0.904196,
        0.904625, 0.90505, 0.905472, 0.90589, 0.906305, 0.906717, 0.907125,
        0.907529, 0.907931, 0.908328, 0.908723, 0.909115, 0.909503, 0.909888,
        0.91027, 0.910649, 0.911025, 0.911398, 0.911768, 0.912135, 0.912499,
        0.912861, 0.913219, 0.913575, 0.913928, 0.914278, 0.914625, 0.91497,
        0.915312, 0.915651, 0.915988, 0.916323, 0.916654, 0.916984, 0.91731,
        0.917635, 0.917957, 0.918276, 0.918593, 0.918908, 0.91922, 0.919531,
        0.919838, 0.920144, 0.920447, 0.920749, 0.921048, 0.921344, 0.921639,
        0.921932, 0.922222, 0.92251, 0.922797, 0.923081, 0.923364, 0.923644,
        0.923922, 0.924199, 0.924473, 0.924746, 0.925016, 0.925285, 0.925552,
        0.925817, 0.926081, 0.926342, 0.926602, 0.92686, 0.927116, 0.927371,
        0.927623, 0.927874, 0.928124, 0.928372, 0.928618, 0.928862, 0.929105,
        0.929346, 0.929586, 0.929824, 0.930061, 0.930296, 0.930529, 0.930761,
        0.930992, 0.931221, 0.931448, 0.931674, 0.931899, 0.932122, 0.932344,
        0.932565, 0.932784, 0.933001, 0.933218, 0.933432, 0.933646, 0.933858,
        0.934069, 0.934279, 0.934487, 0.934694, 0.9349, 0.935105, 0.935308,
        0.93551, 0.935711, 0.935911, 0.936109, 0.936306, 0.936502, 0.936697,
        0.936891, 0.937084, 0.937275, 0.937465, 0.937655, 0.937843, 0.93803,
        0.938216, 0.9384, 0.938584, 0.938767, 0.938948, 0.939129, 0.939308,
        0.939487, 0.939664, 0.939841, 0.940016, 0.940191, 0.940364, 0.940537,
        0.940708, 0.940879, 0.941049, 0.941217, 0.941385, 0.941552, 0.941718,
        0.941883, 0.942047, 0.94221, 0.942372, 0.942533, 0.942694, 0.942853,
        0.943012, 0.94317, 0.943327, 0.943483, 0.943639, 0.943793, 0.943947,
        0.9441, 0.944252, 0.944403, 0.944553, 0.944703, 0.944852, 0.945,
        0.945147, 0.945294, 0.94544, 0.945585, 0.945729, 0.945872, 0.946015,
        0.946157, 0.946299, 0.946439, 0.946579, 0.946718, 0.946857, 0.946994,
        0.947131, 0.947268, 0.947403, 0.947538, 0.947673, 0.947806, 0.947939,
        0.948071, 0.948203, 0.948334, 0.948464, 0.948594, 0.948723, 0.948852,
        0.948979, 0.949107, 0.949233, 0.949359, 0.949484, 0.949609, 0.949733,
        0.949857, 0.94998, 0.950102, 0.950224, 0.950345, 0.950465, 0.950585,
        0.950705, 0.950824, 0.950942, 0.95106, 0.951177, 0.951294, 0.95141,
        0.951525, 0.95164, 0.951755, 0.951869, 0.951982, 0.952095, 0.952207,
        0.952319, 0.952431, 0.952541, 0.952652, 0.952762, 0.952871, 0.95298,
        0.953088, 0.953196, 0.953303, 0.95341, 0.953517, 0.953623, 0.953728,
        0.953833, 0.953937, 0.954042, 0.954145, 0.954248, 0.954351, 0.954453,
        0.954555, 0.954656, 0.954757, 0.954857, 0.954957, 0.955057, 0.955156,
        0.955255, 0.955353, 0.955451, 0.955548, 0.955645, 0.955742, 0.955838,
        0.955934, 0.956029, 0.956124, 0.956219, 0.956313, 0.956407, 0.9565,
        0.956593, 0.956686, 0.956778, 0.95687, 0.956961, 0.957052, 0.957143,
        0.957233, 0.957323, 0.957412, 0.957501, 0.95759, 0.957679, 0.957767,
        0.957854, 0.957942, 0.958029, 0.958115, 0.958202, 0.958288, 0.958373,
        0.958458, 0.958543, 0.958628, 0.958712, 0.958796, 0.95888, 0.958963,
        0.959046, 0.959128, 0.95921, 0.959292, 0.959374, 0.959455, 0.959536,
        0.959617, 0.959697, 0.959777, 0.959856, 0.959936, 0.960015, 0.960094,
        0.960172, 0.96025, 0.960328, 0.960406, 0.960483, 0.96056, 0.960636,
        0.960713, 0.960789, 0.960864, 0.96094, 0.961015, 0.96109, 0.961165,
        0.961239, 0.961313, 0.961387, 0.96146, 0.961533, 0.961606, 0.961679,
        0.961751, 0.961824, 0.961895, 0.961967, 0.962038, 0.962109, 0.96218,
        0.962251, 0.962321, 0.962391, 0.962461, 0.96253, 0.962599, 0.962668,
        0.962737, 0.962806, 0.962874, 0.962942, 0.96301, 0.963077, 0.963144,
        0.963212, 0.963278, 0.963345, 0.963411, 0.963477, 0.963543, 0.963609,
        0.963674, 0.963739, 0.963804, 0.963869, 0.963933, 0.963997, 0.964061,
        0.964125, 0.964189, 0.964252, 0.964315, 0.964378, 0.964441, 0.964503,
        0.964565, 0.964627, 0.964689, 0.964751, 0.964812, 0.964873, 0.964934,
        0.964995, 0.965055, 0.965116, 0.965176, 0.965236, 0.965296, 0.965355,
        0.965414, 0.965473, 0.965532, 0.965591, 0.96565, 0.965708, 0.965766,
        0.965824, 0.965882, 0.965939, 0.965997, 0.966054, 0.966111, 0.966167,
        0.966224, 0.96628, 0.966337, 0.966393, 0.966448, 0.966504, 0.96656,
        0.966615, 0.96667, 0.966725, 0.96678, 0.966834, 0.966889, 0.966943,
        0.966997, 0.967051, 0.967105, 0.967158, 0.967211, 0.967265, 0.967318,
        0.96737, 0.967423, 0.967476, 0.967528, 0.96758, 0.967632, 0.967684,
        0.967736, 0.967787, 0.967838, 0.96789, 0.967941, 0.967991, 0.968042,
        0.968093, 0.968143, 0.968193, 0.968243, 0.968293, 0.968343, 0.968393,
        0.968442, 0.968491, 0.968541, 0.96859, 0.968638, 0.968687, 0.968736,
        0.968784, 0.968832, 0.96888, 0.968928, 0.968976, 0.969024, 0.969071,
        0.969119, 0.969166, 0.969213, 0.96926, 0.969307, 0.969353, 0.9694,
        0.969446, 0.969492, 0.969539, 0.969584, 0.96963, 0.969676, 0.969722,
        0.969767, 0.969812, 0.969857, 0.969902, 0.969947, 0.969992, 0.970037,
        0.970081, 0.970125, 0.97017, 0.970214, 0.970258, 0.970302, 0.970345,
        0.970389, 0.970432, 0.970476, 0.970519, 0.970562, 0.970605, 0.970648,
        0.97069, 0.970733, 0.970775, 0.970818, 0.97086, 0.970902, 0.970944,
        0.970986, 0.971027, 0.971069, 0.971111, 0.971152, 0.971193, 0.971234,
        0.971275, 0.971316, 0.971357, 0.971398, 0.971438, 0.971479, 0.971519,
        0.971559, 0.971599, 0.971639, 0.971679, 0.971719, 0.971759, 0.971798,
        0.971838, 0.971877, 0.971916, 0.971955, 0.971994, 0.972033, 0.972072,
        0.972111, 0.972149, 0.972188, 0.972226, 0.972264, 0.972303, 0.972341,
        0.972379, 0.972416, 0.972454, 0.972492, 0.972529, 0.972567, 0.972604,
        0.972641, 0.972678, 0.972715, 0.972752, 0.972789, 0.972826, 0.972863,
        0.972899, 0.972936, 0.972972, 0.973008, 0.973044, 0.97308, 0.973116,
        0.973152, 0.973188, 0.973224, 0.973259, 0.973295, 0.97333, 0.973365,
        0.973401, 0.973436, 0.973471, 0.973506, 0.97354, 0.973575, 0.97361,
        0.973644, 0.973679, 0.973713, 0.973748, 0.973782, 0.973816, 0.97385,
        0.973884, 0.973918, 0.973952, 0.973985, 0.974019, 0.974052, 0.974086,
        0.974119, 0.974152, 0.974185, 0.974219, 0.974252, 0.974284, 0.974317,
        0.97435, 0.974383, 0.974415, 0.974448, 0.97448, 0.974513, 0.974545,
        0.974577, 0.974609, 0.974641, 0.974673, 0.974705, 0.974737, 0.974768,
        0.9748, 0.974831, 0.974863, 0.974894, 0.974926, 0.974957, 0.974988,
        0.975019, 0.97505, 0.975081, 0.975112, 0.975143],
    "3": [0.828193, 0.828817, 0.829448, 0.830086, 0.83073, 0.83138, 0.832036,
        0.832696, 0.833361, 0.834031, 0.834704, 0.835381, 0.836062, 0.836746,
        0.837432, 0.838121, 0.838813, 0.839506, 0.840201, 0.840897, 0.841594,
        0.842293, 0.842992, 0.843691, 0.844391, 0.845091, 0.845791, 0.84649,
        0.847189, 0.847887, 0.848585, 0.849281, 0.849976, 0.85067, 0.851363,
        0.852054, 0.852743, 0.85343, 0.854116, 0.854799, 0.855481, 0.85616,
        0.856836, 0.857511, 0.858182, 0.858851, 0.859518, 0.860182, 0.860843,
        0.861501, 0.862156, 0.862808, 0.863457, 0.864103, 0.864746, 0.865385,
        0.866022, 0.866655, 0.867285, 0.867911, 0.868534, 0.869154, 0.86977,
        0.870383, 0.870993, 0.871599, 0.872201, 0.8728, 0.873396, 0.873988,
        0.874576, 0.875161, 0.875743, 0.87632, 0.876895, 0.877465, 0.878033,
        0.878596, 0.879156, 0.879713, 0.880266, 0.880815, 0.881361, 0.881904,
        0.882443, 0.882978, 0.88351, 0.884039, 0.884564, 0.885085, 0.885603,
        0.886118, 0.886629, 0.887137, 0.887641, 0.888142, 0.88864, 0.889135,
        0.889626, 0.890113, 0.890598, 0.891079, 0.891557, 0.892032, 0.892503,
        0.892971, 0.893436, 0.893898, 0.894357, 0.894813, 0.895265, 0.895715,
        0.896161, 0.896605, 0.897045, 0.897483, 0.897917, 0.898348, 0.898777,
        0.899202, 0.899625, 0.900045, 0.900462, 0.900876, 0.901287, 0.901695,
        0.902101, 0.902504, 0.902904, 0.903302, 0.903696, 0.904089, 0.904478,
        0.904865, 0.905249, 0.905631, 0.90601, 0.906386, 0.90676, 0.907131,
        0.9075, 0.907867, 0.908231, 0.908592, 0.908951, 0.909308, 0.909662,
        0.910014, 0.910364, 0.910711, 0.911056, 0.911399, 0.911739, 0.912077,
        0.912413, 0.912747, 0.913078, 0.913408, 0.913735, 0.91406, 0.914383,
        0.914704, 0.915022, 0.915339, 0.915654, 0.915966, 0.916277, 0.916585,
        0.916892, 0.917196, 0.917499, 0.917799, 0.918098, 0.918395, 0.91869,
        0.918983, 0.919274, 0.919563, 0.91985, 0.920136, 0.92042, 0.920702,
        0.920982, 0.921261, 0.921537, 0.921812, 0.922086, 0.922357, 0.922627,
        0.922895, 0.923162, 0.923427, 0.92369, 0.923951, 0.924211, 0.92447,
        0.924727, 0.924982, 0.925236, 0.925488, 0.925738, 0.925987, 0.926235,
        0.926481, 0.926726, 0.926969, 0.92721, 0.927451, 0.927689, 0.927927,
        0.928163, 0.928397, 0.92863, 0.928862, 0.929093, 0.929322, 0.929549,
        0.929776, 0.930001, 0.930224, 0.930447, 0.930668, 0.930888, 0.931106,
        0.931323, 0.931539, 0.931754, 0.931968, 0.93218, 0.932391, 0.932601,
        0.93281, 0.933017, 0.933223, 0.933428, 0.933632, 0.933835, 0.934037,
        0.934237, 0.934437, 0.934635, 0.934832, 0.935028, 0.935223, 0.935417,
        0.93561, 0.935802, 0.935993, 0.936182, 0.936371, 0.936559, 0.936745,
        0.936931, 0.937115, 0.937299, 0.937481, 0.937663, 0.937843, 0.938023,
        0.938202, 0.938379, 0.938556, 0.938732, 0.938906, 0.93908, 0.939253,
        0.939425, 0.939597, 0.939767, 0.939936, 0.940105, 0.940272, 0.940439,
        0.940605, 0.94077, 0.940934, 0.941097, 0.941259, 0.941421, 0.941582,
        0.941741, 0.941901, 0.942059, 0.942216, 0.942373, 0.942529, 0.942684,
        0.942838, 0.942991, 0.943144, 0.943296, 0.943447, 0.943598, 0.943747,
        0.943896, 0.944044, 0.944192, 0.944339, 0.944485, 0.94463, 0.944774,
        0.944918, 0.945061, 0.945204, 0.945345, 0.945486, 0.945627, 0.945766,
        0.945905, 0.946043, 0.946181, 0.946318, 0.946454, 0.94659, 0.946725,
        0.946859, 0.946993, 0.947126, 0.947258, 0.94739, 0.947521, 0.947652,
        0.947782, 0.947911, 0.94804, 0.948168, 0.948296, 0.948422, 0.948549,
        0.948674, 0.9488, 0.948924, 0.949048, 0.949172, 0.949294, 0.949417,
        0.949538, 0.94966, 0.94978, 0.9499, 0.95002, 0.950139, 0.950257,
        0.950375, 0.950492, 0.950609, 0.950725, 0.950841, 0.950956, 0.951071,
        0.951185, 0.951299, 0.951412, 0.951525, 0.951637, 0.951749, 0.95186,
        0.951971, 0.952081, 0.952191, 0.9523, 0.952409, 0.952517, 0.952625,
        0.952732, 0.952839, 0.952946, 0.953052, 0.953157, 0.953262, 0.953367,
        0.953471, 0.953575, 0.953678, 0.953781, 0.953883, 0.953985, 0.954087,
        0.954188, 0.954288, 0.954389, 0.954488, 0.954588, 0.954687, 0.954785,
        0.954884, 0.954981, 0.955079, 0.955176, 0.955272, 0.955368, 0.955464,
        0.955559, 0.955654, 0.955749, 0.955843, 0.955937, 0.95603, 0.956123,
        0.956216, 0.956308, 0.9564, 0.956491, 0.956583, 0.956673, 0.956764,
        0.956854, 0.956943, 0.957033, 0.957122, 0.95721, 0.957299, 0.957386,
        0.957474, 0.957561, 0.957648, 0.957735, 0.957821, 0.957907, 0.957992,
        0.958077, 0.958162, 0.958247, 0.958331, 0.958415, 0.958498, 0.958581,
        0.958664, 0.958747, 0.958829, 0.958911, 0.958992, 0.959074, 0.959155,
        0.959235, 0.959316, 0.959396, 0.959475, 0.959555, 0.959634, 0.959713,
        0.959791, 0.95987, 0.959948, 0.960025, 0.960103, 0.96018, 0.960256,
        0.960333, 0.960409, 0.960485, 0.960561, 0.960636, 0.960711, 0.960786,
        0.96086, 0.960935, 0.961009, 0.961082, 0.961156, 0.961229, 0.961302,
        0.961374, 0.961447, 0.961519, 0.961591, 0.961662, 0.961734, 0.961805,
        0.961875, 0.961946, 0.962016, 0.962086, 0.962156, 0.962226, 0.962295,
        0.962364, 0.962433, 0.962501, 0.96257, 0.962638, 0.962706, 0.962773,
        0.962841, 0.962908, 0.962975, 0.963041, 0.963108, 0.963174, 0.96324,
        0.963305, 0.963371, 0.963436, 0.963501, 0.963566, 0.963631, 0.963695,
        0.963759, 0.963823, 0.963887, 0.96395, 0.964013, 0.964077, 0.964139,
        0.964202, 0.964264, 0.964327, 0.964389, 0.96445, 0.964512, 0.964573,
        0.964634, 0.964695, 0.964756, 0.964817, 0.964877, 0.964937, 0.964997,
        0.965057, 0.965116, 0.965176, 0.965235, 0.965294, 0.965352, 0.965411,
        0.965469, 0.965527, 0.965585, 0.965643, 0.965701, 0.965758, 0.965815,
        0.965872, 0.965929, 0.965986, 0.966042, 0.966099, 0.966155, 0.966211,
        0.966266, 0.966322, 0.966377, 0.966433, 0.966488, 0.966543, 0.966597,
        0.966652, 0.966706, 0.96676, 0.966814, 0.966868, 0.966922, 0.966975,
        0.967028, 0.967082, 0.967135, 0.967187, 0.96724, 0.967292, 0.967345,
        0.967397, 0.967449, 0.967501, 0.967552, 0.967604, 0.967655, 0.967706,
        0.967757, 0.967808, 0.967859, 0.967909, 0.96796, 0.96801, 0.96806,
        0.96811, 0.96816, 0.968209, 0.968259, 0.968308, 0.968357, 0.968406,
        0.968455, 0.968504, 0.968553, 0.968601, 0.968649, 0.968697, 0.968745,
        0.968793, 0.968841, 0.968889, 0.968936, 0.968983, 0.96903, 0.969077,
        0.969124, 0.969171, 0.969218, 0.969264, 0.96931, 0.969356, 0.969402,
        0.969448, 0.969494, 0.96954, 0.969585, 0.969631, 0.969676, 0.969721,
        0.969766, 0.969811, 0.969855, 0.9699, 0.969944, 0.969989, 0.970033,
        0.970077, 0.970121, 0.970165, 0.970208, 0.970252, 0.970295, 0.970339,
        0.970382, 0.970425, 0.970468, 0.970511, 0.970553, 0.970596, 0.970638,
        0.970681, 0.970723, 0.970765, 0.970807, 0.970849, 0.97089, 0.970932,
        0.970973, 0.971015, 0.971056, 0.971097, 0.971138, 0.971179, 0.97122,
        0.971261, 0.971301, 0.971342, 0.971382, 0.971422, 0.971462, 0.971502,
        0.971542, 0.971582, 0.971622, 0.971661, 0.971701, 0.97174, 0.97178,
        0.971819, 0.971858, 0.971897, 0.971935, 0.971974, 0.972013, 0.972051,
        0.97209, 0.972128, 0.972166, 0.972204, 0.972242, 0.97228, 0.972318,
        0.972356, 0.972393, 0.972431, 0.972468, 0.972505, 0.972543, 0.97258,
        0.972617, 0.972654, 0.97269, 0.972727, 0.972764, 0.9728, 0.972837,
        0.972873, 0.972909, 0.972945, 0.972981, 0.973017, 0.973053, 0.973089,
        0.973124, 0.97316, 0.973195, 0.973231, 0.973266, 0.973301, 0.973336,
        0.973371, 0.973406, 0.973441, 0.973476, 0.97351, 0.973545, 0.97358,
        0.973614, 0.973648, 0.973682, 0.973717, 0.973751, 0.973784, 0.973818,
        0.973852, 0.973886, 0.973919, 0.973953, 0.973986, 0.97402, 0.974053,
        0.974086, 0.974119, 0.974152, 0.974185, 0.974218, 0.974251, 0.974283,
        0.974316, 0.974348, 0.974381, 0.974413, 0.974446, 0.974478, 0.97451,
        0.974542, 0.974574, 0.974606, 0.974637, 0.974669, 0.974701, 0.974732,
        0.974764, 0.974795, 0.974827, 0.974858, 0.974889, 0.97492, 0.974951,
        0.974982, 0.975013, 0.975044, 0.975074, 0.975105, 0.975136, 0.975166,
        0.975197, 0.975227, 0.975257, 0.975287, 0.975318],
    "4": [0.874192, 0.874424, 0.874661, 0.874901, 0.875146, 0.875395, 0.875647,
        0.875903, 0.876163, 0.876426, 0.876693, 0.876963, 0.877237, 0.877514,
        0.877793, 0.878076, 0.878362, 0.87865, 0.878942, 0.879235, 0.879532,
        0.879831, 0.880132, 0.880436, 0.880741, 0.881049, 0.881359, 0.881671,
        0.881985, 0.882301, 0.882618, 0.882937, 0.883258, 0.88358, 0.883903,
        0.884228, 0.884554, 0.884882, 0.88521, 0.88554, 0.885871, 0.886202,
        0.886535, 0.886868, 0.887202, 0.887536, 0.887872, 0.888208, 0.888544,
        0.888881, 0.889218, 0.889556, 0.889894, 0.890232, 0.890571, 0.890909,
        0.891248, 0.891587, 0.891926, 0.892264, 0.892603, 0.892942, 0.89328,
        0.893619, 0.893957, 0.894294, 0.894632, 0.894969, 0.895306, 0.895642,
        0.895978, 0.896314, 0.896649, 0.896983, 0.897317, 0.897651, 0.897983,
        0.898316, 0.898647, 0.898978, 0.899308, 0.899637, 0.899966, 0.900294,
        0.900621, 0.900947, 0.901272, 0.901597, 0.90192, 0.902243, 0.902565,
        0.902886, 0.903206, 0.903525, 0.903843, 0.90416, 0.904476, 0.904792,
        0.905106, 0.905419, 0.905731, 0.906042, 0.906352, 0.906661, 0.906968,
        0.907275, 0.907581, 0.907885, 0.908188, 0.908491, 0.908792, 0.909092,
        0.909391, 0.909689, 0.909985, 0.910281, 0.910575, 0.910868, 0.91116,
        0.911451, 0.91174, 0.912029, 0.912316, 0.912602, 0.912887, 0.913171,
        0.913453, 0.913735, 0.914015, 0.914294, 0.914572, 0.914848, 0.915124,
        0.915398, 0.915671, 0.915943, 0.916214, 0.916483, 0.916751, 0.917018,
        0.917284, 0.917549, 0.917813, 0.918075, 0.918336, 0.918596, 0.918855,
        0.919113, 0.919369, 0.919625, 0.919879, 0.920132, 0.920384, 0.920634,
        0.920884, 0.921132, 0.92138, 0.921626, 0.921871, 0.922115, 0.922357,
        0.922599, 0.922839, 0.923079, 0.923317, 0.923554, 0.92379, 0.924025,
        0.924258, 0.924491, 0.924723, 0.924953, 0.925182, 0.925411, 0.925638,
        0.925864, 0.926089, 0.926313, 0.926536, 0.926758, 0.926979, 0.927199,
        0.927417, 0.927635, 0.927852, 0.928067, 0.928282, 0.928495, 0.928708,
        0.92892, 0.92913, 0.92934, 0.929548, 0.929756, 0.929962, 0.930168,
        0.930373, 0.930576, 0.930779, 0.930981, 0.931181, 0.931381, 0.93158,
        0.931778, 0.931975, 0.932171, 0.932366, 0.93256, 0.932753, 0.932946,
        0.933137, 0.933327, 0.933517, 0.933706, 0.933894, 0.93408, 0.934267,
        0.934452, 0.934636, 0.934819, 0.935002, 0.935184, 0.935364, 0.935544,
        0.935724, 0.935902, 0.936079, 0.936256, 0.936432, 0.936607, 0.936781,
        0.936954, 0.937127, 0.937298, 0.937469, 0.937639, 0.937809, 0.937977,
        0.938145, 0.938312, 0.938478, 0.938644, 0.938808, 0.938972, 0.939135,
        0.939298, 0.939459, 0.93962, 0.93978, 0.93994, 0.940098, 0.940256,
        0.940413, 0.94057, 0.940726, 0.940881, 0.941035, 0.941189, 0.941342,
        0.941494, 0.941646, 0.941797, 0.941947, 0.942096, 0.942245, 0.942393,
        0.942541, 0.942688, 0.942834, 0.942979, 0.943124, 0.943268, 0.943412,
        0.943555, 0.943697, 0.943839, 0.94398, 0.94412, 0.94426, 0.944399,
        0.944538, 0.944675, 0.944813, 0.944949, 0.945085, 0.945221, 0.945356,
        0.94549, 0.945624, 0.945757, 0.945889, 0.946021, 0.946153, 0.946284,
        0.946414, 0.946544, 0.946673, 0.946801, 0.946929, 0.947057, 0.947183,
        0.94731, 0.947436, 0.947561, 0.947686, 0.94781, 0.947933, 0.948056,
        0.948179, 0.948301, 0.948423, 0.948544, 0.948664, 0.948784, 0.948904,
        0.949023, 0.949141, 0.949259, 0.949377, 0.949494, 0.94961, 0.949726,
        0.949842, 0.949957, 0.950071, 0.950185, 0.950299, 0.950412, 0.950525,
        0.950637, 0.950748, 0.95086, 0.950971, 0.951081, 0.951191, 0.9513,
        0.951409, 0.951518, 0.951626, 0.951733, 0.951841, 0.951947, 0.952054,
        0.95216, 0.952265, 0.95237, 0.952475, 0.952579, 0.952683, 0.952786,
        0.952889, 0.952992, 0.953094, 0.953195, 0.953297, 0.953398, 0.953498,
        0.953598, 0.953698, 0.953797, 0.953896, 0.953995, 0.954093, 0.954191,
        0.954288, 0.954385, 0.954481, 0.954578, 0.954674, 0.954769, 0.954864,
        0.954959, 0.955053, 0.955147, 0.955241, 0.955334, 0.955427, 0.955519,
        0.955612, 0.955703, 0.955795, 0.955886, 0.955977, 0.956067, 0.956157,
        0.956247, 0.956336, 0.956425, 0.956514, 0.956602, 0.95669, 0.956778,
        0.956865, 0.956952, 0.957039, 0.957126, 0.957212, 0.957297, 0.957383,
        0.957468, 0.957553, 0.957637, 0.957721, 0.957805, 0.957888, 0.957972,
        0.958055, 0.958137, 0.958219, 0.958301, 0.958383, 0.958464, 0.958546,
        0.958626, 0.958707, 0.958787, 0.958867, 0.958947, 0.959026, 0.959105,
        0.959184, 0.959262, 0.95934, 0.959418, 0.959496, 0.959573, 0.95965,
        0.959727, 0.959803, 0.95988, 0.959956, 0.960031, 0.960107, 0.960182,
        0.960257, 0.960331, 0.960406, 0.96048, 0.960553, 0.960627, 0.9607,
        0.960773, 0.960846, 0.960919, 0.960991, 0.961063, 0.961135, 0.961206,
        0.961277, 0.961349, 0.961419, 0.96149, 0.96156, 0.96163, 0.9617,
        0.961769, 0.961839, 0.961908, 0.961977, 0.962045, 0.962114, 0.962182,
        0.96225, 0.962317, 0.962385, 0.962452, 0.962519, 0.962586, 0.962652,
        0.962719, 0.962785, 0.962851, 0.962916, 0.962982, 0.963047, 0.963112,
        0.963177, 0.963241, 0.963305, 0.96337, 0.963433, 0.963497, 0.963561,
        0.963624, 0.963687, 0.96375, 0.963812, 0.963875, 0.963937, 0.963999,
        0.964061, 0.964123, 0.964184, 0.964245, 0.964306, 0.964367, 0.964428,
        0.964488, 0.964548, 0.964608, 0.964668, 0.964728, 0.964787, 0.964847,
        0.964906, 0.964964, 0.965023, 0.965082, 0.96514, 0.965198, 0.965256,
        0.965314, 0.965371, 0.965429, 0.965486, 0.965543, 0.9656, 0.965657,
        0.965713, 0.965769, 0.965825, 0.965881, 0.965937, 0.965993, 0.966048,
        0.966103, 0.966159, 0.966213, 0.966268, 0.966323, 0.966377, 0.966431,
        0.966486, 0.966539, 0.966593, 0.966647, 0.9667, 0.966753, 0.966806,
        0.966859, 0.966912, 0.966965, 0.967017, 0.967069, 0.967121, 0.967173,
        0.967225, 0.967277, 0.967328, 0.96738, 0.967431, 0.967482, 0.967533,
        0.967583, 0.967634, 0.967684, 0.967735, 0.967785, 0.967835, 0.967884,
        0.967934, 0.967984, 0.968033, 0.968082, 0.968131, 0.96818, 0.968229,
        0.968278, 0.968326, 0.968374, 0.968423, 0.968471, 0.968519, 0.968566,
        0.968614, 0.968662, 0.968709, 0.968756, 0.968803, 0.96885, 0.968897,
        0.968944, 0.96899, 0.969037, 0.969083, 0.969129, 0.969175, 0.969221,
        0.969267, 0.969312, 0.969358, 0.969403, 0.969448, 0.969493, 0.969538,
        0.969583, 0.969628, 0.969672, 0.969717, 0.969761, 0.969805, 0.969849,
        0.969893, 0.969937, 0.969981, 0.970025, 0.970068, 0.970111, 0.970155,
        0.970198, 0.970241, 0.970283, 0.970326, 0.970369, 0.970411, 0.970454,
        0.970496, 0.970538, 0.97058, 0.970622, 0.970664, 0.970705, 0.970747,
        0.970788, 0.97083, 0.970871, 0.970912, 0.970953, 0.970994, 0.971035,
        0.971075, 0.971116, 0.971156, 0.971197, 0.971237, 0.971277, 0.971317,
        0.971357, 0.971397, 0.971437, 0.971476, 0.971516, 0.971555, 0.971594,
        0.971633, 0.971672, 0.971711, 0.97175, 0.971789, 0.971828, 0.971866,
        0.971904, 0.971943, 0.971981, 0.972019, 0.972057, 0.972095, 0.972133,
        0.972171, 0.972208, 0.972246, 0.972283, 0.972321, 0.972358, 0.972395,
        0.972432, 0.972469, 0.972506, 0.972542, 0.972579, 0.972616, 0.972652,
        0.972688, 0.972725, 0.972761, 0.972797, 0.972833, 0.972869, 0.972905,
        0.97294, 0.972976, 0.973012, 0.973047, 0.973082, 0.973118, 0.973153,
        0.973188, 0.973223, 0.973258, 0.973293, 0.973327, 0.973362, 0.973396,
        0.973431, 0.973465, 0.9735, 0.973534, 0.973568, 0.973602, 0.973636,
        0.97367, 0.973704, 0.973737, 0.973771, 0.973804, 0.973838, 0.973871,
        0.973904, 0.973938, 0.973971, 0.974004, 0.974037, 0.97407, 0.974102,
        0.974135, 0.974168, 0.9742, 0.974233, 0.974265, 0.974297, 0.97433,
        0.974362, 0.974394, 0.974426, 0.974458, 0.974489, 0.974521, 0.974553,
        0.974585, 0.974616, 0.974647, 0.974679, 0.97471, 0.974741, 0.974772,
        0.974804, 0.974835, 0.974865, 0.974896, 0.974927, 0.974958, 0.974988,
        0.975019, 0.975049, 0.97508, 0.97511, 0.97514, 0.975171, 0.975201,
        0.975231, 0.975261, 0.975291, 0.97532, 0.97535, 0.97538, 0.97541,
        0.975439, 0.975469, 0.975498, 0.975527, 0.975557],
    "5": [0.901107, 0.901216, 0.901328, 0.901442, 0.901558, 0.901676, 0.901796,
        0.901919, 0.902043, 0.90217, 0.902299, 0.90243, 0.902562, 0.902697,
        0.902834, 0.902972, 0.903113, 0.903255, 0.903399, 0.903545, 0.903693,
        0.903842, 0.903993, 0.904146, 0.9043, 0.904456, 0.904614, 0.904773,
        0.904933, 0.905095, 0.905258, 0.905423, 0.905589, 0.905757, 0.905926,
        0.906096, 0.906267, 0.90644, 0.906614, 0.906789, 0.906965, 0.907142,
        0.90732, 0.9075, 0.90768, 0.907861, 0.908044, 0.908227, 0.908411,
        0.908596, 0.908782, 0.908969, 0.909156, 0.909345, 0.909534, 0.909724,
        0.909914, 0.910105, 0.910297, 0.910489, 0.910682, 0.910876, 0.91107,
        0.911265, 0.91146, 0.911656, 0.911852, 0.912048, 0.912245, 0.912443,
        0.91264, 0.912838, 0.913037, 0.913236, 0.913435, 0.913634, 0.913833,
        0.914033, 0.914233, 0.914433, 0.914634, 0.914834, 0.915035, 0.915236,
        0.915437, 0.915637, 0.915839, 0.91604, 0.916241, 0.916442, 0.916643,
        0.916844, 0.917045, 0.917246, 0.917447, 0.917648, 0.917849, 0.91805,
        0.918251, 0.918452, 0.918652, 0.918852, 0.919052, 0.919253, 0.919452,
        0.919652, 0.919851, 0.920051, 0.92025, 0.920448, 0.920647, 0.920845,
        0.921043, 0.921241, 0.921439, 0.921636, 0.921833, 0.922029, 0.922226,
        0.922422, 0.922617, 0.922812, 0.923007, 0.923202, 0.923396, 0.92359,
        0.923784, 0.923977, 0.924169, 0.924362, 0.924554, 0.924745, 0.924936,
        0.925127, 0.925317, 0.925507, 0.925697, 0.925886, 0.926074, 0.926262,
        0.92645, 0.926637, 0.926824, 0.92701, 0.927196, 0.927381, 0.927566,
        0.92775, 0.927934, 0.928117, 0.9283, 0.928483, 0.928665, 0.928846,
        0.929027, 0.929207, 0.929387, 0.929567, 0.929745, 0.929924, 0.930102,
        0.930279, 0.930456, 0.930632, 0.930808, 0.930983, 0.931158, 0.931332,
        0.931506, 0.931679, 0.931851, 0.932023, 0.932195, 0.932366, 0.932536,
        0.932706, 0.932875, 0.933044, 0.933213, 0.93338, 0.933548, 0.933714,
        0.93388, 0.934046, 0.934211, 0.934375, 0.934539, 0.934703, 0.934866,
        0.935028, 0.93519, 0.935351, 0.935512, 0.935672, 0.935831, 0.93599,
        0.936149, 0.936307, 0.936464, 0.936621, 0.936778, 0.936934, 0.937089,
        0.937244, 0.937398, 0.937551, 0.937705, 0.937857, 0.938009, 0.938161,
        0.938312, 0.938462, 0.938612, 0.938762, 0.93891, 0.939059, 0.939207,
        0.939354, 0.939501, 0.939647, 0.939793, 0.939938, 0.940083, 0.940227,
        0.94037, 0.940514, 0.940656, 0.940798, 0.94094, 0.941081, 0.941222,
        0.941362, 0.941501, 0.94164, 0.941779, 0.941917, 0.942054, 0.942191,
        0.942328, 0.942464, 0.9426, 0.942735, 0.942869, 0.943003, 0.943137,
        0.94327, 0.943403, 0.943535, 0.943666, 0.943797, 0.943928, 0.944058,
        0.944188, 0.944317, 0.944446, 0.944574, 0.944702, 0.94483, 0.944956,
        0.945083, 0.945209, 0.945334, 0.945459, 0.945584, 0.945708, 0.945832,
        0.945955, 0.946078, 0.9462, 0.946322, 0.946443, 0.946564, 0.946685,
        0.946805, 0.946925, 0.947044, 0.947162, 0.947281, 0.947399, 0.947516,
        0.947633, 0.94775, 0.947866, 0.947982, 0.948097, 0.948212, 0.948326,
        0.94844, 0.948554, 0.948667, 0.94878, 0.948892, 0.949004, 0.949116,
        0.949227, 0.949338, 0.949448, 0.949558, 0.949668, 0.949777, 0.949886,
        0.949994, 0.950102, 0.950209, 0.950317, 0.950423, 0.95053, 0.950636,
        0.950741, 0.950847, 0.950952, 0.951056, 0.95116, 0.951264, 0.951367,
        0.95147, 0.951573, 0.951675, 0.951777, 0.951878, 0.95198, 0.95208,
        0.952181, 0.952281, 0.95238, 0.95248, 0.952579, 0.952677, 0.952776,
        0.952874, 0.952971, 0.953068, 0.953165, 0.953262, 0.953358, 0.953454,
        0.953549, 0.953644, 0.953739, 0.953834, 0.953928, 0.954021, 0.954115,
        0.954208, 0.954301, 0.954393, 0.954486, 0.954577, 0.954669, 0.95476,
        0.954851, 0.954941, 0.955032, 0.955122, 0.955211, 0.9553, 0.955389,
        0.955478, 0.955566, 0.955654, 0.955742, 0.95583, 0.955917, 0.956003,
        0.95609, 0.956176, 0.956262, 0.956348, 0.956433, 0.956518, 0.956603,
        0.956687, 0.956771, 0.956855, 0.956939, 0.957022, 0.957105, 0.957188,
        0.95727, 0.957352, 0.957434, 0.957516, 0.957597, 0.957678, 0.957759,
        0.957839, 0.957919, 0.957999, 0.958079, 0.958158, 0.958237, 0.958316,
        0.958395, 0.958473, 0.958551, 0.958629, 0.958706, 0.958784, 0.958861,
        0.958937, 0.959014, 0.95909, 0.959166, 0.959242, 0.959317, 0.959392,
        0.959467, 0.959542, 0.959616, 0.959691, 0.959765, 0.959838, 0.959912,
        0.959985, 0.960058, 0.960131, 0.960203, 0.960276, 0.960348, 0.960419,
        0.960491, 0.960562, 0.960633, 0.960704, 0.960775, 0.960845, 0.960915,
        0.960985, 0.961055, 0.961124, 0.961194, 0.961263, 0.961331, 0.9614,
        0.961468, 0.961537, 0.961604, 0.961672, 0.96174, 0.961807, 0.961874,
        0.961941, 0.962007, 0.962074, 0.96214, 0.962206, 0.962272, 0.962337,
        0.962403, 0.962468, 0.962533, 0.962597, 0.962662, 0.962726, 0.96279,
        0.962854, 0.962918, 0.962981, 0.963045, 0.963108, 0.963171, 0.963234,
        0.963296, 0.963358, 0.96342, 0.963482, 0.963544, 0.963606, 0.963667,
        0.963728, 0.963789, 0.96385, 0.963911, 0.963971, 0.964031, 0.964091,
        0.964151, 0.964211, 0.96427, 0.964329, 0.964389, 0.964448, 0.964506,
        0.964565, 0.964623, 0.964681, 0.964739, 0.964797, 0.964855, 0.964913,
        0.96497, 0.965027, 0.965084, 0.965141, 0.965197, 0.965254, 0.96531,
        0.965366, 0.965422, 0.965478, 0.965534, 0.965589, 0.965644, 0.9657,
        0.965755, 0.965809, 0.965864, 0.965918, 0.965973, 0.966027, 0.966081,
        0.966135, 0.966188, 0.966242, 0.966295, 0.966348, 0.966401, 0.966454,
        0.966507, 0.96656, 0.966612, 0.966664, 0.966716, 0.966768, 0.96682,
        0.966872, 0.966923, 0.966975, 0.967026, 0.967077, 0.967128, 0.967179,
        0.967229, 0.96728, 0.96733, 0.96738, 0.96743, 0.96748, 0.96753,
        0.967579, 0.967629, 0.967678, 0.967727, 0.967776, 0.967825, 0.967874,
        0.967923, 0.967971, 0.968019, 0.968068, 0.968116, 0.968164, 0.968211,
        0.968259, 0.968306, 0.968354, 0.968401, 0.968448, 0.968495, 0.968542,
        0.968589, 0.968635, 0.968682, 0.968728, 0.968774, 0.96882, 0.968866,
        0.968912, 0.968958, 0.969003, 0.969049, 0.969094, 0.969139, 0.969184,
        0.969229, 0.969274, 0.969319, 0.969363, 0.969408, 0.969452, 0.969496,
        0.969541, 0.969585, 0.969628, 0.969672, 0.969716, 0.969759, 0.969803,
        0.969846, 0.969889, 0.969932, 0.969975, 0.970018, 0.97006, 0.970103,
        0.970145, 0.970188, 0.97023, 0.970272, 0.970314, 0.970356, 0.970397,
        0.970439, 0.970481, 0.970522, 0.970563, 0.970605, 0.970646, 0.970687,
        0.970727, 0.970768, 0.970809, 0.970849, 0.97089, 0.97093, 0.97097,
        0.971011, 0.971051, 0.97109, 0.97113, 0.97117, 0.97121, 0.971249,
        0.971288, 0.971328, 0.971367, 0.971406, 0.971445, 0.971484, 0.971523,
        0.971561, 0.9716, 0.971638, 0.971677, 0.971715, 0.971753, 0.971791,
        0.971829, 0.971867, 0.971905, 0.971943, 0.97198, 0.972018, 0.972055,
        0.972093, 0.97213, 0.972167, 0.972204, 0.972241, 0.972278, 0.972314,
        0.972351, 0.972388, 0.972424, 0.97246, 0.972497, 0.972533, 0.972569,
        0.972605, 0.972641, 0.972677, 0.972712, 0.972748, 0.972784, 0.972819,
        0.972855, 0.97289, 0.972925, 0.97296, 0.972995, 0.97303, 0.973065,
        0.9731, 0.973134, 0.973169, 0.973204, 0.973238, 0.973272, 0.973307,
        0.973341, 0.973375, 0.973409, 0.973443, 0.973477, 0.97351, 0.973544,
        0.973578, 0.973611, 0.973645, 0.973678, 0.973711, 0.973744, 0.973778,
        0.973811, 0.973844, 0.973876, 0.973909, 0.973942, 0.973975, 0.974007,
        0.97404, 0.974072, 0.974104, 0.974136, 0.974169, 0.974201, 0.974233,
        0.974265, 0.974297, 0.974328, 0.97436, 0.974392, 0.974423, 0.974455,
        0.974486, 0.974517, 0.974549, 0.97458, 0.974611, 0.974642, 0.974673,
        0.974704, 0.974735, 0.974765, 0.974796, 0.974827, 0.974857, 0.974888,
        0.974918, 0.974948, 0.974979, 0.975009, 0.975039, 0.975069, 0.975099,
        0.975129, 0.975159, 0.975188, 0.975218, 0.975248, 0.975277, 0.975307,
        0.975336, 0.975366, 0.975395, 0.975424, 0.975453, 0.975482, 0.975511,
        0.97554, 0.975569, 0.975598, 0.975627, 0.975655, 0.975684, 0.975713,
        0.975741, 0.975769, 0.975798, 0.975826, 0.975854],
    "6": [0.918631, 0.918691, 0.918752, 0.918814, 0.918878, 0.918943, 0.919009,
        0.919076, 0.919145, 0.919215, 0.919286, 0.919358, 0.919432, 0.919507,
        0.919583, 0.91966, 0.919738, 0.919818, 0.919898, 0.91998, 0.920063,
        0.920147, 0.920232, 0.920318, 0.920406, 0.920494, 0.920583, 0.920674,
        0.920765, 0.920858, 0.920951, 0.921045, 0.921141, 0.921237, 0.921334,
        0.921432, 0.921531, 0.921631, 0.921732, 0.921834, 0.921936, 0.92204,
        0.922144, 0.922249, 0.922355, 0.922462, 0.922569, 0.922678, 0.922787,
        0.922896, 0.923007, 0.923118, 0.92323, 0.923342, 0.923456, 0.92357,
        0.923684, 0.923799, 0.923915, 0.924032, 0.924149, 0.924266, 0.924385,
        0.924503, 0.924623, 0.924743, 0.924863, 0.924984, 0.925105, 0.925227,
        0.92535, 0.925472, 0.925596, 0.92572, 0.925844, 0.925968, 0.926093,
        0.926219, 0.926345, 0.926471, 0.926597, 0.926724, 0.926852, 0.926979,
        0.927107, 0.927235, 0.927364, 0.927493, 0.927622, 0.927751, 0.927881,
        0.928011, 0.928141, 0.928271, 0.928402, 0.928533, 0.928664, 0.928795,
        0.928926, 0.929058, 0.92919, 0.929322, 0.929454, 0.929586, 0.929718,
        0.929851, 0.929983, 0.930116, 0.930249, 0.930382, 0.930515, 0.930648,
        0.930781, 0.930914, 0.931048, 0.931181, 0.931314, 0.931448, 0.931581,
        0.931715, 0.931848, 0.931982, 0.932115, 0.932248, 0.932382, 0.932515,
        0.932649, 0.932782, 0.932916, 0.933049, 0.933182, 0.933315, 0.933449,
        0.933582, 0.933715, 0.933848, 0.933981, 0.934113, 0.934246, 0.934379,
        0.934511, 0.934644, 0.934776, 0.934908, 0.93504, 0.935172, 0.935304,
        0.935435, 0.935567, 0.935698, 0.93583, 0.935961, 0.936092, 0.936222,
        0.936353, 0.936483, 0.936614, 0.936744, 0.936874, 0.937004, 0.937133,
        0.937263, 0.937392, 0.937521, 0.93765, 0.937778, 0.937907, 0.938035,
        0.938163, 0.938291, 0.938418, 0.938546, 0.938673, 0.9388, 0.938926,
        0.939053, 0.939179, 0.939305, 0.939431, 0.939556, 0.939682, 0.939807,
        0.939932, 0.940056, 0.940181, 0.940305, 0.940429, 0.940552, 0.940676,
        0.940799, 0.940921, 0.941044, 0.941166, 0.941288, 0.94141, 0.941532,
        0.941653, 0.941774, 0.941895, 0.942015, 0.942135, 0.942255, 0.942375,
        0.942494, 0.942614, 0.942732, 0.942851, 0.942969, 0.943087, 0.943205,
        0.943322, 0.94344, 0.943556, 0.943673, 0.943789, 0.943905, 0.944021,
        0.944137, 0.944252, 0.944367, 0.944481, 0.944596, 0.94471, 0.944823,
        0.944937, 0.94505, 0.945163, 0.945275, 0.945388, 0.9455, 0.945611,
        0.945723, 0.945834, 0.945945, 0.946055, 0.946165, 0.946275, 0.946385,
        0.946494, 0.946604, 0.946712, 0.946821, 0.946929, 0.947037, 0.947144,
        0.947252, 0.947359, 0.947466, 0.947572, 0.947678, 0.947784, 0.94789,
        0.947995, 0.9481, 0.948204, 0.948309, 0.948413, 0.948517, 0.94862,
        0.948723, 0.948826, 0.948929, 0.949031, 0.949133, 0.949235, 0.949337,
        0.949438, 0.949539, 0.949639, 0.94974, 0.94984, 0.94994, 0.950039,
        0.950138, 0.950237, 0.950336, 0.950434, 0.950532, 0.95063, 0.950727,
        0.950824, 0.950921, 0.951018, 0.951114, 0.95121, 0.951306, 0.951402,
        0.951497, 0.951592, 0.951686, 0.951781, 0.951875, 0.951969, 0.952062,
        0.952156, 0.952249, 0.952341, 0.952434, 0.952526, 0.952618, 0.95271,
        0.952801, 0.952892, 0.952983, 0.953073, 0.953164, 0.953254, 0.953343,
        0.953433, 0.953522, 0.953611, 0.9537, 0.953788, 0.953876, 0.953964,
        0.954052, 0.954139, 0.954226, 0.954313, 0.9544, 0.954486, 0.954572,
        0.954658, 0.954744, 0.954829, 0.954914, 0.954999, 0.955083, 0.955168,
        0.955252, 0.955335, 0.955419, 0.955502, 0.955585, 0.955668, 0.955751,
        0.955833, 0.955915, 0.955997, 0.956078, 0.95616, 0.956241, 0.956322,
        0.956402, 0.956482, 0.956563, 0.956642, 0.956722, 0.956801, 0.956881,
        0.956959, 0.957038, 0.957117, 0.957195, 0.957273, 0.95735, 0.957428,
        0.957505, 0.957582, 0.957659, 0.957736, 0.957812, 0.957888, 0.957964,
        0.95804, 0.958115, 0.95819, 0.958265, 0.95834, 0.958415, 0.958489,
        0.958563, 0.958637, 0.95871, 0.958784, 0.958857, 0.95893, 0.959003,
        0.959075, 0.959148, 0.95922, 0.959292, 0.959364, 0.959435, 0.959506,
        0.959577, 0.959648, 0.959719, 0.959789, 0.95986, 0.95993, 0.959999,
        0.960069, 0.960138, 0.960208, 0.960277, 0.960345, 0.960414, 0.960482,
        0.960551, 0.960619, 0.960686, 0.960754, 0.960821, 0.960888, 0.960955,
        0.961022, 0.961089, 0.961155, 0.961221, 0.961287, 0.961353, 0.961419,
        0.961484, 0.96155, 0.961615, 0.961679, 0.961744, 0.961809, 0.961873,
        0.961937, 0.962001, 0.962065, 0.962128, 0.962191, 0.962255, 0.962318,
        0.96238, 0.962443, 0.962506, 0.962568, 0.96263, 0.962692, 0.962754,
        0.962815, 0.962876, 0.962938, 0.962999, 0.963059, 0.96312, 0.963181,
        0.963241, 0.963301, 0.963361, 0.963421, 0.963481, 0.96354, 0.963599,
        0.963658, 0.963717, 0.963776, 0.963835, 0.963893, 0.963951, 0.96401,
        0.964067, 0.964125, 0.964183, 0.96424, 0.964298, 0.964355, 0.964412,
        0.964469, 0.964525, 0.964582, 0.964638, 0.964694, 0.96475, 0.964806,
        0.964862, 0.964917, 0.964973, 0.965028, 0.965083, 0.965138, 0.965193,
        0.965247, 0.965302, 0.965356, 0.96541, 0.965464, 0.965518, 0.965572,
        0.965625, 0.965679, 0.965732, 0.965785, 0.965838, 0.965891, 0.965943,
        0.965996, 0.966048, 0.9661, 0.966153, 0.966204, 0.966256, 0.966308,
        0.966359, 0.966411, 0.966462, 0.966513, 0.966564, 0.966615, 0.966666,
        0.966716, 0.966766, 0.966817, 0.966867, 0.966917, 0.966967, 0.967016,
        0.967066, 0.967115, 0.967165, 0.967214, 0.967263, 0.967312, 0.967361,
        0.967409, 0.967458, 0.967506, 0.967554, 0.967602, 0.96765, 0.967698,
        0.967746, 0.967794, 0.967841, 0.967888, 0.967936, 0.967983, 0.96803,
        0.968077, 0.968123, 0.96817, 0.968216, 0.968263, 0.968309, 0.968355,
        0.968401, 0.968447, 0.968493, 0.968538, 0.968584, 0.968629, 0.968674,
        0.968719, 0.968764, 0.968809, 0.968854, 0.968899, 0.968943, 0.968988,
        0.969032, 0.969076, 0.96912, 0.969164, 0.969208, 0.969252, 0.969295,
        0.969339, 0.969382, 0.969426, 0.969469, 0.969512, 0.969555, 0.969598,
        0.96964, 0.969683, 0.969725, 0.969768, 0.96981, 0.969852, 0.969894,
        0.969936, 0.969978, 0.97002, 0.970062, 0.970103, 0.970145, 0.970186,
        0.970227, 0.970268, 0.970309, 0.97035, 0.970391, 0.970432, 0.970472,
        0.970513, 0.970553, 0.970593, 0.970633, 0.970673, 0.970713, 0.970753,
        0.970793, 0.970833, 0.970872, 0.970912, 0.970951, 0.97099, 0.97103,
        0.971069, 0.971108, 0.971147, 0.971185, 0.971224, 0.971263, 0.971301,
        0.97134, 0.971378, 0.971416, 0.971454, 0.971492, 0.97153, 0.971568,
        0.971606, 0.971643, 0.971681, 0.971718, 0.971756, 0.971793, 0.97183,
        0.971867, 0.971904, 0.971941, 0.971978, 0.972015, 0.972051, 0.972088,
        0.972124, 0.972161, 0.972197, 0.972233, 0.972269, 0.972305, 0.972341,
        0.972377, 0.972413, 0.972448, 0.972484, 0.97252, 0.972555, 0.97259,
        0.972626, 0.972661, 0.972696, 0.972731, 0.972766, 0.9728, 0.972835,
        0.97287, 0.972904, 0.972939, 0.972973, 0.973008, 0.973042, 0.973076,
        0.97311, 0.973144, 0.973178, 0.973212, 0.973246, 0.973279, 0.973313,
        0.973346, 0.97338, 0.973413, 0.973447, 0.97348, 0.973513, 0.973546,
        0.973579, 0.973612, 0.973645, 0.973677, 0.97371, 0.973743, 0.973775,
        0.973808, 0.97384, 0.973872, 0.973904, 0.973937, 0.973969, 0.974001,
        0.974033, 0.974064, 0.974096, 0.974128, 0.974159, 0.974191, 0.974222,
        0.974254, 0.974285, 0.974316, 0.974348, 0.974379, 0.97441, 0.974441,
        0.974472, 0.974502, 0.974533, 0.974564, 0.974595, 0.974625, 0.974656,
        0.974686, 0.974716, 0.974747, 0.974777, 0.974807, 0.974837, 0.974867,
        0.974897, 0.974927, 0.974957, 0.974986, 0.975016, 0.975046, 0.975075,
        0.975105, 0.975134, 0.975163, 0.975193, 0.975222, 0.975251, 0.97528,
        0.975309, 0.975338, 0.975367, 0.975396, 0.975424, 0.975453, 0.975482,
        0.97551, 0.975539, 0.975567, 0.975596, 0.975624, 0.975652, 0.97568,
        0.975708, 0.975737, 0.975765, 0.975792, 0.97582, 0.975848, 0.975876,
        0.975904, 0.975931, 0.975959, 0.975986, 0.976014, 0.976041, 0.976068,
        0.976096, 0.976123, 0.97615, 0.976177, 0.976204],
    "7": [0.930915, 0.930951, 0.930988, 0.931025, 0.931064, 0.931103, 0.931143,
        0.931184, 0.931226, 0.931268, 0.931311, 0.931355, 0.9314, 0.931446,
        0.931492, 0.931539, 0.931587, 0.931636, 0.931685, 0.931735, 0.931786,
        0.931838, 0.93189, 0.931943, 0.931997, 0.932051, 0.932106, 0.932162,
        0.932218, 0.932276, 0.932334, 0.932392, 0.932451, 0.932511, 0.932572,
        0.932633, 0.932695, 0.932757, 0.93282, 0.932884, 0.932948, 0.933013,
        0.933079, 0.933145, 0.933211, 0.933279, 0.933346, 0.933415, 0.933484,
        0.933553, 0.933623, 0.933694, 0.933765, 0.933837, 0.933909, 0.933982,
        0.934055, 0.934129, 0.934203, 0.934278, 0.934353, 0.934429, 0.934505,
        0.934582, 0.934659, 0.934736, 0.934814, 0.934893, 0.934971, 0.935051,
        0.93513, 0.935211, 0.935291, 0.935372, 0.935453, 0.935535, 0.935617,
        0.935699, 0.935782, 0.935866, 0.935949, 0.936033, 0.936117, 0.936202,
        0.936287, 0.936372, 0.936457, 0.936543, 0.936629, 0.936716, 0.936803,
        0.93689, 0.936977, 0.937065, 0.937153, 0.937241, 0.937329, 0.937418,
        0.937507, 0.937596, 0.937685, 0.937775, 0.937865, 0.937955, 0.938045,
        0.938136, 0.938226, 0.938317, 0.938408, 0.9385, 0.938591, 0.938683,
        0.938774, 0.938866, 0.938959, 0.939051, 0.939143, 0.939236, 0.939329,
        0.939422, 0.939515, 0.939608, 0.939701, 0.939795, 0.939888, 0.939982,
        0.940076, 0.940169, 0.940263, 0.940357, 0.940451, 0.940546, 0.94064,
        0.940734, 0.940829, 0.940923, 0.941018, 0.941113, 0.941207, 0.941302,
        0.941397, 0.941492, 0.941587, 0.941682, 0.941777, 0.941872, 0.941967,
        0.942062, 0.942157, 0.942252, 0.942347, 0.942442, 0.942537, 0.942632,
        0.942727, 0.942822, 0.942917, 0.943013, 0.943108, 0.943203, 0.943298,
        0.943393, 0.943488, 0.943583, 0.943678, 0.943773, 0.943867, 0.943962,
        0.944057, 0.944152, 0.944246, 0.944341, 0.944436, 0.94453, 0.944625,
        0.944719, 0.944813, 0.944908, 0.945002, 0.945096, 0.94519, 0.945284,
        0.945378, 0.945472, 0.945566, 0.945659, 0.945753, 0.945846, 0.94594,
        0.946033, 0.946126, 0.946219, 0.946312, 0.946405, 0.946498, 0.946591,
        0.946684, 0.946776, 0.946868, 0.946961, 0.947053, 0.947145, 0.947237,
        0.947329, 0.947421, 0.947512, 0.947604, 0.947695, 0.947786, 0.947877,
        0.947968, 0.948059, 0.94815, 0.948241, 0.948331, 0.948421, 0.948511,
        0.948602, 0.948691, 0.948781, 0.948871, 0.94896, 0.94905, 0.949139,
        0.949228, 0.949317, 0.949406, 0.949494, 0.949583, 0.949671, 0.949759,
        0.949847, 0.949935, 0.950023, 0.950111, 0.950198, 0.950285, 0.950373,
        0.950459, 0.950546, 0.950633, 0.950719, 0.950806, 0.950892, 0.950978,
        0.951064, 0.951149, 0.951235, 0.95132, 0.951406, 0.951491, 0.951575,
        0.95166, 0.951745, 0.951829, 0.951913, 0.951997, 0.952081, 0.952165,
        0.952248, 0.952332, 0.952415, 0.952498, 0.952581, 0.952663, 0.952746,
        0.952828, 0.95291, 0.952992, 0.953074, 0.953156, 0.953237, 0.953319,
        0.9534, 0.953481, 0.953562, 0.953642, 0.953723, 0.953803, 0.953883,
        0.953963, 0.954043, 0.954122, 0.954201, 0.954281, 0.95436, 0.954439,
        0.954517, 0.954596, 0.954674, 0.954752, 0.95483, 0.954908, 0.954986,
        0.955063, 0.95514, 0.955217, 0.955294, 0.955371, 0.955448, 0.955524,
        0.9556, 0.955676, 0.955752, 0.955828, 0.955903, 0.955979, 0.956054,
        0.956129, 0.956204, 0.956278, 0.956353, 0.956427, 0.956501, 0.956575,
        0.956649, 0.956722, 0.956796, 0.956869, 0.956942, 0.957015, 0.957088,
        0.95716, 0.957233, 0.957305, 0.957377, 0.957449, 0.95752, 0.957592,
        0.957663, 0.957734, 0.957805, 0.957876, 0.957947, 0.958017, 0.958087,
        0.958157, 0.958227, 0.958297, 0.958367, 0.958436, 0.958505, 0.958575,
        0.958643, 0.958712, 0.958781, 0.958849, 0.958917, 0.958986, 0.959053,
        0.959121, 0.959189, 0.959256, 0.959323, 0.95939, 0.959457, 0.959524,
        0.959591, 0.959657, 0.959723, 0.959789, 0.959855, 0.959921, 0.959987,
        0.960052, 0.960117, 0.960182, 0.960247, 0.960312, 0.960377, 0.960441,
        0.960505, 0.96057, 0.960634, 0.960697, 0.960761, 0.960824, 0.960888,
        0.960951, 0.961014, 0.961077, 0.961139, 0.961202, 0.961264, 0.961327,
        0.961389, 0.96145, 0.961512, 0.961574, 0.961635, 0.961697, 0.961758,
        0.961819, 0.961879, 0.96194, 0.962001, 0.962061, 0.962121, 0.962181,
        0.962241, 0.962301, 0.96236, 0.96242, 0.962479, 0.962538, 0.962597,
        0.962656, 0.962715, 0.962773, 0.962832, 0.96289, 0.962948, 0.963006,
        0.963064, 0.963121, 0.963179, 0.963236, 0.963294, 0.963351, 0.963408,
        0.963464, 0.963521, 0.963578, 0.963634, 0.96369, 0.963746, 0.963802,
        0.963858, 0.963914, 0.963969, 0.964024, 0.96408, 0.964135, 0.96419,
        0.964244, 0.964299, 0.964354, 0.964408, 0.964462, 0.964516, 0.96457,
        0.964624, 0.964678, 0.964732, 0.964785, 0.964838, 0.964892, 0.964945,
        0.964997, 0.96505, 0.965103, 0.965155, 0.965208, 0.96526, 0.965312,
        0.965364, 0.965416, 0.965468, 0.965519, 0.965571, 0.965622, 0.965673,
        0.965724, 0.965775, 0.965826, 0.965877, 0.965927, 0.965978, 0.966028,
        0.966078, 0.966128, 0.966178, 0.966228, 0.966278, 0.966327, 0.966377,
        0.966426, 0.966475, 0.966524, 0.966573, 0.966622, 0.966671, 0.966719,
        0.966768, 0.966816, 0.966864, 0.966912, 0.96696, 0.967008, 0.967056,
        0.967104, 0.967151, 0.967198, 0.967246, 0.967293, 0.96734, 0.967387,
        0.967434, 0.96748, 0.967527, 0.967573, 0.96762, 0.967666, 0.967712,
        0.967758, 0.967804, 0.96785, 0.967895, 0.967941, 0.967986, 0.968032,
        0.968077, 0.968122, 0.968167, 0.968212, 0.968256, 0.968301, 0.968346,
        0.96839, 0.968434, 0.968479, 0.968523, 0.968567, 0.968611, 0.968654,
        0.968698, 0.968742, 0.968785, 0.968829, 0.968872, 0.968915, 0.968958,
        0.969001, 0.969044, 0.969086, 0.969129, 0.969172, 0.969214, 0.969256,
        0.969299, 0.969341, 0.969383, 0.969425, 0.969466, 0.969508, 0.96955,
        0.969591, 0.969633, 0.969674, 0.969715, 0.969756, 0.969797, 0.969838,
        0.969879, 0.96992, 0.96996, 0.970001, 0.970041, 0.970082, 0.970122,
        0.970162, 0.970202, 0.970242, 0.970282, 0.970322, 0.970361, 0.970401,
        0.97044, 0.97048, 0.970519, 0.970558, 0.970597, 0.970636, 0.970675,
        0.970714, 0.970753, 0.970792, 0.97083, 0.970869, 0.970907, 0.970945,
        0.970983, 0.971021, 0.971059, 0.971097, 0.971135, 0.971173, 0.971211,
        0.971248, 0.971286, 0.971323, 0.97136, 0.971398, 0.971435, 0.971472,
        0.971509, 0.971546, 0.971582, 0.971619, 0.971656, 0.971692, 0.971729,
        0.971765, 0.971801, 0.971837, 0.971874, 0.97191, 0.971946, 0.971981,
        0.972017, 0.972053, 0.972088, 0.972124, 0.972159, 0.972195, 0.97223,
        0.972265, 0.9723, 0.972335, 0.97237, 0.972405, 0.97244, 0.972475,
        0.972509, 0.972544, 0.972578, 0.972613, 0.972647, 0.972681, 0.972716,
        0.97275, 0.972784, 0.972818, 0.972852, 0.972885, 0.972919, 0.972953,
        0.972986, 0.97302, 0.973053, 0.973086, 0.97312, 0.973153, 0.973186,
        0.973219, 0.973252, 0.973285, 0.973318, 0.97335, 0.973383, 0.973416,
        0.973448, 0.973481, 0.973513, 0.973545, 0.973577, 0.97361, 0.973642,
        0.973674, 0.973706, 0.973738, 0.973769, 0.973801, 0.973833, 0.973864,
        0.973896, 0.973927, 0.973959, 0.97399, 0.974021, 0.974052, 0.974083,
        0.974114, 0.974145, 0.974176, 0.974207, 0.974238, 0.974269, 0.974299,
        0.97433, 0.97436, 0.974391, 0.974421, 0.974451, 0.974482, 0.974512,
        0.974542, 0.974572, 0.974602, 0.974632, 0.974662, 0.974691, 0.974721,
        0.974751, 0.97478, 0.97481, 0.974839, 0.974869, 0.974898, 0.974927,
        0.974956, 0.974986, 0.975015, 0.975044, 0.975073, 0.975101, 0.97513,
        0.975159, 0.975188, 0.975216, 0.975245, 0.975273, 0.975302, 0.97533,
        0.975359, 0.975387, 0.975415, 0.975443, 0.975471, 0.975499, 0.975527,
        0.975555, 0.975583, 0.975611, 0.975639, 0.975666, 0.975694, 0.975721,
        0.975749, 0.975776, 0.975804, 0.975831, 0.975858, 0.975886, 0.975913,
        0.97594, 0.975967, 0.975994, 0.976021, 0.976048, 0.976075, 0.976101,
        0.976128, 0.976155, 0.976181, 0.976208, 0.976234, 0.976261, 0.976287,
        0.976313, 0.97634, 0.976366, 0.976392, 0.976418, 0.976444, 0.97647,
        0.976496, 0.976522, 0.976548, 0.976574, 0.976599],
    "8": [0.939991, 0.940015, 0.940039, 0.940063, 0.940088, 0.940114, 0.94014,
        0.940166, 0.940193, 0.940221, 0.940249, 0.940278, 0.940307, 0.940337,
        0.940367, 0.940398, 0.940429, 0.940461, 0.940493, 0.940526, 0.940559,
        0.940593, 0.940627, 0.940662, 0.940697, 0.940733, 0.94077, 0.940806,
        0.940843, 0.940881, 0.940919, 0.940958, 0.940997, 0.941037, 0.941077,
        0.941117, 0.941158, 0.941199, 0.941241, 0.941283, 0.941326, 0.941369,
        0.941413, 0.941457, 0.941501, 0.941546, 0.941591, 0.941637, 0.941683,
        0.94173, 0.941777, 0.941824, 0.941872, 0.94192, 0.941968, 0.942017,
        0.942067, 0.942116, 0.942166, 0.942217, 0.942267, 0.942319, 0.94237,
        0.942422, 0.942474, 0.942527, 0.94258, 0.942633, 0.942687, 0.942741,
        0.942795, 0.94285, 0.942904, 0.94296, 0.943015, 0.943071, 0.943127,
        0.943184, 0.943241, 0.943298, 0.943355, 0.943413, 0.943471, 0.943529,
        0.943588, 0.943647, 0.943706, 0.943766, 0.943825, 0.943885, 0.943945,
        0.944006, 0.944067, 0.944128, 0.944189, 0.94425, 0.944312, 0.944374,
        0.944436, 0.944499, 0.944561, 0.944624, 0.944687, 0.944751, 0.944814,
        0.944878, 0.944942, 0.945006, 0.94507, 0.945135, 0.9452, 0.945264,
        0.94533, 0.945395, 0.94546, 0.945526, 0.945592, 0.945658, 0.945724,
        0.94579, 0.945857, 0.945924, 0.94599, 0.946057, 0.946124, 0.946192,
        0.946259, 0.946327, 0.946394, 0.946462, 0.94653, 0.946598, 0.946666,
        0.946735, 0.946803, 0.946872, 0.946941, 0.947009, 0.947078, 0.947147,
        0.947216, 0.947286, 0.947355, 0.947424, 0.947494, 0.947563, 0.947633,
        0.947703, 0.947773, 0.947843, 0.947913, 0.947983, 0.948053, 0.948123,
        0.948193, 0.948264, 0.948334, 0.948404, 0.948475, 0.948546, 0.948616,
        0.948687, 0.948758, 0.948828, 0.948899, 0.94897, 0.949041, 0.949112,
        0.949183, 0.949254, 0.949325, 0.949396, 0.949467, 0.949538, 0.949609,
        0.94968, 0.949751, 0.949823, 0.949894, 0.949965, 0.950036, 0.950107,
        0.950179, 0.95025, 0.950321, 0.950392, 0.950464, 0.950535, 0.950606,
        0.950677, 0.950748, 0.95082, 0.950891, 0.950962, 0.951033, 0.951104,
        0.951175, 0.951246, 0.951318, 0.951389, 0.95146, 0.951531, 0.951602,
        0.951673, 0.951743, 0.951814, 0.951885, 0.951956, 0.952027, 0.952098,
        0.952168, 0.952239, 0.952309, 0.95238, 0.952451, 0.952521, 0.952591,
        0.952662, 0.952732, 0.952802, 0.952873, 0.952943, 0.953013, 0.953083,
        0.953153, 0.953223, 0.953293, 0.953363, 0.953433, 0.953502, 0.953572,
        0.953641, 0.953711, 0.95378, 0.95385, 0.953919, 0.953988, 0.954058,
        0.954127, 0.954196, 0.954265, 0.954333, 0.954402, 0.954471, 0.95454,
        0.954608, 0.954677, 0.954745, 0.954813, 0.954882, 0.95495, 0.955018,
        0.955086, 0.955154, 0.955222, 0.955289, 0.955357, 0.955425, 0.955492,
        0.95556, 0.955627, 0.955694, 0.955761, 0.955828, 0.955895, 0.955962,
        0.956029, 0.956096, 0.956162, 0.956229, 0.956295, 0.956361, 0.956427,
        0.956494, 0.95656, 0.956625, 0.956691, 0.956757, 0.956823, 0.956888,
        0.956954, 0.957019, 0.957084, 0.957149, 0.957214, 0.957279, 0.957344,
        0.957409, 0.957473, 0.957538, 0.957602, 0.957666, 0.95773, 0.957795,
        0.957858, 0.957922, 0.957986, 0.95805, 0.958113, 0.958177, 0.95824,
        0.958303, 0.958366, 0.958429, 0.958492, 0.958555, 0.958618, 0.95868,
        0.958743, 0.958805, 0.958867, 0.958929, 0.958991, 0.959053, 0.959115,
        0.959177, 0.959238, 0.9593, 0.959361, 0.959422, 0.959484, 0.959545,
        0.959605, 0.959666, 0.959727, 0.959787, 0.959848, 0.959908, 0.959968,
        0.960029, 0.960089, 0.960148, 0.960208, 0.960268, 0.960327, 0.960387,
        0.960446, 0.960505, 0.960564, 0.960623, 0.960682, 0.960741, 0.9608,
        0.960858, 0.960917, 0.960975, 0.961033, 0.961091, 0.961149, 0.961207,
        0.961265, 0.961322, 0.96138, 0.961437, 0.961494, 0.961552, 0.961609,
        0.961666, 0.961722, 0.961779, 0.961836, 0.961892, 0.961948, 0.962005,
        0.962061, 0.962117, 0.962173, 0.962228, 0.962284, 0.96234, 0.962395,
        0.96245, 0.962506, 0.962561, 0.962616, 0.962671, 0.962725, 0.96278,
        0.962835, 0.962889, 0.962943, 0.962998, 0.963052, 0.963106, 0.963159,
        0.963213, 0.963267, 0.96332, 0.963374, 0.963427, 0.96348, 0.963533,
        0.963586, 0.963639, 0.963692, 0.963745, 0.963797, 0.963849, 0.963902,
        0.963954, 0.964006, 0.964058, 0.96411, 0.964162, 0.964213, 0.964265,
        0.964316, 0.964367, 0.964419, 0.96447, 0.964521, 0.964572, 0.964622,
        0.964673, 0.964724, 0.964774, 0.964824, 0.964875, 0.964925, 0.964975,
        0.965025, 0.965074, 0.965124, 0.965174, 0.965223, 0.965272, 0.965322,
        0.965371, 0.96542, 0.965469, 0.965518, 0.965566, 0.965615, 0.965663,
        0.965712, 0.96576, 0.965808, 0.965856, 0.965904, 0.965952, 0.966,
        0.966048, 0.966095, 0.966143, 0.96619, 0.966237, 0.966285, 0.966332,
        0.966379, 0.966425, 0.966472, 0.966519, 0.966565, 0.966612, 0.966658,
        0.966704, 0.96675, 0.966797, 0.966842, 0.966888, 0.966934, 0.96698,
        0.967025, 0.967071, 0.967116, 0.967161, 0.967206, 0.967251, 0.967296,
        0.967341, 0.967386, 0.967431, 0.967475, 0.96752, 0.967564, 0.967608,
        0.967652, 0.967696, 0.96774, 0.967784, 0.967828, 0.967872, 0.967915,
        0.967959, 0.968002, 0.968045, 0.968089, 0.968132, 0.968175, 0.968218,
        0.968261, 0.968303, 0.968346, 0.968388, 0.968431, 0.968473, 0.968515,
        0.968558, 0.9686, 0.968642, 0.968684, 0.968725, 0.968767, 0.968809,
        0.96885, 0.968892, 0.968933, 0.968974, 0.969015, 0.969057, 0.969098,
        0.969138, 0.969179, 0.96922, 0.969261, 0.969301, 0.969342, 0.969382,
        0.969422, 0.969462, 0.969502, 0.969542, 0.969582, 0.969622, 0.969662,
        0.969702, 0.969741, 0.969781, 0.96982, 0.969859, 0.969899, 0.969938,
        0.969977, 0.970016, 0.970055, 0.970094, 0.970132, 0.970171, 0.970209,
        0.970248, 0.970286, 0.970325, 0.970363, 0.970401, 0.970439, 0.970477,
        0.970515, 0.970553, 0.97059, 0.970628, 0.970666, 0.970703, 0.97074,
        0.970778, 0.970815, 0.970852, 0.970889, 0.970926, 0.970963, 0.971,
        0.971037, 0.971073, 0.97111, 0.971146, 0.971183, 0.971219, 0.971256,
        0.971292, 0.971328, 0.971364, 0.9714, 0.971436, 0.971472, 0.971507,
        0.971543, 0.971579, 0.971614, 0.97165, 0.971685, 0.97172, 0.971755,
        0.97179, 0.971826, 0.97186, 0.971895, 0.97193, 0.971965, 0.972,
        0.972034, 0.972069, 0.972103, 0.972138, 0.972172, 0.972206, 0.97224,
        0.972274, 0.972308, 0.972342, 0.972376, 0.97241, 0.972444, 0.972477,
        0.972511, 0.972545, 0.972578, 0.972611, 0.972645, 0.972678, 0.972711,
        0.972744, 0.972777, 0.97281, 0.972843, 0.972876, 0.972909, 0.972941,
        0.972974, 0.973006, 0.973039, 0.973071, 0.973104, 0.973136, 0.973168,
        0.9732, 0.973232, 0.973264, 0.973296, 0.973328, 0.97336, 0.973391,
        0.973423, 0.973455, 0.973486, 0.973518, 0.973549, 0.97358, 0.973612,
        0.973643, 0.973674, 0.973705, 0.973736, 0.973767, 0.973798, 0.973829,
        0.973859, 0.97389, 0.973921, 0.973951, 0.973982, 0.974012, 0.974042,
        0.974073, 0.974103, 0.974133, 0.974163, 0.974193, 0.974223, 0.974253,
        0.974283, 0.974313, 0.974343, 0.974372, 0.974402, 0.974432, 0.974461,
        0.97449, 0.97452, 0.974549, 0.974578, 0.974608, 0.974637, 0.974666,
        0.974695, 0.974724, 0.974753, 0.974782, 0.97481, 0.974839, 0.974868,
        0.974896, 0.974925, 0.974953, 0.974982, 0.97501, 0.975038, 0.975067,
        0.975095, 0.975123, 0.975151, 0.975179, 0.975207, 0.975235, 0.975263,
        0.975291, 0.975318, 0.975346, 0.975374, 0.975401, 0.975429, 0.975456,
        0.975484, 0.975511, 0.975538, 0.975566, 0.975593, 0.97562, 0.975647,
        0.975674, 0.975701, 0.975728, 0.975755, 0.975782, 0.975808, 0.975835,
        0.975862, 0.975888, 0.975915, 0.975941, 0.975968, 0.975994, 0.976021,
        0.976047, 0.976073, 0.976099, 0.976125, 0.976151, 0.976177, 0.976203,
        0.976229, 0.976255, 0.976281, 0.976307, 0.976332, 0.976358, 0.976384,
        0.976409, 0.976435, 0.97646, 0.976486, 0.976511, 0.976536, 0.976562,
        0.976587, 0.976612, 0.976637, 0.976662, 0.976687, 0.976712, 0.976737,
        0.976762, 0.976787, 0.976812, 0.976836, 0.976861, 0.976886, 0.97691,
        0.976935, 0.976959, 0.976984, 0.977008, 0.977032],
    "9": [0.946967, 0.946983, 0.947, 0.947016, 0.947034, 0.947051, 0.947069,
        0.947087, 0.947106, 0.947125, 0.947144, 0.947164, 0.947184, 0.947204,
        0.947225, 0.947246, 0.947268, 0.94729, 0.947312, 0.947335, 0.947358,
        0.947381, 0.947404, 0.947429, 0.947453, 0.947478, 0.947503, 0.947528,
        0.947554, 0.94758, 0.947606, 0.947633, 0.94766, 0.947688, 0.947715,
        0.947743, 0.947772, 0.947801, 0.94783, 0.947859, 0.947889, 0.947919,
        0.947949, 0.94798, 0.948011, 0.948042, 0.948074, 0.948106, 0.948138,
        0.94817, 0.948203, 0.948236, 0.94827, 0.948303, 0.948337, 0.948372,
        0.948406, 0.948441, 0.948476, 0.948512, 0.948548, 0.948584, 0.94862,
        0.948656, 0.948693, 0.94873, 0.948768, 0.948805, 0.948843, 0.948881,
        0.94892, 0.948959, 0.948997, 0.949037, 0.949076, 0.949116, 0.949156,
        0.949196, 0.949236, 0.949277, 0.949318, 0.949359, 0.949401, 0.949442,
        0.949484, 0.949526, 0.949569, 0.949611, 0.949654, 0.949697, 0.94974,
        0.949783, 0.949827, 0.949871, 0.949915, 0.949959, 0.950004, 0.950048,
        0.950093, 0.950138, 0.950184, 0.950229, 0.950275, 0.95032, 0.950367,
        0.950413, 0.950459, 0.950506, 0.950552, 0.950599, 0.950646, 0.950694,
        0.950741, 0.950789, 0.950837, 0.950885, 0.950933, 0.950981, 0.951029,
        0.951078, 0.951127, 0.951176, 0.951225, 0.951274, 0.951323, 0.951373,
        0.951422, 0.951472, 0.951522, 0.951572, 0.951622, 0.951673, 0.951723,
        0.951774, 0.951824, 0.951875, 0.951926, 0.951977, 0.952028, 0.95208,
        0.952131, 0.952182, 0.952234, 0.952286, 0.952338, 0.95239, 0.952442,
        0.952494, 0.952546, 0.952598, 0.952651, 0.952703, 0.952756, 0.952809,
        0.952862, 0.952915, 0.952968, 0.953021, 0.953074, 0.953127, 0.95318,
        0.953234, 0.953287, 0.953341, 0.953394, 0.953448, 0.953502, 0.953556,
        0.95361, 0.953664, 0.953718, 0.953772, 0.953826, 0.95388, 0.953934,
        0.953989, 0.954043, 0.954097, 0.954152, 0.954206, 0.954261, 0.954316,
        0.95437, 0.954425, 0.95448, 0.954534, 0.954589, 0.954644, 0.954699,
        0.954754, 0.954809, 0.954864, 0.954919, 0.954974, 0.955029, 0.955084,
        0.955139, 0.955194, 0.955249, 0.955305, 0.95536, 0.955415, 0.95547,
        0.955526, 0.955581, 0.955636, 0.955691, 0.955747, 0.955802, 0.955857,
        0.955913, 0.955968, 0.956023, 0.956079, 0.956134, 0.956189, 0.956245,
        0.9563, 0.956355, 0.956411, 0.956466, 0.956521, 0.956577, 0.956632,
        0.956687, 0.956743, 0.956798, 0.956853, 0.956909, 0.956964, 0.957019,
        0.957074, 0.95713, 0.957185, 0.95724, 0.957295, 0.95735, 0.957405,
        0.95746, 0.957516, 0.957571, 0.957626, 0.957681, 0.957736, 0.957791,
        0.957846, 0.9579, 0.957955, 0.95801, 0.958065, 0.95812, 0.958175,
        0.958229, 0.958284, 0.958339, 0.958393, 0.958448, 0.958502, 0.958557,
        0.958611, 0.958666, 0.95872, 0.958774, 0.958829, 0.958883, 0.958937,
        0.958991, 0.959046, 0.9591, 0.959154, 0.959208, 0.959262, 0.959316,
        0.959369, 0.959423, 0.959477, 0.959531, 0.959584, 0.959638, 0.959692,
        0.959745, 0.959799, 0.959852, 0.959905, 0.959959, 0.960012, 0.960065,
        0.960118, 0.960171, 0.960225, 0.960278, 0.96033, 0.960383, 0.960436,
        0.960489, 0.960542, 0.960594, 0.960647, 0.960699, 0.960752, 0.960804,
        0.960857, 0.960909, 0.960961, 0.961013, 0.961065, 0.961117, 0.961169,
        0.961221, 0.961273, 0.961325, 0.961377, 0.961428, 0.96148, 0.961531,
        0.961583, 0.961634, 0.961685, 0.961737, 0.961788, 0.961839, 0.96189,
        0.961941, 0.961992, 0.962043, 0.962094, 0.962144, 0.962195, 0.962245,
        0.962296, 0.962346, 0.962397, 0.962447, 0.962497, 0.962547, 0.962598,
        0.962648, 0.962697, 0.962747, 0.962797, 0.962847, 0.962896, 0.962946,
        0.962996, 0.963045, 0.963094, 0.963144, 0.963193, 0.963242, 0.963291,
        0.96334, 0.963389, 0.963438, 0.963487, 0.963535, 0.963584, 0.963632,
        0.963681, 0.963729, 0.963778, 0.963826, 0.963874, 0.963922, 0.96397,
        0.964018, 0.964066, 0.964114, 0.964161, 0.964209, 0.964256, 0.964304,
        0.964351, 0.964399, 0.964446, 0.964493, 0.96454, 0.964587, 0.964634,
        0.964681, 0.964728, 0.964774, 0.964821, 0.964868, 0.964914, 0.964961,
        0.965007, 0.965053, 0.965099, 0.965145, 0.965191, 0.965237, 0.965283,
        0.965329, 0.965375, 0.96542, 0.965466, 0.965511, 0.965557, 0.965602,
        0.965647, 0.965692, 0.965737, 0.965782, 0.965827, 0.965872, 0.965917,
        0.965961, 0.966006, 0.966051, 0.966095, 0.966139, 0.966184, 0.966228,
        0.966272, 0.966316, 0.96636, 0.966404, 0.966448, 0.966492, 0.966535,
        0.966579, 0.966622, 0.966666, 0.966709, 0.966752, 0.966796, 0.966839,
        0.966882, 0.966925, 0.966968, 0.96701, 0.967053, 0.967096, 0.967138,
        0.967181, 0.967223, 0.967266, 0.967308, 0.96735, 0.967392, 0.967434,
        0.967476, 0.967518, 0.96756, 0.967602, 0.967643, 0.967685, 0.967726,
        0.967768, 0.967809, 0.96785, 0.967891, 0.967933, 0.967974, 0.968015,
        0.968055, 0.968096, 0.968137, 0.968178, 0.968218, 0.968259, 0.968299,
        0.96834, 0.96838, 0.96842, 0.96846, 0.9685, 0.96854, 0.96858,
        0.96862, 0.96866, 0.968699, 0.968739, 0.968778, 0.968818, 0.968857,
        0.968897, 0.968936, 0.968975, 0.969014, 0.969053, 0.969092, 0.969131,
        0.96917, 0.969208, 0.969247, 0.969285, 0.969324, 0.969362, 0.969401,
        0.969439, 0.969477, 0.969515, 0.969553, 0.969591, 0.969629, 0.969667,
        0.969705, 0.969743, 0.96978, 0.969818, 0.969855, 0.969893, 0.96993,
        0.969967, 0.970004, 0.970042, 0.970079, 0.970116, 0.970152, 0.970189,
        0.970226, 0.970263, 0.970299, 0.970336, 0.970372, 0.970409, 0.970445,
        0.970481, 0.970518, 0.970554, 0.97059, 0.970626, 0.970662, 0.970698,
        0.970733, 0.970769, 0.970805, 0.97084, 0.970876, 0.970911, 0.970947,
        0.970982, 0.971017, 0.971052, 0.971087, 0.971122, 0.971157, 0.971192,
        0.971227, 0.971262, 0.971297, 0.971331, 0.971366, 0.9714, 0.971435,
        0.971469, 0.971503, 0.971538, 0.971572, 0.971606, 0.97164, 0.971674,
        0.971708, 0.971742, 0.971775, 0.971809, 0.971843, 0.971876, 0.97191,
        0.971943, 0.971977, 0.97201, 0.972043, 0.972076, 0.972109, 0.972142,
        0.972175, 0.972208, 0.972241, 0.972274, 0.972307, 0.972339, 0.972372,
        0.972405, 0.972437, 0.97247, 0.972502, 0.972534, 0.972566, 0.972599,
        0.972631, 0.972663, 0.972695, 0.972727, 0.972759, 0.97279, 0.972822,
        0.972854, 0.972885, 0.972917, 0.972948, 0.97298, 0.973011, 0.973043,
        0.973074, 0.973105, 0.973136, 0.973167, 0.973198, 0.973229, 0.97326,
        0.973291, 0.973322, 0.973352, 0.973383, 0.973414, 0.973444, 0.973475,
        0.973505, 0.973535, 0.973566, 0.973596, 0.973626, 0.973656, 0.973686,
        0.973716, 0.973746, 0.973776, 0.973806, 0.973836, 0.973866, 0.973895,
        0.973925, 0.973954, 0.973984, 0.974013, 0.974043, 0.974072, 0.974101,
        0.974131, 0.97416, 0.974189, 0.974218, 0.974247, 0.974276, 0.974305,
        0.974334, 0.974362, 0.974391, 0.97442, 0.974448, 0.974477, 0.974505,
        0.974534, 0.974562, 0.974591, 0.974619, 0.974647, 0.974675, 0.974703,
        0.974732, 0.97476, 0.974788, 0.974815, 0.974843, 0.974871, 0.974899,
        0.974927, 0.974954, 0.974982, 0.975009, 0.975037, 0.975064, 0.975092,
        0.975119, 0.975146, 0.975173, 0.975201, 0.975228, 0.975255, 0.975282,
        0.975309, 0.975336, 0.975363, 0.975389, 0.975416, 0.975443, 0.97547,
        0.975496, 0.975523, 0.975549, 0.975576, 0.975602, 0.975629, 0.975655,
        0.975681, 0.975707, 0.975734, 0.97576, 0.975786, 0.975812, 0.975838,
        0.975864, 0.97589, 0.975915, 0.975941, 0.975967, 0.975993, 0.976018,
        0.976044, 0.976069, 0.976095, 0.97612, 0.976146, 0.976171, 0.976196,
        0.976222, 0.976247, 0.976272, 0.976297, 0.976322, 0.976347, 0.976372,
        0.976397, 0.976422, 0.976447, 0.976472, 0.976497, 0.976521, 0.976546,
        0.97657, 0.976595, 0.97662, 0.976644, 0.976668, 0.976693, 0.976717,
        0.976742, 0.976766, 0.97679, 0.976814, 0.976838, 0.976862, 0.976886,
        0.97691, 0.976934, 0.976958, 0.976982, 0.977006, 0.97703, 0.977053,
        0.977077, 0.977101, 0.977124, 0.977148, 0.977171, 0.977195, 0.977218,
        0.977242, 0.977265, 0.977288, 0.977311, 0.977335, 0.977358, 0.977381,
        0.977404, 0.977427, 0.97745, 0.977473, 0.977496],
    "10": [0.907861, 0.907952, 0.908044, 0.908135, 0.908227, 0.908319,
        0.908411, 0.908504, 0.908596, 0.908689, 0.908782, 0.908875, 0.908969,
        0.909062, 0.909156, 0.90925, 0.909345, 0.909439, 0.909534, 0.909629,
        0.909724, 0.909819, 0.909914, 0.910009, 0.910105, 0.910201, 0.910297,
        0.910393, 0.910489, 0.910586, 0.910682, 0.910779, 0.910876, 0.910973,
        0.91107, 0.911167, 0.911265, 0.911362, 0.91146, 0.911558, 0.911656,
        0.911754, 0.911852, 0.91195, 0.912048, 0.912147, 0.912245, 0.912344,
        0.912443, 0.912541, 0.91264, 0.912739, 0.912838, 0.912938, 0.913037,
        0.913136, 0.913236, 0.913335, 0.913435, 0.913534, 0.913634, 0.913734,
        0.913833, 0.913933, 0.914033, 0.914133, 0.914233, 0.914333, 0.914433,
        0.914534, 0.914634, 0.914734, 0.914834, 0.914935, 0.915035, 0.915135,
        0.915236, 0.915336, 0.915437, 0.915537, 0.915637, 0.915738, 0.915839,
        0.915939, 0.91604, 0.91614, 0.916241, 0.916341, 0.916442, 0.916542,
        0.916643, 0.916744, 0.916844, 0.916945, 0.917045, 0.917146, 0.917246,
        0.917347, 0.917447, 0.917548, 0.917648, 0.917749, 0.917849, 0.91795,
        0.91805, 0.918151, 0.918251, 0.918351, 0.918452, 0.918552, 0.918652,
        0.918752, 0.918852, 0.918952, 0.919052, 0.919153, 0.919253, 0.919352,
        0.919452, 0.919552, 0.919652, 0.919752, 0.919851, 0.919951, 0.920051,
        0.92015, 0.92025, 0.920349, 0.920448, 0.920548, 0.920647, 0.920746,
        0.920845, 0.920944, 0.921043, 0.921142, 0.921241, 0.92134, 0.921439,
        0.921537, 0.921636, 0.921734, 0.921833, 0.921931, 0.922029, 0.922128,
        0.922226, 0.922324, 0.922422, 0.922519, 0.922617, 0.922715, 0.922812,
        0.92291, 0.923007, 0.923105, 0.923202, 0.923299, 0.923396, 0.923493,
        0.92359, 0.923687, 0.923784, 0.92388, 0.923977, 0.924073, 0.924169,
        0.924266, 0.924362, 0.924458, 0.924554, 0.92465, 0.924745, 0.924841,
        0.924936, 0.925032, 0.925127, 0.925222, 0.925317, 0.925412, 0.925507,
        0.925602, 0.925697, 0.925791, 0.925886, 0.92598, 0.926074, 0.926168,
        0.926262, 0.926356, 0.92645, 0.926543, 0.926637, 0.92673, 0.926824,
        0.926917, 0.92701, 0.927103, 0.927196, 0.927288, 0.927381, 0.927473,
        0.927566, 0.927658, 0.92775, 0.927842, 0.927934, 0.928026, 0.928117,
        0.928209, 0.9283, 0.928392, 0.928483, 0.928574, 0.928665, 0.928755,
        0.928846, 0.928937, 0.929027, 0.929117, 0.929207, 0.929297, 0.929387,
        0.929477, 0.929567, 0.929656, 0.929745, 0.929835, 0.929924, 0.930013,
        0.930102, 0.93019, 0.930279, 0.930367, 0.930456, 0.930544, 0.930632,
        0.93072, 0.930808, 0.930896, 0.930983, 0.93107, 0.931158, 0.931245,
        0.931332, 0.931419, 0.931506, 0.931592, 0.931679, 0.931765, 0.931851,
        0.931937, 0.932023, 0.932109, 0.932195, 0.93228, 0.932366, 0.932451,
        0.932536, 0.932621, 0.932706, 0.932791, 0.932875, 0.93296, 0.933044,
        0.933129, 0.933213, 0.933297, 0.93338, 0.933464, 0.933548, 0.933631,
        0.933714, 0.933797, 0.93388, 0.933963, 0.934046, 0.934128, 0.934211,
        0.934293, 0.934375, 0.934457, 0.934539, 0.934621, 0.934703, 0.934784,
        0.934866, 0.934947, 0.935028, 0.935109, 0.93519, 0.93527, 0.935351,
        0.935431, 0.935512, 0.935592, 0.935672, 0.935752, 0.935831, 0.935911,
        0.93599, 0.93607, 0.936149, 0.936228, 0.936307, 0.936386, 0.936464,
        0.936543, 0.936621, 0.9367, 0.936778, 0.936856, 0.936934, 0.937011,
        0.937089, 0.937166, 0.937244, 0.937321, 0.937398, 0.937475, 0.937551,
        0.937628, 0.937705, 0.937781, 0.937857, 0.937933, 0.938009, 0.938085,
        0.938161, 0.938236, 0.938312, 0.938387, 0.938462, 0.938537, 0.938612,
        0.938687, 0.938762, 0.938836, 0.93891, 0.938985, 0.939059, 0.939133,
        0.939207, 0.93928, 0.939354, 0.939427, 0.939501, 0.939574, 0.939647,
        0.93972, 0.939793, 0.939865, 0.939938, 0.94001, 0.940083, 0.940155,
        0.940227, 0.940299, 0.94037, 0.940442, 0.940514, 0.940585, 0.940656,
        0.940727, 0.940798, 0.940869, 0.94094, 0.941011, 0.941081, 0.941151,
        0.941222, 0.941292, 0.941362, 0.941431, 0.941501, 0.941571, 0.94164,
        0.94171, 0.941779, 0.941848, 0.941917, 0.941986, 0.942054, 0.942123,
        0.942191, 0.94226, 0.942328, 0.942396, 0.942464, 0.942532, 0.9426,
        0.942667, 0.942735, 0.942802, 0.942869, 0.942936, 0.943003, 0.94307,
        0.943137, 0.943203, 0.94327, 0.943336, 0.943403, 0.943469, 0.943535,
        0.943601, 0.943666, 0.943732, 0.943797, 0.943863, 0.943928, 0.943993,
        0.944058, 0.944123, 0.944188, 0.944253, 0.944317, 0.944382, 0.944446,
        0.94451, 0.944574, 0.944638, 0.944702, 0.944766, 0.94483, 0.944893,
        0.944956, 0.94502, 0.945083, 0.945146, 0.945209, 0.945272, 0.945334,
        0.945397, 0.945459, 0.945522, 0.945584, 0.945646, 0.945708, 0.94577,
        0.945832, 0.945893, 0.945955, 0.946016, 0.946078, 0.946139, 0.9462,
        0.946261, 0.946322, 0.946383, 0.946443, 0.946504, 0.946564, 0.946625,
        0.946685, 0.946745, 0.946805, 0.946865, 0.946925, 0.946984, 0.947044,
        0.947103, 0.947162, 0.947222, 0.947281, 0.94734, 0.947399, 0.947457,
        0.947516, 0.947575, 0.947633, 0.947691, 0.94775, 0.947808, 0.947866,
        0.947924, 0.947982, 0.948039, 0.948097, 0.948154, 0.948212, 0.948269,
        0.948326, 0.948383, 0.94844, 0.948497, 0.948554, 0.948611, 0.948667,
        0.948724, 0.94878, 0.948836, 0.948892, 0.948948, 0.949004, 0.94906,
        0.949116, 0.949172, 0.949227, 0.949283, 0.949338, 0.949393, 0.949448,
        0.949503, 0.949558, 0.949613, 0.949668, 0.949722, 0.949777, 0.949831,
        0.949886, 0.94994, 0.949994, 0.950048, 0.950102, 0.950156, 0.950209,
        0.950263, 0.950317, 0.95037, 0.950423, 0.950477, 0.95053, 0.950583,
        0.950636, 0.950689, 0.950741, 0.950794, 0.950847, 0.950899, 0.950952]
}

export default arrowJson