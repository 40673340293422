<template>
  <div id="table">

    <el-card  :style="{width: 500 + 'px', height: (height - 300) + 'px'}"
              v-loading="queryWindow.loading" >
      <template #header>
        <div class="clearfix">
          <span>{{ $t('Запрос по') }} {{queryWindow.node.label}} : {{queryWindow.node.id}}</span>
        </div>
      </template>

      <el-select v-model="select.name"
                 :no-match-text="$t('Ничего не найдено')"
                 :placeholder="$t('Выберете связь')">
        <el-option
            v-for="item in Object.keys(queryWindow.links)"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>

      <el-select v-model="select.source"
                 v-if="select.name.length > 0"
                 style="margin-left: 8px"
                 :no-match-text="$t('Ничего не найдено')"
                 :placeholder="$t('Выберете источник')">
        <el-option
            v-for="item in queryWindow.links[select.name]"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>

      <el-row style="margin-top: 16px">
        <el-col :span="12">
          <el-button @click="close">
            {{ $t('Закрыть') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="$emit('query-done', select)">
            {{ $t('Отправить') }}
          </el-button>
        </el-col>
      </el-row>
    </el-card>

  </div>
</template>

<script>

import VisController from "../../controllers/vis.controller";
import OntologyController from "../../controllers/ontology.controller";


export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          node: {
          }
        }
      }
    }
  },
  name: 'QueryWindow',
  components: {
  },
    data () {
    return {
      tableColumns: [],
      tableData: [],
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ) * 3 / 4 + 16,
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 56,
      queryWindow: {
        loading: true,
        node: {},
        links: {}
      },
      select: {
        node: '',
        name: '',
        source: ''
      },
      visController: null,
      ontologyController: null,
      ontology: null
    }
  },
  mounted() {
    this.visController = new VisController()
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    this.init()
  },
  methods: {
    init() {
      this.queryWindow.loading = true
      const vm = this
      let node = {
        id: parseInt(this.data.node.id),
        label: this.data.node.name
      }
      this.queryWindow.node = node
      this.visController.getLinkSourcesByNode({node: node, ontology: this.ontology}).then( (response) => {
        this.queryWindow.links = response.data.links
        this.select.node = node
        vm.queryWindow.loading = false
      })
    },
    close () {
      this.$emit('close')
    },
    addToCart() {
      let attrName = ''
      for (let attr in this.nodeWindow.node.attributes) {
        if (attr.includes('name') || attr.includes('Name')) {
          attrName = attr + ': ' + this.nodeWindow.node.attributes[attr]
        }
      }
      this.$emit('add-to-cart', {
        name: this.nodeWindow.node.label + ' : ' + this.nodeWindow.node.id,
        id: this.nodeWindow.node.id,
        type: this.nodeWindow.node.label,
        text: attrName,
        class:'element-cart-card'
      })
    }
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<style>


  .break-long-words {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    display: inline-block;
  }


.vue-ads-w-6 {
  padding-left: 8px;
  padding-right: 8px;
  width: initial;
}

</style>
