import * as d3 from 'd3'
import arrowJSON from '../store/arrowJSON'
import {store} from "../store";

export default class D3Controller {
    constructor(svg, width, height) {
        this.ontology = store.getters['ontologyStore/getOntologyStore']
        // this.svg = svgSelection
        // this.graphSelection = graphSelection

        this.svg = d3.select(svg)
            .attr('width', width)
            .attr('height', height)
        this.graphSelection = this.svg.select('#gElement')
        //this.graphSelectionScale = this.svg.select('#gElementScale')
        this.height = height
        this.width = width
        this.simulation = null
        this.zoom = null
        this.graph = {
            nodes: [],
            links: [],
        }

        this.forceMode = false

        this.keydownDefault = null
        this.keyupDefault = null

        this.selected_link = null
        this.selectedNode = {x: 0, y:0}
        this.mousedown_node = null
        this.mouseup_node = null

        this.canBeSelected = true
        this.canBeEdited = true
        this.canDrawLine = true
        this.dragEnable = true


        const vm = this
        this.arrowJSON = arrowJSON
        this.tickFunction = function () {
            const graph = vm.graphSelection
            const selected = vm.selectedNode
            const transform = d => {
                return 'translate(' + (d.x) + ',' + (d.y) + ')'
            }

            graph.selectAll('.node').attr("transform", transform);

            if (selected !== null) {
                graph.select('#addToCart')
                    .attr('x', selected.x)
                    .attr('y', selected.y)
            }


            graph.selectAll('.link')
                .attr("x1", function(d) { return d.source.x; })
                .attr("y1", function(d) { return d.source.y; })
                .attr("x2", function(d) { return d.target.x; })
                .attr("y2", function(d) { return d.target.y; });
        }

    }

    select(element) {
        return d3.select(element)
    }

    event() {
        return d3.event
    }

    mouse(element) {
        return d3.mouse(element)
    }

    updateGraph(graph) {
        this.graph.links = graph.edges
        this.graph.nodes = graph.nodes
    }

    bindActions(dblclick, mousedown, mouseup, mousemove) {
        this.graphSelection
            .on('dblclick', dblclick)
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)
            .on('mousemove', mousemove)
    }

    bindKeyUp(func) {
        this.keydownDefault = func
        d3.select(window).on('keyup', func)
    }

    bindKeyDown(func) {
        d3.select(window).on('keydown', func)
        this.keyupDefault = func
    }

    bindZoom(scale) {
        const vm = this
        this.zoom = d3.zoom()
            .scaleExtent(scale)
            .on('zoom', function () {
                const transform = d3.event.transform;
                vm.graphSelection.attr('transform', transform)
            })
        this.svg.call(this.zoom)
        this.svg.on("dblclick.zoom", null);
    }

    unbindZoom() {
        this.svg.on('.zoom', null)
    }

    tick() {
        this.tickFunction()
    }

    bindSimulation(forceProperties) {
        this.simulation = d3.forceSimulation()
            .force('link', d3.forceLink())
            .force('charge', d3.forceManyBody())
            // .force('collide', d3.forceCollide())
            // .force('center', d3.forceCenter())
            .force('forceX', d3.forceX())
            .force('forceY', d3.forceY())
            .on('tick', this.tickFunction)
        this.restartSimulation(forceProperties)
    }

    restartSimulation(forceProperties) {
        if (this.simulation !== null) {
            const { simulation, width, height } = this
            // simulation.force("center")
            //     .x(width * forceProperties.center.x)
            //     .y(height * forceProperties.center.y);
            simulation.force("charge")
                .strength(forceProperties.charge.strength * forceProperties.charge.enabled)
                .distanceMin(forceProperties.charge.distanceMin)
                .distanceMax(forceProperties.charge.distanceMax);
            // simulation.force("collide")
            //     .strength(forceProperties.collide.strength * forceProperties.collide.enabled)
            //     .radius(forceProperties.collide.radius)
            //     .iterations(forceProperties.collide.iterations);
            simulation.force("forceX")
                .strength(forceProperties.forceX.strength * forceProperties.forceX.enabled)
                .x(width * forceProperties.forceX.x);
            simulation.force("forceY")
                .strength(forceProperties.forceY.strength * forceProperties.forceY.enabled)
                .y(height * forceProperties.forceY.y);
            simulation.force("link")
                .distance(forceProperties.link.distance)
                .iterations(forceProperties.link.iterations);
            this.simulation.nodes(this.graph.nodes)
            this.simulation.force('link').links(this.graph.links)
            this.simulation.alpha(1).restart()
        }
    }

    disableDrag() {
        this.dragEnable = false
        this.graphSelection.selectAll('.node').on('mousedown.drag', null)
    }

    enableDrag() {
        this.dragEnable = true
        const vm = this
        this.graphSelection.selectAll('.node')
            .call(d3.drag()
                .on('start', function (d) {
                    if (!d3.event.active) {
                        vm.simulation.alphaTarget(0.3).restart()
                    }
                    d.fx = d.x
                    d.fy = d.y
                })
                .on('drag', function (d) {
                    d.fx = d3.event.x
                    d.fy = d3.event.y
                })
                .on('end', function (d) {
                    if (!d3.event.active) { vm.simulation.alphaTarget(0.0001) }
                    if (vm.forceMode) {
                        d.fx = null
                        d.fy = null
                    } else {
                        d.fx = d3.event.x
                        d.fy = d3.event.y
                    }
                })
            )
    }


    removeElements(className) {
        this.graphSelection.selectAll(className).remove()
    }

    getNeighbours(id) {
        let neighbours = []
        for (let link of this.graph.links) {
            if (link.source.id === id) {
                neighbours.push(link.target.id)
            }
            if (link.target.id === id) {
                neighbours.push(link.source.id)
            }
        }
        return neighbours
    }

    selectNodeAndNighbours(id) {
        const neighbours = this.getNeighbours(id)
        // console.log('selectNodeAndNighbours', id, neighbours)
        this.graphSelection.selectAll('.label').attr('style', d => {
            if (id === d.id) return 'display: block'
            return 'display: none'
        })
        this.graphSelection.selectAll('.node').attr('style', d => {
            if (id === d.id || neighbours.includes(d.id)) return 'pointer-events: fill; opacity: 1'
            else return 'pointer-events: none; display: none;'
        })
        this.graphSelection.selectAll('.link').attr('style', d => {
            if ((id === d.source.id && neighbours.includes(d.target.id) )|| (id === d.target.id && neighbours.includes(d.source.id))) return 'stroke:' + d.color + ';stroke-width:' + 8*d.size + 'px;fill: none; opacity: 1'
            else return 'stroke:' + d.color + ';stroke-width:' + 8*d.size + 'px;fill: none;display: none;'
        })
    }


    createLinks(action, beforeElement, linkMousedown, linkMouseup) {
        const link = this.graphSelection.selectAll('.link').data(this.graph.links)

        link.enter().insert('line', beforeElement)
            .attr('class', 'link')
            .attr('x1', d => d.source.x)
            .attr('y1', d => d.source.y)
            .attr('x2', d => d.target.x)
            .attr('y2', d => d.target.y)
            .attr('style', d => { return 'stroke:' + d.color + ';stroke-width:' + 4*d.size + 'px;fill: none;'})
            .on('mousedown', linkMousedown)
            .on('mouseup', linkMouseup)

        link.exit().remove()
    }

    createOntology(action, beforeElement, mousedown, mouseup, double, mouseenter, mouseleave) {
        const node = this.graphSelection.selectAll('.ontology').data(this.graph.nodes)
        const vm = this
        const gNode = node.enter().insert('g', beforeElement).attr('class', 'node ontology')
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)
            .on('dblclick', double)
            .on('mouseenter', mouseenter)
            .on('mouseleave', mouseleave)


        const gLabel = gNode.insert('g').attr('class', 'label').attr('style', (d) => {
            if (d.freeze) return 'display: block'
            return 'display: none'
        })
        this.graphSelection.selectAll('.text-label-test').remove()

// + this._groups[0][0].getComputedTextLength()/2
        this.graphSelection.insert('text')
            .attr('class', 'text-label-test')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '20px')
            .attr('fill', 'white')
            .attr('font-weight', '500')
            .attr('text-anchor', 'middle')
            .text('')

        this.graphSelection.append('text')
            .attr('class', 'test-icon')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr("fill","black")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size',  42 + 'px')
            .text('');

        gLabel.insert('path')
            .attr('d', d => {
                let r = 16
                if (d.label !== null) {
                    let length = 0
                    let text = vm.graphSelection.select('.text-label-test').text(d.label)
                    d.lengthText = text._groups[0][0].getComputedTextLength()
                    length = d.lengthText + (20 * d.size) + 10
                    return 'M' + 0 + ',' + 0  + ' ' +
                        'm' + '0, ' + (-r) + ' ' +
                        'a' + r + "," + r + " 0 1,0 " + 0  + ","  + r*2 + ' '  +
                        'l' + length + ', ' + 0 + ' ' +
                        'l' + 0 + ', ' + -r*2 + ' ' +
                        'l' + -length + ', ' + 0 + ' '
                } else {
                    return 'M' + 0 + ',' + 0  + ' '
                }
            })
            .attr('style', 'fill: #202020')

        gLabel.insert('text')
            .attr('class', 'text-label')
            .attr('x', d => {
                return (20 * d.size) + d.lengthText/2 + 3
            })
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '20px')
            .attr('fill', 'white')
            .attr('font-weight', '500')
            .attr('text-anchor', 'middle')
            .text(d => {
                return d.label
            })
        gNode.insert('circle')
            .attr('class', 'stroke')
            .attr('style', d => {
                if (!vm.forceMode){
                    d.fx = d.x
                    d.fy = d.y
                }
                return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:white;stroke-width:1px'
            })
            .attr('r',d => {return 20 * d.size})
        gNode.append('text')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr('x', (d) => {

                let text = vm.graphSelection.select('.test-icon')
                    .attr('font-size', function() {    if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return 0
                    else return (17 * d.size) + 'px'} )
                    .text(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let size = text.node().getBBox()
                return -size.width/2
            })
            .attr('y', (d) => {
                // console.log(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let text = vm.graphSelection.select('.test-icon')
                    .attr('font-size', function() {    if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return 0
                    else return (17 * d.size) + 'px'} )
                    .text(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let size = text.node().getBBox()
                return -(size.height)/2 - size.y - 1
            })
            .attr("fill","white")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', function(d) {    if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return 0
            else return (19 * d.size) + 'px'} )
            .text(function(d) {
                if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return ''
                else return String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode))
            });

        this.graphSelection.selectAll('.test-icon').remove()
        this.graphSelection.selectAll('.text-label-test').remove()

        node.exit()
            .remove()

        return gNode
    }

    resetMouseVars() {
        const vm = this
        this.mousedown_node = null
        this.mouseup_node = null
        this.selected_node = null
        this.selected_link = null
        this.graphSelection.selectAll('.stroke').attr('style', d => {
            return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:white;stroke-width:1px'
        })
        this.graphSelection.selectAll('.link').attr('style', d => { return 'stroke:' + d.color + ';stroke-width:' + 6*d.size + 'px;fill: none; opacity: 1'})
        this.graphSelection.selectAll('.node').attr('style', 'pointer-events: fill; opacity: 1')
    }
}