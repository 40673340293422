<template>
  <div
    class="graph"
    v-loading="isLoading"
    id="sigma-parent"
    :style="{  position: 'absolute', left:0, right: 0, width: '100%', height: (height-2-100) + 'px', border: '1px solid #eee' }"
  >
    <!--<svg id="mapSVG" :width="(width-2) + 'px'" :height="(height-2) + 'px'" xmlns="http://www.w3.org/2000/svg" :style="{backgroundImage: 'url('+srcMap+')'}"></svg>-->

    <div
            :style="{position: 'absolute', left:8+'px', right: 0, top:8+'px', pointerEvents: 'none', 'z-index': 1000}"
            id="graph-labels"
    >
      <el-row type="flex" :style="{position: 'relative', left:16+'px', 'color':'white', 'font-size': '16px'}" justify="start">
        <span
                class="mr-2 badge badge-pill"
                v-for="node in legend.nodes"
                :key="node.name"
                :style="{position:'relative', background: ontology.nodes[node.type].color }"
        >{{node.title +' (' +countNodes[node.type]+')'}}</span>
      </el-row>
      <el-row type="flex" class="mt-1" :style="{position: 'relative', left:16+'px', 'color':'white', 'font-size': '16px'}" justify="start">
        <span
                class="mr-2 badge badge-pill"
                type="primary"
                v-for="edge in legend.edges"
                :key="edge.name"
                :style="{position:'relative', background: 'linear-gradient(90deg, hsl(0,0%,0%) 0%, hsl(0,0%,40%) 50%, hsl(0,0%,70%) 100%)' }"
        >{{edge.title +' (' +countEdges[edge.type]+')'}}</span>
      </el-row>
    </div>


    <el-card  :id="showFilter ? 'control-pane' : 'control-pane-collapse'" header-tag="header" footer-tag="footer">
      <template #header>
        <h5 class="mb-0 ml-0">{{ $t('Фильтр') }}</h5>
        <div :style="{float: 'right', position: 'absolute', padding: '3px 0', top: '10px', right: '16px'}">
          <div v-if="showFilter" @click="showFilter = false">
            <i  class="fas fa-minus fa-lg icon-cog"></i>
          </div>
          <div v-else @click="showFilter = true">
            <i  class="fas fa-plus fa-lg icon-cog"></i>
          </div>
        </div>

      </template>
      <div v-if="showFilter">
        <div>
          <h6>{{ $t('Степень') }}: {{levelValue[0]}} - {{levelValue[1]}}</h6>
          <el-slider id="min-level" range :marks="marksLevel" :max="maxLevel" v-model="levelValue" />
          <br />
        </div>
        <div>
          <h6>{{ $t('Величина ребра') }}: {{countValue[0]}} - {{countValue[1]}}</h6>
          <el-slider id="min-count" range :marks="marksCount" :max="maxCount" v-model="countValue" />
          <br />
        </div>
        <div style="margin-top: 8px; margin-bottom: 16px">
          <el-checkbox-group v-model="filter.typesDeep">
            <el-checkbox-button label="link-deep1">{{ $t('Соседи') }}</el-checkbox-button>
            <el-checkbox-button label="link-deep2">{{ $t('Через одного') }}</el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div>
          <el-button type="primary" id="submit-btn" style="margin-right: 8px" @click="acceptFilter">{{ $t('Применить') }}</el-button>
          <el-button type="danger" id="reset-btn" @click="resetFilter">{{ $t('Сброс') }}</el-button>
        </div>
      </div>
    </el-card>
    <div id="image-map"></div>
  </div>
  <div  v-if="isLoading" :style="{ 'z-index': 2000, position: 'absolute',  left: '0px', top: height/2+'px' , width:'100%', height: 200 + 'px', 'text-align': 'center'}">

    <p style="font-size:16px; text-align: center;margin-top: 0px">
      {{socketProgress}}
    </p>
  </div>
</template>

<script>
  /* eslint-disable */
import config from "../../../config";
import VisController from "../../controllers/vis.controller";
  import OntologyController from "../../controllers/ontology.controller";
  import CaseSessionController from "../../controllers/caseSession.controller";
  import CashController from "../../controllers/cash.controller";

const ScienceAPI = config.API

export default {
  name: 'GraphMap',
  props: {
    data: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  data () {
    return {
      graph: {
        nodes: [],
        edges: [],
        visLinks: []
      },
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ) * 3 / 4 + 16,
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 56,
      padding: 150,
      srcMap: require('@/assets/mapRussia.svg'),
      countNodes: {},
      countEdges: {},
      legend: {
        nodes: [],
        edges: []
      },
      isLoading: true,
      filter: null,
      maxCount: 0,
      maxLevel: 0,
      maxCountAll: [],
      maxLevelAll: [],
      levelValue: [0, 1],
      countValue: [0, 1],
      filter: {
        typesDeep: ['link-deep1', 'link-deep2']
      },
      marksLevel: {
        0: '0'
      },
      marksCount: {
        0: '0'
      },
      mapRussia: null,
      sizeX: 0,
      sizeY: 0,
      socketProgress: this.$t('Загрузка'),
      ontology: null,
      visController: null,
      caseSessionController: null,
      ontologyController: null,
      cashController: null,
      showElementInfoTimeout: null,
      edgesDone: {'deep1': [], 'deep2': []},
      gLayer: null,
      showFilter: true
    }
  },
  computed: {
    nodes () {
      return this.graph.nodes
    },
    links () {
      return this.graph.links
    }
  },
  mounted: function () {
    this.visController = new VisController()
    this.ontologyController = new OntologyController()
    this.cashController = new CashController()
    this.caseSessionController = new CaseSessionController()
    this.ontology = this.ontologyController.getOntology()
    if (this.data !== null){
      this.init(this.data)
    }
  },
  watch: {},
  methods: {
    clear() {
      if (this.gLayer !== null) this.gLayer.clearLayers()

      this.graph.nodes = []
      this.graph.edges = []
      this.graph.visLinks = []
      this.countNodes = {}
      this.countEdges = {}
    },
    init (data) {
      const vm = this
      this.isLoading = true
      const session = this.caseSessionController.getCurrentSessionStore()
      const socket = this.$io
      socket.on('subgraph-map-message', (data) => {
        if (data.sessiodId === session._id) {
          vm.socketProgress = data.message
        }
      })
      if (this.mapRussia === null) this.mapRussia = vm.$L.map('image-map', {
        minZoom: 3,
        maxZoom: 18,
        center: vm.$L.latLng(65, 100),
        zoom: 3,
        dragging: true,
        zoomControl: false
      })
      let vectorTileStyling = {
        water: {
          fill: true,
          weight: 1,
          fillColor: 'rgb(146,202,202)',
          color: 'rgb(146,202,202)',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        admin: {
          weight: 1,
          fillColor: 'pink',
          color: 'pink',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        waterway: {
          weight: 1,
          fillColor: '#cfd0e0',
          color: '#cfd0e0',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        landcover: {
          fill: true,
          weight: 1,
          fillColor: '#d0e0c8',
          color: '#d0e0c8',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        landuse: {
          fill: true,
          weight: 1,
          fillColor: 'rgb(234, 234, 230)',
          color: 'rgb(234, 234, 230)',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        park: {
          fill: true,
          weight: 1,
          fillColor: 'rgb(230, 233, 229)',
          color: 'rgb(230, 233, 229)',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        boundary: {
          weight: 1,
          fillColor: '#000000',
          color: '#000000',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        aeroway: {
          weight: 1,
          fillColor: '#9eb2b6',
          color: '#9eb2b6',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        road: {	// mapbox & nextzen only
          weight: 1,
          fillColor: '#f2e9cb',
          color: '#f2e9cb',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        tunnel: {	// mapbox only
          weight: 0.5,
          fillColor: '#f2e9cb',
          color: '#f2e9cb',
          fillOpacity: 0.2,
          opacity: 0.4
          // 					dashArray: [4, 4]
        },
        bridge: {	// mapbox only
          weight: 0.5,
          fillColor: '#f2e9cb',
          color: '#f2e9cb',
          fillOpacity: 0.2,
          opacity: 0.4
          // 					dashArray: [4, 4]
        },
        transportation: {	// openmaptiles only
          weight: 0.5,
          fillColor: '#f2e9cb',
          color: '#f2e9cb',
          fillOpacity: 0.2,
          opacity: 0.4
          // 					dashArray: [4, 4]
        },
        transit: {	// nextzen only
          weight: 0.5,
          fillColor: '#f2e9cb',
          color: '#f2e9cb',
          fillOpacity: 0.2,
          opacity: 0.4
          // 					dashArray: [4, 4]
        },
        building: {
          fill: true,
          weight: 1,
          fillColor: '#2b2b2b',
          color: '#2b2b2b',
          fillOpacity: 0.2,
          opacity: 0.4
        },
        water_name: [],
        transportation_name: [],
        place: [],
        housenumber: [],
        poi: [],
        earth: [],
        mountain_peak: [],
        // Do not symbolize some stuff for mapbox
        country_label: [],
        marine_label: [],
        state_label: [],
        place_label: [],
        waterway_label: [],
        poi_label: [],
        road_label: [],
        housenum_label: [],
        // Do not symbolize some stuff for openmaptiles
        country_name: [],
        marine_name: [],
        state_name: [],
        place_name: [],
        waterway_name: [],
        poi_name: [],
        road_name: [],
        housenum_name: []
      }

      L.vectorGrid.protobuf(`${ScienceAPI}/sqlite/getTiles/{z}/{x}/{y}`, {
        vectorTileLayerStyles: vectorTileStyling
      }).addTo(vm.mapRussia)

      if (vm.gLayer === null) vm.gLayer = vm.$L.layerGroup().addTo(vm.mapRussia);
      vm.gLayer.clearLayers()

      let currentBounds = vm.mapRussia.getBounds()
      vm.sizeY = vm.mapRussia.latLngToLayerPoint(currentBounds._southWest).y
      vm.sizeX = vm.mapRussia.latLngToLayerPoint(currentBounds._northEast).x

      let cash = this.cashController.getCashStore()
      if (!data.isNew) {
        data.nodes = cash.graphSubgraph.graph.nodes
        data.links = cash.graphSubgraph.graph.edges
      }

      this.visController.getSubgraphMap({
        ontology: this.ontology,
        revealed: cash.revealed.elements,
        sessionId: session._id,
        data: data
      }).then((response) => {
        const data = response.data

        vm.legend = data.legend

        for (let n of vm.legend.edges) {
          vm.countEdges[n.type] = 0
        }

        let i = 0
        for (let node of data.nodes) {
          let displayAttribute = ''
          if (Object.keys(cash.displayAttribute).includes(node.name)) {
            displayAttribute = cash.displayAttribute[node.name]
          } else {
            displayAttribute = ''
          }
          if (node.name in vm.countNodes) vm.countNodes[node.name] += 1
          else {
            vm.countNodes[node.name] = 1
          }
          vm.graph.nodes.push({
            id: '' + node.id,
            name: node.name,
            latlng: [parseFloat(node.latlng[0]), parseFloat(node.latlng[1])],
            label: node.name,
            element: 'ontology',
            isFiltered: false,
            size: 1,
            displayAttribute: displayAttribute,
            sizesAll: 1,
            level: node.level,
            color: vm.ontology.nodes[node.name].color,
            showElementInfo: false
          })
          i++
        }

        let maxCount = 0
        let maxLevel = 10


        for (let relation of data.relations) {
          if (relation.type in vm.countEdges) vm.countEdges[relation.type] += 1
          else {
            vm.countEdges[relation.type] = 1
          }
          let hidden = false
          let color = '#999'
          if (relation.id.toString().split(':').length > 1) color = '#3d3'
          vm.graph.edges.push({
            id: '' + relation.id,
            source: '' + relation.source,
            label: relation.id,
            target: '' + relation.target,
            element: 'link',
            size: 0.48,
            color: color,
            count: relation.count,
            hidden: hidden,
            count: 1,
            deep: relation.type,
            name: relation.name,
            isActive: false,
            isFiltered: false
          })
          i++
          vm.countEdges[relation.name] += 1
        }

        vm.edgesDone = {'deep1': [], 'deep2': []}
        for(let i = 0; i < vm.graph.edges.length; i++) {
          let key = 'deep1'
          // console.log(vm.graph.edges[i].id)
          if (vm.graph.edges[i].id.split(':').length > 1) key = 'deep2'
          if (!vm.edgesDone[key].includes(vm.graph.edges[i].source + ':' + vm.graph.edges[i].target) && !vm.edgesDone[key].includes(vm.graph.edges[i].target + ':' + vm.graph.edges[i].source)) {
            let source = null
            let target = null
            for (let j = 0; j < vm.graph.nodes.length; j++) {
              if (vm.graph.nodes[j].id === vm.graph.edges[i].source) source = vm.graph.nodes[j]
              if (vm.graph.nodes[j].id === vm.graph.edges[i].target) target = vm.graph.nodes[j]
            }
            let count = 0
            for (let relation2 of vm.graph.edges) {
              if ((vm.graph.edges[i].source === relation2.source && vm.graph.edges[i].target === relation2.target) || (vm.graph.edges[i].target === relation2.source && vm.graph.edges[i].source === relation2.target)) {
                count++
              }
            }
            if (count > maxCount) maxCount = count
            vm.graph.edges[i].count = count
            vm.graph.edges[i].latlngs = [source.latlng, target.latlng]
            vm.edgesDone[key].push(vm.graph.edges[i].source + ':' + vm.graph.edges[i].target)
            vm.graph.visLinks.push(vm.graph.edges[i])
          }
        }

        for (let i = 0; i < vm.graph.visLinks.length; i++) {
          vm.graph.visLinks[i].count /= 2
        }
        maxCount /= 2
        for (let key in vm.countEdges) {
          vm.countEdges[key] /= 2
        }

        for (let j = 0; j < vm.graph.nodes.length; j++) {
          let level = 0
          for (let relation of vm.graph.visLinks) {
            if (relation.source === vm.graph.nodes[j].id || relation.target === vm.graph.nodes[j].id) level += relation.count
          }
          if (level > maxLevel) maxLevel = level
          vm.graph.nodes[j].level = level
        }

        vm.maxLevelAll = maxLevel
        vm.maxCountAll = maxCount
        vm.maxCount = maxCount
        vm.maxLevel = maxLevel
        vm.levelValue[1] = maxLevel
        vm.countValue[1] = maxCount
        vm.marksLevel = {0: '0'}
        vm.marksCount = {0: '0'}
        vm.marksLevel['' + maxLevel] = '' + maxLevel
        vm.marksCount['' + maxCount] = '' + maxCount

        socket.off('subgraph-map-message')
        vm.draw()
      })

    },
    acceptFilter () {
      this.isLoading = true


      const vm = this
      for (let i = 0; i < this.graph.nodes.length; i++) {
        this.graph.nodes[i].hidden = false
      }
      for (let i = 0; i < this.graph.visLinks.length; i++) {
        this.graph.visLinks[i].hidden = true
      }

      for (let type of this.filter.typesDeep) {
        for (let i = 0; i < this.graph.visLinks.length; i++) {
          if (this.graph.visLinks[i].deep === type) this.graph.visLinks[i].hidden = false
        }
      }

      for (let i = 0; i < this.graph.nodes.length; i++) {
        let level = this.graph.nodes[i].level
        if (level > vm.levelValue[1] || level < vm.levelValue[0]) {
          this.graph.nodes[i].hidden = true
        }
      }

      for (let i = 0; i < this.graph.visLinks.length; i++) {
        let count = this.graph.visLinks[i].count
        if (count > vm.countValue[1] || count < vm.countValue[0]) {
          this.graph.visLinks[i].hidden = true
        } else {
          for (let j = 0; j < this.graph.nodes.length; j++) {
            if (this.graph.visLinks[i].source === this.graph.nodes[j].id || this.graph.visLinks[i].target === this.graph.nodes[j].id) {
              if (this.graph.nodes[j].hidden) {
                this.graph.visLinks[i].hidden = true
                break
              }
            }
          }
        }
      }

      for (let j = 0; j < this.graph.nodes.length; j++) {
        let countLInks = 0
        for (let i = 0; i < this.graph.visLinks.length; i++) {
          if (this.graph.visLinks[i].source === this.graph.nodes[j].id || this.graph.visLinks[i].target === this.graph.nodes[j].id) {
            if (!this.graph.visLinks[i].hidden) countLInks++
          }
        }
        if (countLInks === 0) this.graph.nodes[j].hidden = true
      }

      this.draw()
    },
    resetFilter () {
      this.levelValue = [0, this.maxLevel]
      this.countValue = [0, this.maxCount]
      this.filter.typesDeep = ['link-deep1', 'link-deep2']
      this.acceptFilter()
    },
    draw () {
      this.gLayer.clearLayers()
      const vm = this
      function getEdgeColor (bias, count, max, H, S) {
        let num = Math.tanh(count/ max * 1.5) / (1 + bias) + bias
        return 'hsl(' + H + ', ' + S + '%, ' + Math.floor((1 - num) * 80 + 15) + '%)'
      }

      function getParameter (bias, count, max) {
        return Math.tanh(count/ max * 1.8) / (1 + bias) + bias
      }

      for (let i = 0; i < vm.graph.nodes.length; i++) {
        let node = vm.graph.nodes[i]
        if (!node.hidden) {
          vm.graph.nodes[i].showElementInfo = false
          let latlng = vm.graph.nodes[i].latlng
          if (typeof latlng !== 'undefined') {
            if (latlng[0] !== null && latlng[1] !== null) {
              let circle = vm.$L.circleMarker([Math.round(latlng[0] * 10000) / 10000, Math.round(latlng[1] * 10000) / 10000], {
                radius: 14 * getParameter(0.1, node.level, vm.maxLevel),
                fill: true,
                fillOpacity: 0.7,
                color: vm.graph.nodes[i].color,
                index: i
              }).addTo(vm.gLayer)
              //circle.bindTooltip('аываываыва')
              circle.on('mouseover', function onClick(e) {
                vm.graph.nodes[e.target.options.index].showElementInfo = true
                vm.showElementInfoTimeout = setTimeout(function () {
                  if (vm.graph.nodes[e.target.options.index].showElementInfo) {
                    vm.$emit('show-info', vm.graph.nodes[e.target.options.index])
                  }
                }, 500)
              })
              circle.on('mouseout', function onClick(e) {
                clearTimeout(vm.showElementInfoTimeout);
                vm.graph.nodes[e.target.options.index].showElementInfo = false
                vm.$emit('close-info', vm.graph.nodes[e.target.options.index])
              })
            }
          }
        }
      }


      for (let relation of vm.graph.visLinks) {
        if (!relation.hidden) {
          let shift = 0
          if (relation.deep === 'link-deep1') {
            if (vm.edgesDone['deep2'].includes(relation.source + ':' + relation.target) || vm.edgesDone['deep2'].includes(relation.target + ':' + relation.source)) {
              shift = -1
            }
          }
          if (relation.deep === 'link-deep2') {
            if (vm.edgesDone['deep1'].includes(relation.source + ':' + relation.target) || vm.edgesDone['deep1'].includes(relation.target + ':' + relation.source)) {
              shift = 1
            }
          }
          let line = null
          let H = 0
          let S = 0
          if (relation.deep === 'link-deep2') {
            H = 160
            S = 90
          }
          if (shift !== 0) {
            let offsetX = relation.latlngs[1][1] - relation.latlngs[0][1]
            let offsetY = relation.latlngs[1][0] - relation.latlngs[0][0]
            if (offsetX < 0) offsetX = -offsetX
            if (offsetY < 0) offsetY = -offsetY
            let midX = (relation.latlngs[1][1] + relation.latlngs[0][1])/2
            let midY = (relation.latlngs[1][0] + relation.latlngs[0][0])/2

            let theta = Math.atan2(offsetY, offsetX);


            let midpointX = (1 * Math.sin(theta)) * shift + midX
            let midpointY = (1 * Math.cos(theta)) * shift + midY


            let midpointLatLng = [midpointY, midpointX];

            line = vm.$L.curve(
                    [
                      'M', relation.latlngs[0],
                      'Q', midpointLatLng,
                      relation.latlngs[1]
                    ], {
                      color: getEdgeColor(0.1, relation.count, vm.maxCount, H, S),
                      weight: 2.4 * getParameter(0.1, relation.count, vm.maxCount)
                    }).addTo(vm.gLayer)
          } else {
            line = vm.$L.polyline(relation.latlngs, {
              color: getEdgeColor(0.1, relation.count, vm.maxCount, H, S),
              weight: 2.4 * getParameter(0.1, relation.count, vm.maxCount)
            }).addTo(vm.gLayer)
            //line.bindTooltip(relation.type)
          }
          line.bringToBack()
        }
      }

      // Then, let's add some data to display:
      vm.isLoading = false
    }
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
      integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
      crossorigin=""/>
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

#container {
  position: absolute;
  width: 100%;
  height: 100%;
}
#image-map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}


.leaflet-pane,
.leaflet-map-pane {
  position: absolute;
  left: 0;
}
.muted {
  fill-opacity: 0.1;
  stroke-opacity: 0.1;
}
#control-pane {
  top: 10px;
  /*bottom: 10px;*/
  right: 10px;
  margin-right: 16px;
  position: absolute;
  width: 300px;
  z-index: 1900;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
#control-pane-collapse {
  top: 10px;
  /*bottom: 10px;*/
  right: 10px;
  margin-right: 16px;
  position: absolute;
  width: 300px;
  height: 50px;
  z-index: 50000;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.loading {
  z-index: 314180;
}

#control-pane > div {
  margin: 10px;
  overflow-x: auto;
}
.line {
  clear: both;
  display: block;
  width: 100%;
  margin: 0;
  padding: 12px 0 0 0;
  border-bottom: 1px solid #000;
  background: transparent;
}
.leaflet-container {
  background-color:rgba(255,250,250,0.0);
}
h2,
h3,
h4 {
  padding: 0;
}

h2.underline {
  color: #000;
  background: rgb(240, 240, 240);
  margin: 0;
  border-radius: 2px;
  padding: 8px 12px;
  font-weight: normal;
}
.hidden {
  display: none;
  visibility: hidden;
}

input[type="range"] {
  width: 160px;
}

.graph-label {
  width: 20px;
  height: 10px;
  border-radius: 20%;
  text-align: center;
  padding: 1.4644px;
  background: rgb(184, 54, 39);
}
</style>
