<template>
  <div
    class="graph"
    oncontextmenu="return false;"
    id="graph"
    v-loading="isLoading"
    :style="{  position: 'absolute', left:0, right: 0, width: '100%', height: '100%', border: '1px solid #eee' }"
  >
    <!--<svg id="mapSVG" :width="(width-2) + 'px'" :height="(height-2) + 'px'" xmlns="http://www.w3.org/2000/svg" :style="{backgroundImage: 'url('+srcMap+')'}"></svg>-->
    <svg :style="{position: 'absolute', left:0, right: 0, width: '100%', height:  '100%'}" height="100%" width="100%"
         xmlns="http://www.w3.org/2000/svg"
         id="svg"
         ref="svgGraph">
      <g height="100%" width="100%" id="gElement">
        <rect :x="-10000" :y="-10000" height="20000" width="20000" style="fill: white"></rect>
        <foreignObject  :style="{'display': colorMenu.show ? 'block' : 'none', width: '258px', height: '100px'}" id="colorMenuObject" ref="colorMenuObject">
          <ColorPicker @change = "changeColorElement" v-model="colorMenu.color" :predefine="colorMenu.predefine" ref="colorMenu" id="colorMenu" />
        </foreignObject>
        <foreignObject  :style="{'display': 'none', width: '600px', height: '500px'}" id="elementInfoObject" ref="elementInfoObject">
          <div style="pointer-events: all">

          </div>
        </foreignObject>
      </g>
    </svg>

    <div
      :style="{position: 'absolute', left:8+'px', top:8+'px', pointerEvents: 'none', 'z-index': 1000}"
      id="graph-labels"
    >
      <el-row type="flex" :style="{position: 'relative', left:16+'px', 'color':'white', 'font-size': '16px'}" justify="start">
        <div v-for="node in legend.nodes"
             :key="node.type"
             @click="labelEdit(node.type)"
             style="pointer-events: all"
            >
           <span
                   class="mr-2 mt-1 badge badge-pill"
                   :style="{position:'relative', background: ontology.nodes[node.type].color }"
           >{{node.title +' (' +countNodes[node.type]+')'}}</span>

        </div>
      </el-row>
      <el-row type="flex" :style="{position: 'relative', left:16+'px', 'color':'white', 'font-size': '16px'}" justify="start">
        <span
          class="mr-2 mt-1 badge badge-pill"
          type="primary"
          v-for="edge in legend.edges"
          :key="edge.type"
          :style="{position:'relative', background: 'linear-gradient(90deg, hsl(0,0%,0%) 0%, hsl(0,0%,40%) 50%, hsl(0,0%,70%) 100%)' }"
        >{{edge.title +' (' +countEdges[edge.type]+')'}}</span>
      </el-row>
    </div>

    <ExpandWindow  @expand-done="expandDone"
                   @close-info="closeInfo"  @show-info="showInfo"
                   :style="{position: 'absolute', 'z-index': 2000, 'top': '-42px', 'left': '16px', 'right': '16px', 'bottom': '16px'}"
                   @close="expandWindow.show = false" v-if="expandWindow.show" :data="expandWindow.data"> </ExpandWindow>


    <QueryWindow
        @query-done="queryDone"
        :style="{position: 'absolute', 'z-index': 2000, 'top': '16px', 'left': '16px'}"
        @close="queryWindow.show = false" v-if="queryWindow.show" :data="queryWindow.data"></QueryWindow>


    <EdgesTable
            @close-info="closeInfo"  @show-info="showInfo"
            :style="{position: 'absolute', 'z-index': 2000, 'top': '-42px', 'left': '16px', 'right': '16px', 'bottom': '16px'}"
            @close="edgesWindow.show = false" v-if="edgesWindow.show" :data="edgesWindow.data"></EdgesTable>

    <div id="control-reset">
      <el-button style="font-size: 16px" type="danger" @click="resetFilter">
        {{ $t('Сброс') }}
      </el-button>
    </div>

<!--    <div id="control-force">-->
<!--      <el-button type="primary" @click="changeForceMode">-->
<!--        <div v-if="!forceStarted">-->
<!--          <span style="font-size: 16px">Force</span>-->
<!--          <i class="ml-2 fas fa-play fa-lg icon-cog"></i>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <span style="font-size: 16px">Force</span>-->
<!--          <i style="color: #AC3B61;" class="ml-2 fas fa-stop fa-lg icon-cog"></i>-->
<!--        </div>-->
<!--      </el-button>-->
<!--    </div>-->

<!--    <div id="control-image">-->
<!--      <el-button type="primary" @click="saveImage">-->
<!--        <i class="fas fa-camera fa-lg icon-cog"></i>-->
<!--      </el-button>-->
<!--    </div>-->

    <div id="control-search" >
      <el-row type="flex" justify="end" :style="{textAlign: 'center'}">
        <el-select v-model="nodeSearch"
                                @change="searchNodeStart"
                   value-key="value"
                   :no-match-text="$t('Ничего не найдено')"
                                style="width: 300px;display: inline-block; margin-right: 4px;"
                                v-if="searchExpand"
                                filterable
                                :placeholder="$t('Поиск')">
        <el-option
                v-for="item in nodeNames"
                :key="item.value"
                :label="item.name"
                :value="item">
        </el-option>
      </el-select>
          <el-button style="display: inline-block; height: fit-content" type="primary" @click="searchExpand = !searchExpand">
            <i class="fas fa-search fa-lg icon-cog"></i>
          </el-button>
      </el-row>
    </div>

    <div id="control-show-labels">
      <el-row type="flex" justify="end" :style="{textAlign: 'center'}">
        <el-checkbox @change="showAllLabelsChange" v-model="showAllLabels">{{ $t('Показать все надписи') }}</el-checkbox>
      </el-row>
    </div>
  </div>
  <div  v-if="isLoading" :style="{ 'z-index': 2000, position: 'absolute',  left: '0px', top: height/2+'px' , width:'100%', height: 200 + 'px', 'text-align': 'center'}">

    <p style="font-size:16px; text-align: center;margin-top: 40px">
      {{socketProgress}}
    </p>
  </div>
</template>

<script>
  /* eslint-disable */
import axios from 'axios'
  import Utils from "../../services/utils";
import config from "../../../config";

  import {defineComponent,createApp} from 'vue'
  import VisController from "../../controllers/vis.controller";
  import EdgesTable from "./EdgesTable"
  import QueryWindow from "./QueryWindow"
  import ExpandWindow from "./ExpandWindow"
  import ElementInfo from "./ElementInfo"
  import D3Controller from "../../controllers/d3.graph.full.controller";
  import CaseSessionController from "../../controllers/caseSession.controller";
  import ColorPicker from "./ColorPicker";
  import * as d3 from "d3";
  import basic from "../../store/basic";
  import OntologyController from "../../controllers/ontology.controller";
  import CashController from "../../controllers/cash.controller";
const ScienceAPI = config.API
  import {Canvg} from 'canvg'
  import html2pdf from "html2pdf.js";



export default {
  name: 'v-graph',
  props: {
    data: {},
  },
  components: {
    QueryWindow,
    ExpandWindow,
    EdgesTable,
    ColorPicker,
    ElementInfo
  },
  data () {
    return {
      graph: {
        nodes: [],
        edges: [],
        edgenames: [],
        allEdges: []
      },
      showAllLabels: false,
      labelEditWindow: {
        show: false,
        data: [],
        label: '',
        picked: ''
      },
      queryWindow: {
        show: false,
        data: {}
      },
      edgesWindow: {
        show: false,
        data: {},
        avaliableNodes: [],
        avaliableEdges: []
      },
      expandWindow: {
        show: false,
        data: {}
      },
      colorMenu: {
        color: '',
        predefine: [
          '#ee403e',
          '#ee403e',
          '#ff8334',
          '#ffee27',
          '#a8ee4b',
          '#38d152',
          '#4bd190',
          '#36bfd1',
          '#244eef',
          '#8b2edb',
          '#6d696f',
          '#000000'],
        show: false
      },
      subgraphEdgesWindow: {
        show: false,
        loading: false,
        data: {}
      },
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 146,
      padding: 150,
      forceProperties: {
        center: {
          x: 0.5,
          y: 0.5
        },
        charge: {
          enabled: true,
          strength: -1500,
          distanceMin: 70,
          distanceMax: 1200
        },
        collide: {
          enabled: true,
          strength: .7,
          iterations: 1,
          radius: 35
        },
        forceX: {
          enabled: false,
          strength: 0.01,
          x: 0.5
        },
        forceY: {
          enabled: false,
          strength: 0.01,
          y: 0.5
        },
        link: {
          enabled: true,
          distance: 100,
          iterations: 1
        }
      },
      menu: {backgroundColor: 'white',display: 'none', height: 148 + 'px', width: (170)  + 'px','z-index':1000000},
      countNodes: {},
      countEdges: {},
      legend: {
        nodes: [],
        edges: []
      },
      isLoading: true,
      filter: null,
      ontology: {
        nodes: {},
        links: {},
        shortList: {}
      },
      socketProgress: this.$t('Загрузка'),
      showFilter: true,
      forceStarted: false,
      searchExpand: false,
      nodeSearch: null,
      nodeNames: [],
      selectedNode: null,
      visController: null,
      showPreElementInfo: false,
      showElementInfoTimeout: null,
      controller: null,
      sessionController: null,
      ontologyController: null,
      cashController: null
    }
  },
  mounted: function () {
    const vm = this
    this.ontologyController = new OntologyController()
    this.cashController = new CashController()
    this.ontology = this.ontologyController.getOntology()
    this.visController = new VisController()
    this.controller = new D3Controller(this.$refs.svgGraph, this.width, this.height)
    this.sessionController = new CaseSessionController()
    this.controller.bindZoom([1 / 3, 4])
    //this.bindMouseActions()
    window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        this.expandWindow.show = false
        this.subgraphEdgesWindow.show = false
        this.edgesWindow.show = false
        this.queryWindow.show = false
        this.labelEditWindow.show = false
      }
    });
    let cash = this.cashController.getCashStore()
    if (cash.graphSubgraph === null || this.data.isNew) this.init(this.data)
    else this.initCash()
    this.bindMouseActions()
  },
  beforeUnmount: function() {
    let cash = this.cashController.getCashStore()
    cash.graphSubgraph = {}
    cash.graphSubgraph.graph = this.graph
    cash.graphSubgraph.legend = this.legend
    cash.graphSubgraph.scale = this.controller.currentScale
    this.cashController.setCashStore(cash)
    this.removeAllElements()
  },
  methods: {
    initCash() {
      this.clear()
      this.nodeSearch = null
      this.nodeNames = []
      this.graph.nodes = []
      this.graph.edges = []

      const vm = this

      let cash = this.cashController.getCashStore()
      console.log("cash.displayAttribute = ", JSON.stringify(cash.displayAttribute));
      this.graph = Object.assign({}, cash.graphSubgraph.graph)
      this.setCommonAttributes()

      for (let l =0; l < this.graph.edges.length; l++) {

        let relation = this.graph.edges[l]
        if (relation.name in this.countEdges) this.countEdges[relation.name] += 1
        else {
          this.edgesWindow.avaliableEdges.push(relation.name)
          this.countEdges[relation.name] = 1
        }

        this.graph.edges[l].source = (function () {
          for (let i = 0; i < vm.graph.nodes.length; i++) {
            if (vm.graph.nodes[i].index ===  vm.graph.edges[l].source.index) return vm.graph.nodes[i]
          }
        })()
        this.graph.edges[l].target = (function () {
          for (let i = 0; i < vm.graph.nodes.length; i++) {
            if (vm.graph.nodes[i].index ===  vm.graph.edges[l].target.index) return vm.graph.nodes[i]
          }
        })()
      }

      this.controller.linksCounter = 0
      this.controller.nodesCounter = 0

      for (let n of this.graph.nodes) {
        if (this.controller.nodesCounter < n.counter) this.controller.nodesCounter = n.counter
      }

      for (let l of this.graph.edges) {
        if (this.controller.linksCounter < l.counter) this.controller.linksCounter = l.counter
      }

      this.controller.updateGraph(this.graph)

      for (let node of this.graph.nodes) {
        if (node.name in this.countNodes) this.countNodes[node.name] += 1
        else {
          this.edgesWindow.avaliableNodes.push(node.name)
          this.countNodes[node.name] = 1
        }
      }

      this.legend = cash.graphSubgraph.legend
      this.controller.currentScale = cash.graphSubgraph.scale
      this.controller.svg.call(this.controller.zoom.transform,
              this.controller.zoomIdentity()
                      .translate(cash.graphSubgraph.scale.x, cash.graphSubgraph.scale.y)
                      .scale(cash.graphSubgraph.scale.k));
      this.controller.bindSimulation(this.forceProperties)
      this.loadNodesNames()
      this.draw()
      this.controller.enableDrag()
      this.isLoading = false
    },
    setCommonAttributes() {
      let attributes = localStorage.getItem("displayAttribute")
      if(!!attributes) {
        let checkAttributes = JSON.parse(attributes)
        if (!!checkAttributes) {
          this.graph.nodes.forEach((node) => { 
            const index = checkAttributes.findIndex((attribute) => attribute.type === node.name)
            if(index !== -1) {
                node.displayAttribute = checkAttributes[index].attribute
                console.log(".. node.displayAttribute = ", node.displayAttribute);
            } else {
              console.log(node.name, "-->", node.displayAttribute);
              // node.displayAttribute = ""
            }
          })
        } else {
          console.log(".. setCommonAttributes. JSON parse error. attributes = ", attributes);
        }
      } else {
         this.graph.nodes.forEach((node) => {
          console.log(node.name, "-->", node.displayAttribute);
          // node.displayAttribute = ""
        })
      }
    },
    async saveImage(){
      let canvas = document.getElementById("canvas");
      const ctx = canvas.getContext('2d');

      let svg = document.getElementById("svg");
      let svgWider = svg.innerHTML;

      let v = await Canvg.from(ctx, svgWider);
      // Start SVG rendering with animations and mouse handling.
      v.start();

      const options = {
        margin: 15,
        filename: 'document.pdf',
        pagebreak: {mode: ['css', 'legacy']},
        image: {type: 'png', quality: 1},
        jsPDF: {unit: 'mm', format: 'a4'}
      }
      html2pdf().set(options).from(canvas).save().then(function () {
        //doc.remove()
      })



    },

    clear() {
      this.removeAllElements()

      this.graph.nodes = []
      this.graph.edges = []
      this.graph.edgenames = []
      this.graph.allEdges = []
      this.countNodes = {}
      this.countEdges = {}
    },
    spliceLinksForNode(node) {
      const vm = this
      let toSplice = this.graph.allEdges.filter(
          function (l) {
            return (l.source.id === node.id) || (l.target.id === node.id)
          })
      toSplice.map(
          function (l) {
            vm.countEdges[l.name]--
            vm.graph.allEdges.splice(vm.graph.allEdges.indexOf(l), 1)
          })
      let toSplice2 = this.graph.edges.filter(
          function (l) {
            return (l.source.id === node.id) || (l.target.id === node.id)
          })
      toSplice2.map(
          function (l) {
            vm.graph.edges.splice(vm.graph.edges.indexOf(l), 1)
          })
    },
    deleteElement() {
      const vm = this
      this.sessionController.addLogStore('deleteGraphElement', 'Удаление элемента графа')

      if (vm.controller.selected_node) {
        if (vm.controller.selected_node.element === 'ontology') {
          vm.countNodes[vm.controller.selected_node.name] --
          vm.graph.nodes.splice(vm.graph.nodes.indexOf(vm.controller.selected_node), 1)
        }
        vm.spliceLinksForNode(vm.controller.selected_node)
      }
      clearTimeout(vm.controller.selected_node.showElementInfoTimeout);
      vm.controller.selected_node.showPreElementInfo = false
      vm.controller.selected_node.showElementInfo = false
      vm.controller.selected_link = null
      vm.controller.selected_node = null
      vm.removePies()
      vm.draw('delete', null)
    },
    showAllLabelsChange() {
     
      for (let i = 0; i < this.graph.nodes.length; i++) {
        this.graph.nodes[i].freeze = this.showAllLabels
        // console.log('this.graph.nodes[i].freeze', this.graph.nodes[i].freeze)
      }
      this.draw()
    },
    labelEdit (label) {
     this.$emit('show-edit-label', label)
    },
    expandNode () {
      this.edgesWindow.show = false
      this.queryWindow.show = false
      this.expandWindow.show = false
      this.expandWindow.data = {
        selected: this.selectedNode,
        edges: this.graph.allEdges,
        ontology: this.ontology
      }
      const vm = this
      setTimeout( function () {
        vm.expandWindow.show = true
        // clearTimeout(vm.controller.selected_node.showElementInfoTimeout);
        vm.controller.selected_node.showPreElementInfo = false
        vm.controller.selected_node.showElementInfo = false
        vm.$emit('expand-vis')
      }, 150)
    },
    expandDone(selection) {
      this.sessionController.addLogStore('expandNode', 'Раскрытие узла')
      let cash = this.cashController.getCashStore()

      let selectedNode = null
      for (let i = 0; i < this.graph.nodes.length; i++) {
        if (this.graph.nodes[i].id === selection[0].selected) {
          selectedNode = this.graph.nodes[i]
          break
        }
      }

      for (let edge of selection) {
        this.addToLegend('node', edge.nodeName, edge.nodeName)
        this.addToLegend('link',  edge.linkType,  edge.linkType)
      }
      let indexN = 0
      let indexL = 0
      for (let edge of selection) {
        let flag = true
        for (let node of this.graph.nodes) {
          if (node.id === edge.node) flag = false
        }
        if (flag) {

          let displayAttribute = this.prepareToNewNode(edge.nodeName)

          indexN++
          let color = this.ontology.nodes[edge.nodeName].color
          if (edge.node + '' in cash.marked) color = cash.marked[edge.node + '']
          this.graph.nodes.push({
            id: edge.node,
            name: edge.nodeName,
            label: edge.node,
            x: (function () {
              return selectedNode.x + (150 - Math.random() * 300)
            })(),
            y: (function () {
              return selectedNode.y + (150 - Math.random() * 300)
            })(),
            element: 'ontology',
            isFiltered: false,
            displayAttribute: displayAttribute,
            size: 1,
            sizesAll: 1,
            counter: this.controller.nodesCounter + indexN,
            level: 1,
            fixed: false,
            color: color,
            freeze: this.showAllLabels,
          })
        }
        if (edge.linkType in this.countEdges) this.countEdges[edge.linkType] += 1
        else {
          this.edgesWindow.avaliableEdges.push(edge.linkType)
          this.countEdges[edge.linkType] = 1
        }
        let target = null
        let source = null

        if (edge.type === 'out'){
          for (let i = 0; i < this.graph.nodes.length; i++) {
            if (this.graph.nodes[i].id === edge.selected) source = this.graph.nodes[i]
            if (this.graph.nodes[i].id === edge.node) target = this.graph.nodes[i]
          }
        } else {
          for (let i = 0; i < this.graph.nodes.length; i++) {
            if (this.graph.nodes[i].id === edge.node) source = this.graph.nodes[i]
            if (this.graph.nodes[i].id === edge.selected) target = this.graph.nodes[i]
          }
        }

        let params = this.prepareMultipleLinks(source, target)

        indexL++
        // Формируем надпись для связи
        let linklabel = edge.name
        // let linklabel = this.ontology.links[edge.linkType].label
        // for (var key in edge.attributes) {
        //    var value = edge.attributes[key];
        //    if (key == 'Сумма') {
        //        linklabel = value
        //    }
        //}
        this.graph.allEdges.push({
          id: edge.link,
          source: source,
          //label: edge.link,
          label: linklabel,
          target: target,
          element: 'link',
          counter: this.controller.linksCounter + indexL,
          name: edge.linkType,
        })
        this.graph.edges.push({
          id: edge.link,
          source: source,
          //abel: edge.link, // Было так, поменял, чтобы выводить надпись из linklabel
          label: linklabel,
          sameLink: {all: params.all, current: params.realCount - 1, realCount: params.realCount},
          target: target,
          element: 'link',
          size: 0.48,
          counter: this.controller.linksCounter + indexL,
          color: params.color,
          count: 1,
          hidden: params.hidden,
          name: edge.linkType,
          isActive: false,
          isFiltered: false
        })
      }
      this.controller.nodesCounter = this.controller.nodesCounter + indexN
      this.controller.linksCounter = this.controller.linksCounter + indexL
      this.$emit('show-notification', {name: 'expandDone', agrs: {nCount: indexN, lCount: indexL}})
      this.loadNodesNames()
      this.cutGraphEdges()
      this.loadLinksNames()
      this.controller.selected_node = null
      this.draw()
    },
    cutGraphEdges() {
      for (let i = 0; i < this.graph.edges.length; i++) {
        let edge = this.graph.edges[i]
        if (edge.sameLink.all + 1 !== edge.sameLink.realCount && edge.sameLink.current > 0) {
          this.graph.edges.splice(i, 1)
        }
      }
    },
    displayLabelChange(obj){
      this.setDisplayAttribute(obj)
      for (let i = 0; i < this.graph.nodes.length; i++) {
        if (this.graph.nodes[i].name === obj.type) {
           this.graph.nodes[i].displayAttribute = obj.attribute
       }
      }
      this.loadNodesNames()
    },
    setDisplayAttribute(obj) {
      let attributes = localStorage.getItem("displayAttribute")
      
      if(!!attributes) {
        let checkAttributes = JSON.parse(attributes)
        if (!!checkAttributes) {
          const index = checkAttributes.findIndex((attribute) => attribute.type === obj.type)
          if(index === -1) {
            checkAttributes.push(obj)
          } else {
            checkAttributes[index] = obj
          }
          localStorage.setItem("displayAttribute", JSON.stringify(checkAttributes))
        } else {
          console.log(".. setDisplayAttribute. JSON parse error. attributes = ", attributes);
          let newCheckAttributes = []
          newCheckAttributes.push(obj)
          localStorage.setItem("displayAttribute", JSON.stringify(newCheckAttributes))
        }
      } else {
         let checkAttributes = []
         checkAttributes.push(obj)
         localStorage.setItem("displayAttribute", JSON.stringify(checkAttributes))
      } 
    },
    loadNodesNames() {
      this.nodeNames = []
      const vm = this
      let data = []
      for (let node of this.graph.nodes) {
        data.push({
          id: node.id,
          attribute: node.displayAttribute,
          name: node.name
        })
      }
      this.visController.getAttributeNodes(data).then( (response) => {
        let nodes = response.data.nodes
        for (let i = 0; i < vm.graph.nodes.length; i++) {
          vm.graph.nodes[i].label = nodes[i]
          vm.nodeNames.push({name: nodes[i], value: data[i].id})
        }
        vm.nodeNames.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
        vm.draw()
      })
    },
    loadLinksNames() {
      const vm = this
      for (let i = 0; i < vm.graph.edges.length; i++) {
        let edge = vm.graph.edges[i]
        while (edge.id >= vm.graph.edgenames.length) {
          vm.graph.edgenames.push(null);
        }
        if (vm.graph.edgenames[edge.id] === null) {
          let element = {
              id: edge.id,
              label: edge.name
          }
          vm.visController.getLink({element: element}).then( (response) => {
              // Выводим сумму на стрелку после наведения
              let retel = response.data.element
              let linklabel = retel.label
              for (var key in retel.attributes) {
                  var value = retel.attributes[key];
                  if ((key == 'Сумма') || (key == 'Сумма (The amount)')){
                    linklabel = value
                    let numTest = Utils.testNumber(value)
                    if (numTest !== null) {
                      linklabel = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    }
                  }
              }
              while (retel.id >= vm.graph.edgenames.length) {
                vm.graph.edgenames.push(null);
              }
              vm.graph.edgenames[retel.id] = linklabel;
              //
              vm.draw()
          })
        }
      }
      //vm.draw()
    },
    showInfo(d) {
      this.$emit('show-info', d)
    },
    closeInfo(d) {
      this.$emit('close-info', d)
    },
    showQuery () {
      this.edgesWindow.show = false
      this.queryWindow.show = false
      this.expandWindow.show = false
      this.queryWindow.data = {
        node: this.selectedNode
      }
      const vm = this
      setTimeout( function () {
        clearTimeout(vm.controller.selected_node.showElementInfoTimeout);
        vm.controller.selected_node.showPreElementInfo = false
        vm.controller.selected_node.showElementInfo = false
        vm.queryWindow.show = true
      }, 150)
    },
    addToLegend(typeElement, title, type){
      let flag = true
      if (typeElement === 'node') {
        for (let n of this.legend.nodes) {
          if (n.type === type) flag = false
        }
        if (flag) {
          this.legend.nodes.push({title: title, type: type})
        }
      } else {
        for (let n of this.legend.edges) {
          if (n.type === type) flag = false
        }
        if (flag) {
          this.legend.edges.push({title: title, type: type})
        }
      }
    },
    prepareToNewNode(nodeName) {
      const cash = this.cashController.getCashStore()
      let displayAttribute = ''
      // Смотрим в кеше
      if (Object.keys(cash.displayAttribute).includes(nodeName)) {
         displayAttribute = cash.displayAttribute[nodeName]
      } else {
         displayAttribute = ''
      }
      console.log(".... displayAttribute-1 (prepareToNewNode) = ", displayAttribute);

      let attributes = localStorage.getItem("displayAttribute")
      if (attributes !== null) {
        console.log(".. attributes (prepareToNewNode) = ", attributes);
        if (attributes.trim().localeCompare("null") == 0) {
          attributes = null;
        }
      }
      //
      if (!!attributes) {
        let checkAttributes = JSON.parse(attributes)
      
           const index = checkAttributes.findIndex((attribute) => attribute.type === nodeName)
           if(index !== -1) {
              displayAttribute = checkAttributes[index].attribute
              console.log(".... displayAttribute-2 (prepareToNewNode) = ", displayAttribute);
           }
      } 

      if (nodeName in this.countNodes) this.countNodes[nodeName] += 1
      else {
        this.edgesWindow.avaliableNodes.push(nodeName)
        this.countNodes[nodeName] = 1
      }
      return displayAttribute

      
    },

    // prepareToNewNode(nodeName){
    //   const cash = this.cashController.getCashStore()
    //   let attribute = JSON.parse(localStorage.getItem("displayAttribute"))
    //   let displayAttribute = ''
    //   if (attribute && Object.keys(attribute).includes(nodeName)) {
    //     displayAttribute = attribute[nodeName]
    //   } else {
    //     displayAttribute = ''
    //   }
    //   if (nodeName in this.countNodes) this.countNodes[nodeName] += 1
    //   else {
    //     this.edgesWindow.avaliableNodes.push(nodeName)
    //     this.countNodes[nodeName] = 1
    //   }
    //    return displayAttribute;
    // },
    prepareMultipleLinks(source, target) {
      let all = 0
      let hidden = false
      let color = '#999'
      for (let edge of this.graph.edges) {
        if (source.id === edge.source.id && target.id === edge.target.id) {
          all++
          edge.sameLink.all++
        }
        else if (source.id === edge.target.id && target.id === edge.source.id) {
          all++
          edge.sameLink.all++
        }
      }
      let realCount = all + 1
      if (all > 6) {
        color = '#666'
        hidden = true
        all = 0
        for (let edge of this.graph.edges) {
          if (source.id === edge.source.id && target.id === edge.target.id) {
            if (edge.sameLink.current > 0) edge.hidden = true
            edge.color = '#666'
            edge.size = 0.64
            edge.sameLink.all = 0
            edge.sameLink.realCount = realCount
          }
          else if (source.id === edge.target.id && target.id === edge.source.id) {
            if (edge.sameLink.current > 0) edge.hidden = true
            edge.color = '#666'
            edge.size = 0.64
            edge.sameLink.all = 0
            edge.sameLink.realCount = realCount
          }
        }
      } else {
        for (let edge of this.graph.edges) {
          if (source.id === edge.source.id && target.id === edge.target.id) {
            edge.sameLink.realCount = realCount
          }
          else if (source.id === edge.target.id && target.id === edge.source.id) {
            edge.sameLink.realCount = realCount
          }
        }
      }
      return {all: all, realCount: realCount, color: color, hidden: hidden}
    },
    queryDone (select) {
      const vm = this
      vm.isLoading = true
      this.visController.revealLinks(select).then((response) => {
        vm.sessionController.addLogStore('queryDone', 'Дополнительный запрос')

        let links = response.data.revealed
        let cash = vm.cashController.getCashStore()

        let revealed = []
        for (let relation of links) {
          vm.addToLegend('node', relation[2][0], relation[2][0])
        }
        vm.addToLegend('link', select.name, select.name)

        let indexN = 0
        let indexL = 0
        for (let relation of links) {
          revealed.push(relation[0])

          let flag = true
          for (let node of vm.graph.nodes) {
            if (node.id === relation[1]) {
              flag = false
              break
            }
          }

          if (flag) {
            indexN++

            let displayAttribute = vm.prepareToNewNode(relation[2][0])

            let color = vm.ontology.nodes[relation[2][0]].color
            if (relation[1] + '' in cash.marked) color = cash.marked[relation[1] + '']
            vm.graph.nodes.push({
              id: relation[1],
              name: relation[2][0],
              label: relation[1],
              x: (function () {
                return Math.random() * 1000
              })(),
              y: (function () {
                return Math.random() * 1000
              })(),
              element: 'ontology',
              displayAttribute: displayAttribute,
              isFiltered: false,
              size: 1,
              counter: vm.controller.nodesCounter + indexN,
              sizesAll: 1,
              level: 1,
              fixed: false,
              color: color,
              freeze: vm.showAllLabels,
            })
          }
        }
        vm.controller.nodesCounter = vm.controller.nodesCounter + indexN

        for (let relation of links) {
          if (select.name in vm.countEdges) vm.countEdges[select.name] += 1
          else {
            vm.edgesWindow.avaliableEdges.push(select.name)
            vm.countEdges[select.name] = 1
          }
          let target = null
          let source = null


          if (relation[3] === 'out'){
            for (let i = 0; i < vm.graph.nodes.length; i++) {
              if (vm.graph.nodes[i].id === select.node.id) source = vm.graph.nodes[i]
              if (vm.graph.nodes[i].id === relation[1]) target = vm.graph.nodes[i]
            }
          } else {
            for (let i = 0; i < vm.graph.nodes.length; i++) {
              if (vm.graph.nodes[i].id === relation[1]) source = vm.graph.nodes[i]
              if (vm.graph.nodes[i].id === select.node.id) target = vm.graph.nodes[i]
            }
          }

          let params = vm.prepareMultipleLinks(source, target)

          indexL++
          vm.graph.allEdges.push({
            id: relation[0],
            source: source,
            label: relation[0],
            target: target,
            element: 'link',
            counter: vm.controller.linksCounter + indexL,
            name: select.name,
          })
          vm.graph.edges.push({
            id: relation[0],
            source: source,
            label: relation[0],
            sameLink: {all: params.all, current: params.realCount - 1, realCount: params.realCount},
            target: target,
            element: 'link',
            size: 0.48,
            color: params.color,
            count: 1,
            hidden: params.hidden,
            counter: vm.controller.linksCounter + indexL,
            name: select.name,
            isActive: false,
            isFiltered: false
          })
        }
        vm.controller.linksCounter = vm.controller.linksCounter + indexL
        this.$emit('show-notification', {name: 'expandDone', agrs: {nCount: indexN, lCount: indexL}})
        vm.loadNodesNames()
        vm.cutGraphEdges()
        cash.revealed.elements = cash.revealed.elements.concat(revealed)
        this.cashController.setCashStore(cash)
        vm.isLoading = false
        vm.controller.selected_node = null
        vm.draw()
      })
    },
    showEdges () {
      this.edgesWindow.show = false
      this.queryWindow.show = false
      this.expandWindow.show = false
      this.edgesWindow.data = {
        selected: this.selectedNode,
        edges: this.graph.allEdges,
        avaliableNodes: this.edgesWindow.avaliableNodes,
        avaliableEdges: this.edgesWindow.avaliableEdges
      }
      const vm = this
      setTimeout( function () {
        vm.edgesWindow.show = true
        vm.sessionController.addLogStore('showEdges', 'Просмотр связей')
        clearTimeout(vm.controller.selected_node.showElementInfoTimeout);
        vm.controller.selected_node.showPreElementInfo = false
        vm.controller.selected_node.showElementInfo = false
        vm.$emit('expand-vis')
      }, 150)

    },
    addToCart () {
      this.$emit('add-to-cart', {
        name: this.selectedNode.name + ' : ' + this.selectedNode.id,
        id: this.selectedNode.id,
        type: this.selectedNode.name,
        text: this.selectedNode.label,
        class:'element-cart-card'
      })
    },
    bindMouseActions() {
      const vm = this
      function svgDoubleClick() {
        // console.log('RESET')
        // vm.controller.event().preventDefault();
        // vm.resetMouseVars()
      }

      let svgMouseDown = function () {
        vm.controller.resetMouseVars()
        if (vm.controller.selected_node !== null) {
          vm.controller.selected_link = null
          vm.removePies()
          clearTimeout(vm.controller.selected_node.showElementInfoTimeout);
          vm.controller.selected_node.showPreElementInfo = false
          vm.controller.selected_node.showElementInfo = false
          vm.controller.selected_node = null
        }
        //if (vm.controller.selected_link !== null) {
        //  console.log(vm.controller.selected_link);
        //}
      }
      this.controller.bindActions(svgDoubleClick, svgMouseDown, null, null)
    },
    changeForceMode () {
      this.forceStarted = !this.forceStarted
      this.controller.forceMode = !this.controller.forceMode
      // console.log('changeForceMode', this.controller.forceMode)
      if (this.controller.forceMode) {
        for (let i = 0; i < this.graph.nodes.length; i++) {
          // console.log(i, this.graph.nodes[i].fixed)
          // if (this.graph.nodes[i].fixed) {
          //   this.graph.nodes[i].fx = this.graph.nodes[i].x
          //   this.graph.nodes[i].fy = this.graph.nodes[i].y
          // } else {
          //   this.graph.nodes[i].fx = null
          //   this.graph.nodes[i].fy = null
          // }
          this.graph.nodes[i].fx = null
          this.graph.nodes[i].fy = null
        }
      }
      this.draw()
    },
    init (form) {
      this.nodeSearch = null
      const vm = this

      if (vm.showElementInfoTimeout !== null) {
        clearTimeout(vm.controller.selected_node.showElementInfoTimeout);
        if (vm.controller.selected_node !== null) {
          vm.controller.selected_node.showPreElementInfo = false
          vm.controller.selected_node.showElementInfo = false
        }
      }
      const session = this.sessionController.getCurrentSessionStore()

      const cash = this.cashController.getCashStore()
      vm.isLoading = true

      const socket = this.$io
      socket.on('subgraph-graph-message', (data) => {
        if (data.sessiodId === session._id) {
          vm.socketProgress = data.message
        }
      })
      this.visController.getSubgraphGraph({
        ontology: this.ontology,
        revealed: cash.revealed.elements,
        sessionId: session._id,
        data: form
      })
        .then(function (response) {
          const minNodeSize = 0.42
          const data = response.data

          if (data.nodes.length !== 0) {
            vm.clear()
            vm.controller.bindSimulation(vm.forceProperties)
            vm.controller.enableDrag()
            vm.legend = data.legend

            for (let n of vm.legend.edges) {
              vm.countEdges[n.type] = 0
            }

            for (let n of vm.legend.nodes) {
              vm.countNodes[n.type] = 0
            }

            let i = 0
            for (let node of data.nodes) {

              let displayAttribute = vm.prepareToNewNode(node.name)

              let color = vm.ontology.nodes[node.name].color
              if (node.id + '' in cash.marked) color = cash.marked[node.id + '']
              vm.graph.nodes.push({
                id: node.id,
                name: node.name,
                label: node.id,
                x: node.x === null ? (function () {
                  return Math.random() * 1000
                })() : node.x,
                y: node.y === null ? (function () {
                  return Math.random() * 1000
                })() : node.y,
                element: 'ontology',
                isFiltered: false,
                size: 1,
                displayAttribute: displayAttribute,
                sizesAll: 1,
                counter: i,
                level: node.level,
                fixed: false,
                color: color,
                freeze: false,
              })
              i++
            }
            vm.controller.nodesCounter = i
            i = 0
            for (let relation of data.relations) {
              if (relation.name in vm.countEdges) vm.countEdges[relation.name] += 1
              else {
                vm.edgesWindow.avaliableEdges.push(relation.name)
                vm.countEdges[relation.name] = 1
              }
              let target = null
              let source = null

              for (let i = 0; i < vm.graph.nodes.length; i++) {
                if (vm.graph.nodes[i].id === relation.source) source = vm.graph.nodes[i]
                if (vm.graph.nodes[i].id === relation.target) target = vm.graph.nodes[i]
              }

              let params = vm.prepareMultipleLinks(source, target)

              // ToDo: Сделать здесь для Транзакции вывод суммы
              let linklabel = relation.name

              vm.graph.allEdges.push({
                id: relation.id,
                source: source,
                label: linklabel,
                target: target,
                element: 'link',
                counter: i,
                name: relation.name,
              })
              vm.graph.edges.push({
                id: relation.id,
                source: source,
                label: linklabel,
                sameLink: {all: params.all, current:  params.realCount - 1, realCount:  params.realCount},
                target: target,
                element: 'link',
                size: 0.48,
                color:  params.color,
                counter: i,
                count: relation.count,
                hidden:  params.hidden,
                name: relation.name,
                isActive: false,
                isFiltered: false
              })
              i++
            }
            vm.controller.linksCounter = i
            vm.loadNodesNames()
            vm.cutGraphEdges()
            vm.loadLinksNames()
            vm.isLoading = false
            vm.draw()
          } else {
            vm.$emit('show-notification', {name: 'noSubGraphs', args: {}})
            vm.isLoading = false
          }

          vm.changeForceMode()
          setTimeout(() => {
            vm.changeForceMode()
          }, 4000)
          socket.off('subgraph-graph-message')

        })
    },
    removePies(){
      let removed = this.controller.graphSelection.select('#colorMenuObject').remove().node()
      this.controller.graphSelection.append(function () {
        return removed
      });
      this.controller.graphSelection.select('#pies').remove()
      this.controller.graphSelection.selectAll('#elementInfoObjectClone').remove()
    },

    showElementInfo(d, shift, mouse){
      if (d.element === 'ontology') {
        let fobject = this.controller.select('#gg' + d.counter)
        fobject.raise()
        let elementInfo = this.controller.graphSelection.select('#elementInfoObject').clone(true).node()
        elementInfo.setAttribute('id', 'elementInfoObjectClone')
        fobject.append(function () {
          return elementInfo
        });
        fobject.select('#elementInfoObjectClone').raise()
        if (fobject.select('#pies').node() !== null) {
          shift = 32
        }
        fobject.select('#pies').raise()
        fobject.select('#elementInfoObjectClone')
                .attr('x', 12)
                .attr('y', 12 + shift)
        .attr('style', 'display: block; width: 320px; height: 700px; pointer-events: none;')
        let ComponentApp = createApp(ElementInfo, {element: d})
        ComponentApp.mount(fobject.select('#elementInfoObjectClone').select('div').node())
      } else {
        let fobject = this.controller.select('#gl' + d.counter)
        let angle = Math.PI / 2
        if (d.source.x - d.target.x !== 0) angle = Math.atan((d.source.y - d.target.y) / (d.source.x - d.target.x))
        let shift = (d.sameLink.all / 2 - d.sameLink.current) * 44
        let x1 = (-shift) * Math.sin(angle) / 2 + (d.source.x + d.target.x) / 2
        let y1 = shift * Math.cos(angle) / 2 + (d.source.y + d.target.y) / 2

        let dx = mouse[0] - x1
        let dy = mouse[1] - y1
        fobject.raise()
        let elementInfo = this.controller.graphSelection.select('#elementInfoObject').clone(true).node()
        elementInfo.setAttribute('id', 'elementInfoObjectClone')
        fobject.insert(function () {
          return elementInfo
        });
        fobject.select('#elementInfoObjectClone').raise()
        fobject.select('#elementInfoObjectClone')
                .attr('x', 5 + dx)
                .attr('y', 5 + dy)
                .attr('style', 'display: block; width: 320px; height: 700px; pointer-events: none;')
        let ComponentApp = createApp(ElementInfo, {element: d})
        ComponentApp.mount(fobject.select('#elementInfoObjectClone').select('div').node())
      }

    },

    closeElementInfo(d){
      if (d.element === 'ontology') {
        let fobject = this.controller.select('#gg' + d.counter)
        fobject.select('#elementInfoObjectClone').remove()
      } else {
        let fobject = this.controller.select('#gl' + d.counter)
        fobject.selectAll('#elementInfoObjectClone').remove()
      }
    },

    showPaint(counter, coords) {
      const vm = this
      if (this.controller.selected_node !== null) {
        let fobject = this.controller.select('#gg' + counter)
        let arcOuterRadius = 58
        this.colorMenu.color = this.controller.selected_node.color
        this.colorMenu.show = true
        this.colorMenu.predefine[0] = this.ontology.nodes[this.controller.selected_node.name].color

        let coordX = arcOuterRadius + 16

        if (coords[0] < 0) coordX = -coordX
        let removed = this.controller.graphSelection.select('#colorMenuObject').remove().node()
        fobject.append(function () {
          return removed
        });

        this.controller.graphSelection.selectAll('#colorMenuObject').raise()
        this.controller.graphSelection.select('#colorMenuObject')
                .attr('x', coordX)
                .attr('y', coords[1])
        //document.getElementById('colorMenu').click()
        vm.controller.graphSelection.select('.el-color-picker__trigger').dispatch('click');
      }
    },

    changeColorElement(color) {
      const vm = this
      vm.controller.selected_node.color = color
      vm.controller.resetMouseVars()
      setTimeout(() => {
        vm.colorMenu.show = false
        let removed = vm.controller.graphSelection.select('#colorMenuObject').remove().node()
        vm.controller.graphSelection.append(function () {
          return removed
        });
      }, 120)

      let cash = this.cashController.getCashStore()

      cash.marked['' + vm.controller.selected_node.id] = color
      this.cashController.setCashStore(cash)
    },

    drawArcs(counter, type, action, piesType) {
      const vm = this
      let arcOuterRadius = 58
      let arcInnerRadius = 19

      let avaliableTypes = []
      if (action === 'nodeMenu') avaliableTypes = [{label: 'В избранное', name: this.$t('В избранное'), icon: {name: 'fas fa-plus'}, color: '#000', clickable: false},
        {label: 'Связи', name: this.$t('Связи'), icon:  {name: 'fas fa-share-alt'}, color: '#000', clickable: false},
        {label: 'Раскрыть', name: this.$t('Раскрыть'), icon:  {name: 'fas fa-expand-arrows-alt'}, color: '#000', clickable: false},
        {label: 'Раскраска', name: this.$t('Раскраска'), icon:  {name: 'fas fa-paint-roller'}, color: '#000', clickable: false},
        // {name: 'Запрос', icon:  {name: 'fas fa-search'}, color: '#000', clickable: false},
        {label: 'Удалить', name: this.$t('Удалить'), icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]

      let fobject = this.controller.select('#gg' + counter)
      fobject.raise()
      this.removePies()

      let pieG = fobject
              .insert("g", '#middleButton' + counter)
              .attr('width', '2em')
              .attr('height', '2em')
              .attr('class', 'pies')
              .attr('id', 'pies')
      // CREATE ARC
      const arcMain = this.controller.arc()
              .outerRadius(arcOuterRadius)
              .innerRadius(arcInnerRadius)
              .padAngle(0.03)
              .cornerRadius(8)

      let arcs = this.controller.pie()
              .value(() => 100 / avaliableTypes.length)(avaliableTypes);


      let block = pieG.selectAll(".arc-graph")
              .data(arcs)
      block.select('path').attr('d', arcMain)
      let newBlock = block
              .enter()
              .insert("g", '#middleButton' + counter)
              .classed("arc-graph", true)

      newBlock.insert("path", '#middleButton' + counter)
              .attr("d", arcMain)
              .attr("id", (d, i) => "arc-graph-" + i)
              .attr("stroke", (d) => {
                if (d.data.clickable) {
                  if (d.data.chosen) return "red"
                }
                return "gray"
              })
              .attr("stroke-width", (d) => {
                if (d.data.clickable) {
                  if (d.data.chosen) return "3px"
                }
                return "1px"
              })
              .attr("fill", (d) => d.data.color)

      let newBlock2 = this.controller.select('#pies').selectAll("path")
      let clickCallback = null
      if (action === 'nodeMenu') {
        clickCallback = function(d) {
          if (d.data.label === 'В избранное') {
            vm.addToCart()
          } else if (d.data.label === 'Связи') {
            vm.showEdges()
          } else if (d.data.label === 'Раскрыть') {
            vm.expandNode()
          } else if (d.data.label === 'Запрос') {
            vm.showQuery()
          } else if (d.data.label === 'Раскраска') {
            vm.showPaint(counter, [arcMain.centroid(d)[0], arcMain.centroid(d)[1]])
          } else if (d.data.label === 'Удалить') {
            vm.deleteElement()
          }
        }
      }

      newBlock2.on('click', clickCallback)

      // ADD ICON
      this.foIcons = newBlock
              // .append('foreignObject')
              .insert('foreignObject', '#middleButton' + counter)
              .attr('width', '2em')
              .attr('height', '2em')
              .attr(
                      "transform",
                      `translate(-${this.controller.chartWidth / 64}, ${this.controller.chartHeight / 2})`
              )
              .attr('x', (d) =>  arcMain.centroid(d)[0] - 8)
              .attr('y', (d) => arcMain.centroid(d)[1] - 14)
              .on('click', clickCallback)
      this.foIcons
              .append('xhtml:i')
              // .attr('class', () => 'fas fa-home')
              .attr('class', (d) => d.data.icon.name + '')
              .attr('color', 'white')
              .attr("xlink:href", (d, i) => "#arc-" + i)
              .attr('x', (d) => arcMain.centroid(d)[0] - 8)
              .attr('y', (d) => arcMain.centroid(d)[1] - 14)



      setTimeout(function() { newBlock.on('mouseover', (d, i) => {
        let arc = vm.controller.graphSelection.select('#arc-graph-' + i)
        let group = vm.controller.select(arc.node().parentNode)
        arc.style('opacity', '0.5')
        vm.controller.graphSelection.append('text')
                .attr('class', 'test-attr-text')
                .attr("fill","white")
                .attr('text-anchor', 'middle')
                .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
                .attr('font-size',  16)
                .text('');

        let textG = group.append('g')
                .attr('class', 'text-tip')
                .attr('transform', () => {
                  let text = vm.controller.graphSelection.select('.test-attr-text').attr('font-size',  16).text(d.data.name)
                  let size = text.node().getBBox()
                  let tan = arcMain.centroid(d)[1] / arcMain.centroid(d)[0]
                  let angle = Math.atan(tan)
                  let s = 1
                  if (arcMain.centroid(d)[0] < 0) s = -s
                  let x = 0
                  let y = 0
                  x = s*(arcOuterRadius/2)*Math.cos(angle) + arcMain.centroid(d)[0] + (s - 1)*size.width/2
                  if (Math.cos(angle) > -0.001 && Math.cos(angle) < 0.001) {
                    x =  arcMain.centroid(d)[0] + -size.width/2
                  }
                  y = arcMain.centroid(d)[1] + ((arcOuterRadius + 15)/2)*Math.sin(s*angle) + size.height/2
                  return `translate(${x}, ${y})`
                })

        textG
                .append('rect')
                .attr('style', 'fill:white;stroke:black;stroke-width:2px')
                .attr('rx', 6)
                .attr('ry', 6)
                .attr('x', () => {
                  return -4
                })
                .attr('y', -20)
                .attr('width', () => {
                  let text = vm.controller.graphSelection.select('.test-attr-text').attr('font-size',  16).text(d.data.name)
                  let size = text.node().getBBox()
                  return size.width + 8
                })
                .attr('height', () => {
                  let text = vm.controller.graphSelection.select('.test-attr-text').attr('font-size',  16).text(d.data.name)
                  let size = text.node().getBBox()
                  return size.height + 14
                })

        textG
                .append('text')
                // .text(arc.data()[0].data.name)
                .text(d.data.name)
                .attr('font-size', 16)
                .attr('x', () => {
                  return 0
                })
                .attr('y', () => {
                  return 0
                })
        vm.controller.graphSelection.selectAll('.test-attr-text').remove()
      })
        newBlock.on('mouseout', (d, i) => {
          let arc = vm.controller.graphSelection.select('#arc-graph-' + i)
          let group = vm.controller.select(arc.node().parentNode)
          arc.style('opacity', '1')
          group.select('.text-tip').remove()
        })}, 50)
    },
    removeAllElements() {
      this.controller.removeElements('.node')
      this.controller.removeElements('.marker')
      this.controller.removeElements('.text-link')
      this.controller.removeElements('.text-count')
      this.controller.removeElements('.link')
    },
    draw(action) {
      const vm = this
      this.controller.updateGraph(this.graph)

      this.removeAllElements()

      this.controller.restartSimulation(this.forceProperties)

      function ontologyMouseDown(d) {
        if (vm.controller.event().defaultPrevented) return;
        vm.controller.selected_link = null
        vm.removePies()
        clearTimeout(d.showElementInfoTimeout);
        vm.controller.resetMouseVars()
        if (vm.controller.event().button === 0) {
          vm.$emit('select-element', {element: d, type: 'node'})
          vm.removePies()
          vm.controller.selected_link = null
          if (vm.controller.selected_node !== d) {
            vm.controller.selected_node = d
            vm.selectedNode = d
            vm.drawArcs(d.counter, 'node', 'nodeMenu', null)
            vm.closeElementInfo(d)
            vm.showElementInfo(d, 32)
            d.showPreElementInfo = false
            d.showElementInfo = false
          } else {

            d.showPreElementInfo = false
            d.showElementInfo = false
            vm.closeElementInfo(d)
            vm.controller.selected_node = null
          }
        }
      }

      function linkMouseDown(d) {
        if (vm.controller.event().defaultPrevented) return;
        if (d.element === 'link') {
          if (d.color === '#E72F0A') {
            d.color = '#999';
          } else {
            d.color = '#E72F0A';
          }
        }
      }

      function ontologyMouseDouble() {
        // vm.controller.event().preventDefault();
        // console.log('ontologyMouseDown', vm.controller.event().button)
        // //vm.controller.resetMouseVars()
        // if (vm.controller.event().button === 0) {
        //   const current = d
        //   vm.controller.selectNodeAndNighbours(current.id)
        //
        // }
      }
      function ontologyMouseEnter(d) {
        d.showElementInfoTimeout = null
        vm.controller.event().preventDefault();
        d.showPreElementInfo = true
        if (d.element === 'link') {
          vm.controller.graphSelection.selectAll('.link').attr('style', r => {
            if (d === r) return 'stroke:#AC3B61;stroke-width:' + 8*r.size + 'px;fill: none;'
            return 'stroke:' + r.color + ';stroke-width:' + 8*r.size + 'px;fill: none;'
          })
          vm.controller.graphSelection.selectAll('.marker').select('polygon').attr('style', r => {
            if (d === r) return 'fill:#AC3B61'
            return 'fill:' + r.color
          })
        }
        clearTimeout(d.showElementInfoTimeout);
        const mouse = vm.controller.mouse(this)
        d.showElementInfoTimeout = setTimeout(function () {
          if (d.showPreElementInfo) {
            console.log('enter', d, mouse)
            vm.showElementInfo(d, 0, mouse)
            d.showElementInfo = true
          }
        }, 150)
      }
      function ontologyMouseLeave(d) {
        vm.controller.event().preventDefault();
        vm.controller.graphSelection.selectAll('.link').attr('style', (r) => 'stroke:' + r.color + ';stroke-width:' + 8*r.size + 'px;fill: none;')
        vm.controller.graphSelection.selectAll('.marker').select('polygon').attr('style', (r) => 'fill:' + r.color)
        if (vm.controller.selected_node === null) {
          clearTimeout(d.showElementInfoTimeout);
          d.showPreElementInfo = false
          d.showElementInfo = false
          vm.closeElementInfo(d)
          // console.log('leave', d, vm.controller.mouse(this))
        } else {
          if (d !== vm.controller.selected_node) {
            clearTimeout(d.showElementInfoTimeout);
            vm.closeElementInfo(d)
            // console.log('leave 2', d, vm.controller.mouse(this))
          }
        }
      }

      this.controller.createOntology(action, '#addToCart', ontologyMouseDown, null, ontologyMouseDouble, ontologyMouseEnter, ontologyMouseLeave)
      this.controller.createLinks(action, '.node', linkMouseDown, null, ontologyMouseEnter, ontologyMouseLeave)
      if (this.controller.forceMode) {
        this.controller.simulation.tick()
      }
      this.controller.enableDrag()

    },
    resetMouseVars () {
      this.controller.resetMouseVars()
      this.menu.display = 'none'
    },

    acceptFilter () {
      this.isLoading = true
      const vm = this
      const type = 0
      setTimeout(() => {
        let arrayOfNodes = []
        vm.s.graph.nodes().forEach(function (n) {
          let level = n.level
          if (level > vm.levelValue[1] || level < vm.levelValue[0]) {
            n.hidden = true
            n.isFiltered = true
          } else {
            n.hidden = false
            n.isFiltered = false
          }
          let node = {
            id: n.id,
            hidden: n.hidden
          }
          arrayOfNodes.push(node)
        })
        vm.s.graph.edges().forEach(function (e) {
          if (e.type === type) {
            if (e.count > vm.countValue[1] || e.count < vm.countValue[0]) {
              e.hidden = true
              e.isFiltered = true
            } else {
              e.hidden = false
              e.isFiltered = false
            }

            for (let node of arrayOfNodes) {
              if (node.id === e.source || node.id === e.target) {
                if (node.hidden) {
                  e.hidden = true
                  e.isFiltered = true
                  break
                }
              }
            }
          }
        })
        vm.s.graph.nodes().forEach(function (n) {
          let id = n.id
          let flag = true
          vm.s.graph.edges().forEach(function (e) {
            if (e.type === type) {
              if (id === e.source || id === e.target) {
                if (!e.hidden) {
                  flag = false
                }
              }
            }
          })
          if (flag) {
            n.hidden = true
            n.isFiltered = true
          }
        })
        vm.isLoading = false
        vm.s.refresh()
      }, 40)
    },
    searchNodeStart () {
      const vm = this
      vm.isLoading = true
      setTimeout(() => {
        let nodeId = vm.nodeSearch.value
        let node = null
        for (let n of vm.graph.nodes) {
          if (nodeId === n.id) {
            node = n
            break
          }
        }
        vm.controller.selectNodeAndNighbours(nodeId)
        let svgNode = vm.controller.svg.node()
        let width = svgNode.getBoundingClientRect().width
        let height = svgNode.getBoundingClientRect().height
        vm.controller.svg.call(vm.controller.zoom.transform,
            vm.controller.zoomIdentity()
                .translate(-node.x + width/2, -node.y + height/2)
                .scale(vm.controller.currentScale.k));
        //vm.controller.graphSelection.attr('transform', `translate(${node.x},${node.y}) scale(${vm.controller.currentScale.k})`)
        vm.isLoading = false
        vm.nodeSearch = null
      }, 40)
    },
    resetFilter () {
      this.controller.resetMouseVars()
      //this.controller.updateGraph(this.graph)
      this.clear()
      //this.acceptFilter()
    },
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
      integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
      crossorigin=""/>
<style scoped>

  .dropdown{
    background-color: #e3e3e3 !important;
    /*color: #ffffff!important;*/
  }
#container {
  position: absolute;
  width: 100%;
  height: 100%;
        background: rgba(255, 255, 255, 1);
}
#image-map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.leaflet-pane,
.leaflet-map-pane {
  position: absolute;
  left: 0;
}
.muted {
  fill-opacity: 0.1;
  stroke-opacity: 0.1;
}
  #control-show-labels {
    top: 68px;
    /*bottom: 10px;*/
    right: 0px;
    margin-right: 24px;
    z-index: 1900;
    margin-top: 2px;
    position: absolute;
  }
#control-force {
  top: 10px;
  /*bottom: 10px;*/
  right: 100px;
  margin-right: 24px;
  z-index: 1900;
  margin-top: 2px;
  position: absolute;
}
#control-search {
  top: 10px;
  /*bottom: 10px;*/
  right: 130px;
  margin-right: 24px;
  z-index: 1900;
  margin-top: 2px;
  position: absolute;
}

  #control-image {
    bottom: 16px;
    /*bottom: 10px;*/
    left: 16px;
    margin-right: 24px;
    z-index: 1900;
    margin-top: 2px;
    position: absolute;
  }
#control-reset {
  top: 12px;
  /*bottom: 10px;*/
  right: 30px;
  margin-right: 24px;
  z-index: 1900;
  margin-top: 2px;
  position: absolute;
}

#control-pane {
  top: 10px;
  /*bottom: 10px;*/
  right: 10px;
  margin-right: 16px;
  position: absolute;
  width: 300px;
  z-index: 1900;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
#control-pane-collapse {
  top: 10px;
  /*bottom: 10px;*/
  right: 10px;
  margin-right: 16px;
  position: absolute;
  width: 300px;
  height: 50px;
  z-index: 50000;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
#control-pane > div {
  margin: 10px;
  overflow-x: auto;
}
.line {
  clear: both;
  display: block;
  width: 100%;
  margin: 0;
  padding: 12px 0 0 0;
  border-bottom: 1px solid #000;
  background: transparent;
}
.leaflet-container {
  background-color:rgba(255,250,250,0.0);
}
h2,
h3,
h4 {
  padding: 0;
}

h2.underline {
  color: #000;
  background: rgb(240, 240, 240);
  margin: 0;
  border-radius: 2px;
  padding: 8px 12px;
  font-weight: normal;
}
.hidden {
  display: none;
  visibility: hidden;
}

input[type="range"] {
  width: 160px;
}

.graph-label {
  width: 20px;
  height: 10px;
  border-radius: 20%;
  text-align: center;
  padding: 1.4644px;
  background: rgb(184, 54, 39);
}

.filter {
  width: 120px;
  text-align: center;
}
</style>
